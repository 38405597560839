import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import QuotesTable from './QuotesTable';

const QuotesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [quotes, setQuotes] = useState([]);
  const [newQuotes] = useState({
    quote: 'New quote',
    author: 'Anonymous',
    status: false
  });
  const [radioValue, setRadioValue] = useState();

  const fetchQuotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quotes'
      });
      setQuotes(data);
      const q = data.filter(d => d.status === true);
      setRadioValue(q[0]._id);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const onRadioChange = id => {
    setRadioValue(id);
  };

  const addQuote = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/quotes',
        body: newQuotes
      });
      await fetchQuotes();
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const deleteQuote = async quote => {
    try {
      await dispatchAPI('DELETE', {
        url: `/quotes/${quote._id}`
      });
      await fetchQuotes();
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const editQuote = async (quote, row, rValue) => {
    try {
      quotes.map(async q => {
        if (q._id !== rValue && q.status) {
          await dispatchAPI('PATCH', {
            url: `/quotes/${q._id}`,
            body: { ...q, status: false }
          });
        }
      });
      await dispatchAPI('PATCH', {
        url: `/quotes/${quote._id}`,
        body: row
      });
      await fetchQuotes();
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  useEffect(() => {
    fetchQuotes();
  }, []);

  return (
    <>
      {quotes.length > 0 && (
        <QuotesTable
          quotes={quotes}
          editQuote={editQuote}
          deleteQuote={deleteQuote}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          onRadioChange={onRadioChange}
          // check={check}
          // setCheck={setCheck}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: '50' }}
        onClick={addQuote}
      >
        {`${t('quotes.add')}`}
      </Button>
    </>
  );
};

export default QuotesSettings;
