import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from '@ant-design/plots';
import { Typography, Spin, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Title } = Typography;

const styles = {
  container: {
    backgroundColor: 'rgba(38, 100, 123, 0.06)',
    padding: '24px',
    borderRadius: '6px'
  }
};

const CashFlow = ({ id, year }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/line_data/${id}?year=${year}`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    })();
  }, [year]);

  const config = {
    data: datas,
    xField: 'date',
    yField: 'value',
    yAxis: {
      label: {
        formatter: v => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, s => `${s},`)
      }
    },
    seriesField: 'type',
    legend: {
      itemName: {
        formatter: value => t(`companies.cashflow.${value}`)
      }
    },
    tooltip: {
      formatter: ({ value, type }) => {
        return {
          value: `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
          name: t(`companies.cashflow.${type}`)
        };
      },
      title: value => t(`companies.cashflow.line.months.${value}`)
    },
    color: ({ type }) => {
      switch (type) {
        case 'incomes':
          return '#D2ACFF';
        case 'expenses':
          return '#FFA99F';
        default:
          return '#5BFFE1';
      }
    }
  };

  return (
    <div style={styles.container}>
      <Title level={5}>CashFlow</Title>
      <Spin spinning={isLoading}>
        {datas.length < 1 ? <Empty /> : <Line {...config} />}
      </Spin>
    </div>
  );
};

CashFlow.propTypes = {
  id: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired
};

export default CashFlow;
