import React from 'react';
import useColumns from './columns';
// import headers from './headers';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ListArchives = () => {
  const columns = useColumns();

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      customActionColumn
      resourceName="managings"
      // headers={headers}
      withSubRoutes
      withUploadButton
      resourceModelName="managings"
      scroll={{ x: 900 }}
    />
  );
};

export default ListArchives;
