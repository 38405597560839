import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import useColumns from './columns';

const { RangePicker } = DatePicker;

const ListIncomes = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [companies, setCompanies] = useState();
  const [categories, setCategories] = useState();
  const [dateRange, setDateRange] = useState();

  const columns = useColumns({ companies, categories });

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/incomes-categories' });
      const listCategories = data.map(({ label, _id }) => {
        return {
          text: label,
          value: _id
        };
      });
      setCategories(listCategories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      const listCompaniesName = data.map(({ name, _id }) => {
        return {
          text: name,
          value: _id
        };
      });
      setCompanies(listCompaniesName);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getCompanies();
    getCategories();
  }, []);

  const onChangeDates = (_, dateString) => {
    console.log('dateString:', dateString);
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      setDateRange();
    } else {
      setDateRange(dateString);
    }
  };

  const extraButtons = <RangePicker onChange={onChangeDates} />;

  const extraQueryToSend = () => {
    let finalQuery;
    if (dateRange) {
      finalQuery = `startDate=${dateRange[0]}&endDate${dateRange[1]}`;
    }
    console.log('finalQuery:', finalQuery);
    return finalQuery;
  };

  return (
    <SimpleResourceLandingLayout
      resourceName="incomes"
      withUploadButton
      columns={columns}
      populate="lot,building,company,category"
      resourceModelName="Income"
      extraButtons={extraButtons}
      extraQuery={extraQueryToSend()}
      scroll={{ x: 1000 }}
    />
  );
};

export default ListIncomes;
