import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Button } from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import CreateUpdateMemo from '../Memos/CreateUpdateMemo';
import RentalsTimeline from './RentalsTimeline';

const { TabPane } = Tabs;

const RentalsTabs = ({
  id,
  title,
  showForm,
  setShowForm,
  memos,
  fetchData,
  rental
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [purpose, setPurpose] = useState();
  const [dataToForm, setDataToForm] = useState();
  moment.locale('fr');

  const deleteResource = async item => {
    try {
      await dispatchAPI('PATCH', {
        url: `/rentals/${id}`,
        body: { memos: rental.memos.filter(m => m._id !== item._id) }
      });
      await fetchData();
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const handleOk = () => {
    setDataToForm();
    setShowForm(false);
    fetchData();
  };

  const handleCancel = () => {
    setDataToForm();
    setShowForm(false);
  };

  const handleCreateUpdate = (action, item) => {
    if (item) {
      setDataToForm(item);
    }
    setPurpose(action);
    setShowForm(true);
  };

  const handleOnChange = () => {
    if (showForm) {
      setShowForm(false);
    }
  };

  return (
    <div
      style={{
        background: 'rgb(38, 100, 123, 0.04)',
        padding: '16px',
        borderRadius: '6px',
        width: '100%'
      }}
    >
      <h1
        style={{
          fontSize: '1.3rem',
          fontWeight: 600,
          textTransform: 'uppercase'
        }}
      >
        {title}
      </h1>
      <Tabs type="card" onChange={handleOnChange}>
        <TabPane tab="Notes" key="1">
          {!showForm && (
            <div
              style={{
                padding: '0 16px 0 16px',
                overflow: 'auto',
                maxHeight: '550px'
              }}
            >
              <div align="right">
                <Button
                  type="link"
                  style={{ textTransform: 'uppercase' }}
                  onClick={() => handleCreateUpdate('create')}
                >
                  + Ajouter un élément
                </Button>
              </div>
              <RentalsTimeline
                memos={memos}
                resource="notes"
                handleCreateUpdate={handleCreateUpdate}
                deleteResource={deleteResource}
              />
            </div>
          )}
          {showForm && (
            <CreateUpdateMemo
              id={id}
              purpose={purpose}
              handleOk={handleOk}
              handleCancel={handleCancel}
              data={dataToForm}
              rental={rental}
              resource="notes"
              tradKey="notes"
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

RentalsTabs.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showForm: PropTypes.bool.isRequired,
  setShowForm: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  rental: PropTypes.shape({
    memos: PropTypes.arrayOf({})
  }).isRequired,
  memos: PropTypes.arrayOf(PropTypes.shape({}))
};

RentalsTabs.defaultProps = {
  memos: null
};

export default RentalsTabs;
