import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['legal_form'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.legalForms || []).map(title => (
            <Option key={title} value={title}>
              {t(`suppliers.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['group']
    },
    {
      name: ['category'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.category || []).map(title => (
            <Option key={title} value={title}>
              {t(`suppliers.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['channel_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      label: 'street',
      name: ['street']
    },
    {
      name: ['address_details']
    },
    {
      name: ['postal_code'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['city']
    },
    {
      name: ['siren_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['siret_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['ape_number'],
      input: <InputNumber style={{ width: '100%' }} />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/suppliers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
