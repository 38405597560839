import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateUpdateIntervention from './CreateUpdateIntervention';
import ShowIntervention from './ShowIntervention';
import ListInterventions from './ListInterventions';

const InterventionRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create/:resourceId`}
        render={() => <CreateUpdateIntervention purpose="create" />}
      />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateIntervention purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateIntervention purpose="edit" />}
      />
      <Route exact path={`${path}/show/:id`} component={ShowIntervention} />
      <Route exact path={path} component={ListInterventions} />
    </Switch>
  );
};

export default InterventionRouter;
