import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ContentCustom = ({ children, isDataTable }) => {
  return (
    <Content
      style={{
        padding: '24px 24px 16px 24px',
        marginTop: 2,
        background: 'var(--componentBackground)'
      }}
    >
      {isDataTable ? (
        <div>{children}</div>
      ) : (
        <div
          style={{
            padding: '16px',
            background: 'rgb(38, 100, 123, 0.04)',
            borderRadius: '6px'
          }}
        >
          {children}
        </div>
      )}
    </Content>
  );
};

export default ContentCustom;
