import React from 'react';
import {
  ContactsOutlined,
  ClusterOutlined,
  EuroOutlined,
  TeamOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  HomeOutlined,
  SolutionOutlined,
  SettingOutlined,
  StockOutlined,
  TagsOutlined,
  UserOutlined,
  BankOutlined,
  BookOutlined,
  AppstoreOutlined,
  FolderOutlined,
  FormatPainterOutlined,
  ScheduleOutlined,
  CrownOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  SUPPLIERS: <SolutionOutlined />,
  COMPANIES: <ClusterOutlined />,
  ACCOUNTING: <EuroOutlined />,
  COMMERCIAL: <ContactsOutlined />,
  CONTRACTS: <FileDoneOutlined />,
  QUOTES: <FileTextOutlined />,
  CUSTOMERS: <TeamOutlined />,
  BUILDINGS: <BankOutlined />,
  LOT: <AppstoreOutlined />,
  RENTALS: <BookOutlined />,
  PRODUCTION: <StockOutlined />,
  TICKETS: <TagsOutlined />,
  SETTINGS: <SettingOutlined />,
  DOCUMENTS: <FolderOutlined />,
  INTERVENTIONS: <FormatPainterOutlined />,
  MANAGINGS: <ScheduleOutlined />,
  HOLDINGS: <CrownOutlined />
};

export default navMenuLogos;
