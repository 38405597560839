import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber } from 'antd';
import useErrorMessage from '../../../utils/ErrorMessage';
import 'antd/dist/antd.css';

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  month,
  amount,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const { message } = useErrorMessage();

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      dataIndex
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (values.previsional_amount) {
        handleSave({
          ...record,
          ...values,
          month
        });
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  let childNode = amount;

  if (editable) {
    childNode = editing ? (
      <Form form={form}>
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          <InputNumber
            step="0.01"
            decimalSeparator=","
            formatter={value =>
              `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            initialValues={amount}
          />
        </Form.Item>
      </Form>
    ) : (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {`${amount} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || '-'}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

EditableCell.propTypes = {
  editable: PropTypes.bool,
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.element.isRequired,
  handleSave: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  companyPrevisionals: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

EditableCell.defaultProps = {
  editable: true
};

export default EditableCell;
