import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useListContent = ({
  company,
  establishment,
  start_date,
  end_date,
  total_amount,
  interest_rate,
  period,
  duration,
  no_folder
}) => {
  const { t } = useTranslation();

  const content = [
    {
      label: 'loans.form.company',
      content: company && company.name
    },
    {
      label: 'loans.form.establishment',
      content: establishment
    },
    {
      label: 'loans.form.start_date',
      content: moment(start_date).format('L')
    },
    {
      label: 'loans.form.end_date',
      content: moment(end_date).format('L')
    },
    {
      label: 'loans.form.total_amount',
      content: `${parseFloat(total_amount)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
    },
    {
      label: 'loans.form.interest_rate',
      content: `${interest_rate} %`
    },
    {
      label: 'loans.form.period',
      content: t(`loans.types.${period}`)
    },
    {
      label: 'loans.form.duration',
      content: duration
    },
    {
      label: 'loans.form.no_folder',
      content: no_folder
    }
  ];

  return content;
};

export default useListContent;
