import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const ListInterventions = () => {
  const [enums, setEnums] = useState();
  const columns = useColumns(enums);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/interventions/enums'
      });
      const interventionsStatus = data.status.map(stat => ({
        text: t(`interventions.columns.status_enums.${stat}`),
        value: stat
      }));
      const interventionsTypes = data.type.map(typ => ({
        text: t(`interventions.columns.types.${typ}`),
        value: typ
      }));
      const interventionsObjects = data.object.map(o => ({
        text: t(`intervention.columns.objects.${o}`),
        value: o
      }));
      setEnums({
        interventionsStatus,
        interventionsTypes,
        interventionsObjects
      });
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="interventions"
      populate="building,lot,customer"
      withSubRoutes
      resourceModelName="interventions"
      scroll={{ x: 1800 }}
    />
  );
};

export default ListInterventions;
