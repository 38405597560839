import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/routes';
import Login from './login';
import Home from './home/Home';
import Exception from '../components/Exceptions/Exception';
import useAuthContext from '../contexts/AuthContext';
import BasicLayout from '../components/layouts/BasicLayout';
import ProfileRouter from './profile/ProfileRouter';
import Confirmation from './emailConfirmation/Confirmation';
import Settings from './Settings/settings';
import UserRouter from './users/UserRouter';
import AccountRouter from './Accounting/AccountRouter';
import CompaniesRouter from './Companies/CompaniesRouter';
import BuildingRouter from './Buildings/BuildingRouter';
import LotRouter from './Lot/LotRouter';
import RentalsRouter from './Rentals/RentalsRouter';
import CustomersRouter from './Commercial/Customers/customerRouter';
import TinkAuthentication from './Companies/CompanyBankReconciliation/TinkAuthentication';
import InterventionRouter from './Interventions/InterventionRouter';
import RentReviewRouter from './RentReviews/RentReviewRouter';
import ManagingRouter from './Managing/ManagingRouter';
import HoldingRouter from './Holding/HoldingRouter';

export const PrivateRoute = ({
  location,
  component: Component,
  ...restProps
}) => {
  const authContext = useAuthContext();
  const splitPath = location.pathname.split('/');
  const splitPathGrant = location.pathname.split('/').filter(e => e !== '');
  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;

  const grantFromPath =
    Symbol.iterator in Object(splitPathGrant)
      ? splitPathGrant.join('.').toLowerCase()
      : splitPathGrant.toLowerCase();

  const checkToRender = (props, resourceToCheck) => {
    if (!authContext.isValid) {
      return (
        <Redirect
          to={{
            pathname: outOfNavRoutes.LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }

    if (!authContext.checkIsGranted(resourceToCheck)) {
      return (
        <Redirect
          to={{
            pathname: routes.HOME,
            state: { from: props.location }
          }}
        />
      );
    }

    return (
      <BasicLayout path={path}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </BasicLayout>
    );
  };

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={props => checkToRender(props, grantFromPath)}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} component={Login} />
        <Route path={outOfNavRoutes.LOGIN} component={Login} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          component={Confirmation}
        />
        <PrivateRoute path={outOfNavRoutes.PROFILE} component={ProfileRouter} />

        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute path="/tink" component={TinkAuthentication} />
        <PrivateRoute exact path={routes.HOME} component={Home} />
        <PrivateRoute path={routes.USERS} component={UserRouter} />
        <PrivateRoute path={routes.CUSTOMERS} component={CustomersRouter} />
        <PrivateRoute path={routes.HOLDINGS} component={HoldingRouter} />
        <PrivateRoute path={routes.LOT} component={LotRouter} />
        <PrivateRoute path={routes.RENTALS} component={RentalsRouter} />
        <PrivateRoute path={`${routes.ACCOUNTING}`} component={AccountRouter} />
        <PrivateRoute path={routes.MANAGINGS} component={ManagingRouter} />
        <PrivateRoute path="/rent-reviews" component={RentReviewRouter} />
        <PrivateRoute path="/interventions" component={InterventionRouter} />
        <PrivateRoute path={routes.BUILDINGS} component={BuildingRouter} />
        <PrivateRoute path={routes.COMPANIES} component={CompaniesRouter} />
        <PrivateRoute exact path={routes.SETTINGS} component={Settings} />
        <PrivateRoute component={Exception} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
