import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import {
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const Content = styled.div`
  margin: 5px;
`;
const LineFile = styled.div`
  float: right;
  color: ${props => props.color || 'red'};
  cursor: pointer;
  margin: 5px;
`;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CustomLineFile = ({
  resource,
  id,
  file,
  forceRefresh,
  setForceRefresh
}) => {
  const [isLoading, setIsLoading] = useState({});
  const [isDownloading, setIsDownloading] = useState({});
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { _id, filename } = file;

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };
  const deleteFile = async _id => {
    setIsLoading({ ...isDownloading, [_id]: true });
    const body = {
      _id
    };
    try {
      await dispatchAPI('PATCH', { url: `${resource}/files/${id}`, body });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return (
    <Row>
      <Col span={16}>
        <Content onClick={() => downloadFile(_id, filename)}>
          {filename.slice(0, 15)}
        </Content>
      </Col>
      <Col span={4}>
        <LineFile
          onClick={() => downloadFile(_id, filename)}
          color="var(--primaryColor)"
        >
          <DownloadOutlined />
        </LineFile>
      </Col>
      <Col span={4}>
        <LineFile onClick={() => deleteFile(_id)}>
          {isLoading[_id] ? <Spin indicator={antIcon} /> : <DeleteOutlined />}
        </LineFile>
      </Col>
    </Row>
  );
};

CustomLineFile.propTypes = {
  id: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  file: PropTypes.objectOf({
    _id: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired
  }).isRequired,
  forceRefresh: PropTypes.string,
  setForceRefresh: PropTypes.func
};

CustomLineFile.defaultProps = {
  forceRefresh: null,
  setForceRefresh: null
};

export default CustomLineFile;
