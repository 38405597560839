import React, { useEffect, useState } from 'react';
import { Select, Input, DatePicker, TimePicker, Radio, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = ({
  disableFields,
  radioValue,
  setRadioValue,
  switchValue,
  companies,
  building,
  lots,
  allFieldsDisabled
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState([]);
  const days = [];

  for (let i = 1; i <= 31; i += 1) {
    days.push(i);
  }

  const onChangeRadio = e => {
    setRadioValue(e.target.value);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/agendas/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const fields = [
    {
      label: 'title_event',
      name: ['title_events'],
      rules: [{ required: true }],
      input: <Input disabled={allFieldsDisabled} />
    },
    {
      label: 'type_event',
      name: ['type_events'],
      rules: [{ required: true }],
      input: (
        <Select allowClear disabled={allFieldsDisabled}>
          {Object.entries(enums).map(item => (
            <Option value={item[1].type} key={item[1].type}>
              {t(`agendas.form.${item[1].type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'date',
      name: ['date'],
      rules: [{ required: true }],
      input: (
        <DatePicker style={{ width: '100%' }} disabled={allFieldsDisabled} />
      )
    },
    {
      label: 'schedule',
      name: ['schedule'],
      rules: [{ required: true }],
      input: (
        <TimePicker.RangePicker
          style={{ width: '100%' }}
          disabled={allFieldsDisabled}
        />
      )
    },
    {
      label: 'company',
      name: ['company'],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          disabled={allFieldsDisabled}
        >
          {(companies || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'building',
      name: ['building'],
      input: (
        <Select
          disabled={disableFields.building || allFieldsDisabled}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {(building || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot',
      name: ['lot'],
      input: (
        <Select
          disabled={disableFields.lot || allFieldsDisabled}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {(lots || []).map(({ _id, reference }) => (
            <Option key={_id} value={_id}>
              {reference}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'common_event',
      name: ['common_events'],
      input: (
        <Radio.Group
          onChange={onChangeRadio}
          value={radioValue}
          initialValues={radioValue}
          disabled={allFieldsDisabled}
        >
          <Radio value>Oui</Radio>
          <Radio value={false}>Non</Radio>
        </Radio.Group>
      )
    },
    {
      label: 'comments',
      name: ['comments'],
      input: <TextArea rows={4} disabled={allFieldsDisabled} />
    },
    {
      label: 'reminder',
      name: ['reminder'],
      input: <Switch checked={switchValue} disabled={allFieldsDisabled} />
    },
    {
      label: 'number_days_reminder',
      name: ['number_days_reminder'],
      input: (
        <Select allowClear disabled={!switchValue || allFieldsDisabled}>
          {days.map(i => (
            <Option key={i} value={i}>
              {i}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return fields;
};

export default useFields;
