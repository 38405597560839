import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Revenu from './Expense';
import ShowExpense from './ShowExpense';
import CreateUpdateExpense from './CreateUpdateExpense';

const ExpensesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateExpense purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateExpense purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowExpense />} />
      <Route path={path} component={Revenu} />
    </Switch>
  );
};

export default ExpensesRouter;

