import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import useAuthContact from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const useOtherCustomerFormFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContact();
  const { message } = useErrorMessage();

  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState('');
  const [genders, setGenders] = useState([]);

  const resource = 'invoices';
  const addressInput = [
    <Form.Item
      style={{ width: '300%', marginLeft: '-100%' }}
      name={['other_customer', 'bill_address']}
      label={t(`${resource}.form.bill_address`)}
    >
      <PlacesAutocomplete value={address} onChange={value => setAddress(value)}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: 'Rechercher ...',
                className: 'location-search-input'
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Form.Item>
  ];

  const personalFields = [
    <>
      <Form.Item
        style={{ width: '300%', marginLeft: '-100%' }}
        name={['other_customer', 'gender']}
        label={t(`${resource}.form.gender`)}
      >
        <Select
          loading={isLoading}
          options={genders.map(gender => ({
            label: t(`${resource}.form.genders.${gender}`),
            value: gender
          }))}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '300%', marginLeft: '-100%' }}
        name={['other_customer', 'first_name']}
        label={t(`${resource}.form.first_name`)}
      >
        <Input />
      </Form.Item>
      <Form.Item
        style={{ width: '300%', marginLeft: '-100%' }}
        name={['other_customer', 'last_name']}
        label={t(`${resource}.form.last_name`)}
      >
        <Input />
      </Form.Item>

      {addressInput}
    </>
  ];

  const companyFields = [
    <>
      <Form.Item
        style={{ width: '300%', marginLeft: '-100%' }}
        name={['other_customer', 'company_name']}
        label={t(`${resource}.form.company_name`)}
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ width: '300%', marginLeft: '-100%' }}
        name={['other_customer', 'company_contact']}
        label={t(`${resource}.form.company_contact`)}
      >
        <Input />
      </Form.Item>

      {addressInput}
    </>
  ];

  const getGenders = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setGenders(data.gender);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getGenders();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return { personalFields, companyFields };
};
export default useOtherCustomerFormFields;
