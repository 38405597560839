import { useTranslation } from 'react-i18next';

const useListContent = ({
  type,
  name,
  first_name,
  last_name,
  legal_form,
  hq_address,
  bill_mode,
  bill_address,
  rcs_number,
  ape_number,
  manager,
  payment_method,
  phone,
  email
}) => {
  const { t } = useTranslation();
  const renderManager = () => {
    if (manager?.rcs_number) {
      return `${manager.name}`;
    }
    return manager?.name;
  };

  const renderRepresentativeName = () => {
    if (type === 'BUSINESS') {
      return `${manager?.first_name} ${manager?.last_name}`;
    }
    return `${first_name} ${last_name}`;
  };

  // - RCS ${manager.rcs_number} - ${manager.address}`

  const personalContent = [
    {
      label: 'customers.form.name',
      content: first_name
    },

    {
      label: 'customers.form.first_name',
      content: first_name
    },
    {
      label: 'customers.form.last_name',
      content: last_name
    }
  ];

  const businessContent = [
    {
      label: 'customers.form.name',
      content: name
    },
    {
      label: 'customers.form.legal_form',
      content: legal_form
    },
    {
      label: 'customers.form.rcs_number',
      content: rcs_number
    },
    {
      label: 'customers.form.ape_number',
      content: ape_number
    }
  ];

  const commmonContent = [
    {
      label: 'customers.form.type',
      content: t(`customers.type.${type}`)
    },
    {
      label: 'customers.form.manager_first_name',
      content: type === 'BUSINESS' ? manager?.first_name : first_name,
      span: 2
    },
    {
      label: 'customers.form.manager_last_name',
      content: type === 'BUSINESS' ? manager?.last_name : last_name,
      span: 2
    },
    {
      label: 'customers.form.representative_name',
      content: renderRepresentativeName()
    },
    {
      label: 'customers.form.hq_address',
      content: hq_address,
      span: 3
    },
    {
      label: 'customers.form.bill_address',
      content: bill_address,
      span: 2
    },
    {
      label: 'customers.form.bill_mode',
      content: t(`customers.billMode.${bill_mode}`)
    },
    {
      label: 'customers.form.payment_method',
      content: t(`customers.paymentMethod.${payment_method}`)
    },
    {
      label: 'customers.form.manager_email',
      content: manager ? manager.email : email,
      span: 4
    },
    {
      label: 'customers.form.manager_telephone',
      content: manager ? manager.phone : phone?.country_code + phone?.number,
      span: 4
    }
  ];
  if (type === 'BUSINESS') return [...businessContent, ...commmonContent];
  return [...personalContent, ...commmonContent];
};

export default useListContent;
