import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography,
  Checkbox,
  Radio,
  Switch
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import {
  formItemLayout,
  invoiceLineLayout
} from '../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { routes } from '../../../utils/constants/routes';

const { Title } = Typography;
const { TextArea } = Input;
const InvoiceForm = ({
  handleSubmit,
  resource,
  initialValues,
  fields1,
  fields2,
  personalFields,
  companyFields,
  isLoading,
  customers,
  companies,
  purpose
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [displayDiscount, setDiscountDisplay] = useState(
    initialValues.global_discount_value
      ? initialValues.global_discount_value > 0
      : false
  );
  const [displayLines, setLinesDisplay] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [company, setCompany] = useState(null);
  const [displayedDiscounts, setDiscountsDisplay] = useState([]);
  const [lineSubtotals, setSubtotals] = useState([]);
  const [totalMode, setMode] = useState('HT');
  const [totalAmount, setTotalAmount] = useState(
    purpose === 'edit' && initialValues ? Number(initialValues.subTotal) : 0
  );
  const [checked, setChecked] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('customer');
  const [customerType, setCustomerType] = useState('INDIVIDUAL');

  const generateCustomersSelects = () => {
    if (selectedCustomer === 'company') {
      return (
        <>
          <Form.Item name={['company']} rules={[{ required: true }]}>
            <Select
              options={companies.map(({ name, _id }) => ({
                label: name,
                value: _id
              }))}
              loading={isLoading}
              style={{ width: '300%' }}
            />
          </Form.Item>
          {company && (
            <>
              <Form.Item
                style={{ marginBottom: 0 }}
                label={t(`${resource}.form.customer_address`)}
              >
                {company.address}
              </Form.Item>
              <Link
                to={{
                  pathname: `${routes.COMPANIES}/edit/${company._id}`
                }}
              >
                <Button>{t('buttons.edit')}</Button>
              </Link>
            </>
          )}
        </>
      );
    }
    if (selectedCustomer === 'other') {
      return (
        <>
          <Form.Item name={['other_customer', 'customer_type']}>
            <Switch
              checkedChildren={t('customers.type.business')}
              unCheckedChildren={t('customers.type.personal')}
              onClick={c => setCustomerType(c ? 'COMPANY' : 'INDIVIDUAL')}
              checked={customerType === 'COMPANY'}
            />
          </Form.Item>
          {customerType === 'INDIVIDUAL' ? personalFields : companyFields}
        </>
      );
    }
    return (
      <>
        <Form.Item name={['customer']} rules={[{ required: true }]}>
          <Select
            options={customers.map(({ name, _id }) => ({
              label: name,
              value: _id
            }))}
            loading={isLoading}
            style={{ width: '300%' }}
          />
        </Form.Item>
        {(customer || initialValues.customer) && (
          <div style={{ width: '300%', marginLeft: '-100%' }}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={t(`${resource}.form.customer_address`)}
            >
              {customer?.bill_address}
            </Form.Item>
            <Form.Item label={t(`${resource}.form.email`)} name={['email']}>
              {customer?.email}
            </Form.Item>
            <Row justify="center">
              <Link
                to={{
                  pathname: `${routes.CUSTOMERS}/edit/${customer?._id}`
                }}
              >
                <Button type="primary">{t('buttons.edit')}</Button>
              </Link>
            </Row>
          </div>
        )}
      </>
    );
  };

  const onInputChange = () => {
    let tmpArticles = Object.values(form.getFieldValue('articles'));
    const globalDiscount = form.getFieldValue(['global_discount', 'value']);
    const discountType = form.getFieldValue(['global_discount', 'type']);
    const tmpSubtotals = [...lineSubtotals];
    let calculatedGlobalDiscount = 0;
    let subTotal = 0;
    let total_VAT = 0;
    let total_discount = 0;
    tmpArticles = tmpArticles.filter(tmp => tmp !== undefined);
    if (tmpArticles && tmpArticles.length > 0) {
      tmpArticles.forEach(
        (
          { quantity, unit_price, discount, discount_type, subtotal },
          index
        ) => {
          let tmpDiscount = 0;

          if (!tmpSubtotals[index]) {
            tmpSubtotals.push(subtotal);
          }

          if (discount) {
            tmpDiscount =
              discount_type === 'CURRENCY'
                ? discount
                : (quantity * unit_price * discount) / 100;
            total_discount += tmpDiscount;
          }
          subTotal += quantity * unit_price - tmpDiscount;
          total_VAT += (quantity * unit_price - tmpDiscount) * (20 / 100);
        }
      );
    }

    if (globalDiscount && discountType) {
      calculatedGlobalDiscount =
        discountType === 'CURRENCY'
          ? globalDiscount
          : (subTotal * globalDiscount) / 100;
      total_discount += calculatedGlobalDiscount;
    }
    const total = subTotal - calculatedGlobalDiscount + total_VAT;

    form.setFieldsValue({
      subTotal: parseFloat(subTotal).toFixed(2),
      total_discount: parseFloat(total_discount).toFixed(2),
      total_VAT: parseFloat(total_VAT).toFixed(2),
      total: parseFloat(total).toFixed(2)
    });

    setSubtotals(tmpSubtotals);
    setTotalAmount(
      totalMode === 'HT'
        ? subTotal - calculatedGlobalDiscount
        : subTotal - calculatedGlobalDiscount + total_VAT
    );
  };

  useEffect(() => {
    if (initialValues && initialValues.articles) {
      const newLineSubTotals = [];
      initialValues.articles.forEach(article => {
        newLineSubTotals.push(article.subtotal);
      });
      setSubtotals(newLineSubTotals);
    }
  }, [initialValues]);

  const onValuesChange = (_, values) => {
    if (values.customer) {
      setCompany(null);
      form.resetFields(['company']);
      const newCustomer = customers.find(
        element => element._id === values.customer
      );
      setCustomer(newCustomer);
    }
    if (values.company) {
      setCustomer(null);
      form.resetFields(['customer']);
      const newCompany = companies.find(
        element => element._id === values.company
      );
      setCompany(newCompany);
    }
  };

  const updateArticleSubtotal = index => {
    const tmpSubtotal =
      form.getFieldValue(['articles', index, 'unit_price']) *
      form.getFieldValue(['articles', index, 'quantity']);
    let newDiscount = form.getFieldValue(['articles', index, 'discount']);
    const discountType = form.getFieldValue([
      'articles',
      index,
      'discount_type'
    ]);
    if (!newDiscount) {
      newDiscount = 0;
    }
    const newSubtotal =
      tmpSubtotal -
      (discountType === 'CURRENCY'
        ? newDiscount
        : (newDiscount * tmpSubtotal) / 100);
    const fields = form.getFieldsValue();
    const { articles } = fields;
    Object.assign(articles[index], { subtotal: newSubtotal });
    form.setFieldsValue({ articles });
    let tmpSubtotals = [];
    if (lineSubtotals && lineSubtotals.length > 0) {
      tmpSubtotals = [...lineSubtotals];
    }
    tmpSubtotals[index] = newSubtotal;
    setSubtotals(tmpSubtotals);
  };

  const onShowDiscount = index => {
    const tempDiscounts = [...displayedDiscounts];
    const fields = form.getFieldsValue();
    const { articles } = fields;
    if (articles && articles.length > 0) {
      if (tempDiscounts.includes(index)) {
        const idx = tempDiscounts.indexOf(index);
        tempDiscounts.splice(idx, 1);
        const newArticles = [...form.getFieldValue('articles')];
        newArticles[index] = {
          ...newArticles[index],
          discount: 0
        };
        form.setFieldsValue({
          articles: newArticles
        });
      } else {
        tempDiscounts.push(index);
      }
      onInputChange();
      updateArticleSubtotal(index);
      setDiscountsDisplay(tempDiscounts);
    }
  };

  useEffect(() => {
    if (displayedDiscounts.length > 0) {
      onInputChange();
    }
  }, [displayedDiscounts]);

  return (
    <Spin spinning={isLoading}>
      <Form
        {...formItemLayout}
        name="invoicesForm"
        onFinish={handleSubmit}
        initialValues={initialValues}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row style={{ marginBottom: 20 }}>
          <Col span={8} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.heading`)}</Title>
          </Col>
          <Col span={24}>
            <Row style={{ marginBottom: 10 }}>
              <Col span={8} />
              <Col span={8}>
                <Radio.Group defaultValue={selectedCustomer}>
                  <Radio.Button
                    value="customer"
                    onChange={e => setSelectedCustomer(e.target.value)}
                  >
                    {t(`${resource}.form.tenants`)}
                  </Radio.Button>
                  <Radio.Button
                    value="company"
                    onChange={e => setSelectedCustomer(e.target.value)}
                  >
                    {t(`${resource}.form.companies`)}
                  </Radio.Button>
                  <Radio.Button
                    value="other"
                    onChange={e => setSelectedCustomer(e.target.value)}
                  >
                    {t(`${resource}.form.others`)}
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ textAlign: 'right' }}>
                <div style={{ marginTop: 4, color: 'var(--textColor)' }}>
                  <span style={{ color: 'red' }}>*</span>
                  &nbsp;
                  {t(`${resource}.form.customer`)}
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                </div>
              </Col>
              <Col span={8}>{generateCustomersSelects()}</Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={8} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.body`)}</Title>
          </Col>
          <Col span={24}>
            {fields1.map(field => generateFields(resource, field))}
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.lines`)}</Title>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Button type="link" onClick={() => setLinesDisplay(!displayLines)}>
              {displayLines
                ? t(`${resource}.form.title.hide`)
                : t(`${resource}.form.title.display`)}
              {displayLines ? (
                <UpOutlined style={{ marginLeft: 10 }} />
              ) : (
                <DownOutlined style={{ marginLeft: 10 }} />
              )}
            </Button>
          </Col>
        </Row>
        {displayLines && (
          <Row>
            <Col span={24} className="invoice-articles-form">
              <Form.List name="articles">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row>
                        <Col span={24}>
                          <Row
                            key={key}
                            justify="space-between"
                            style={{ minWidth: '100%', gap: 10 }}
                          >
                            <Form.Item
                              label="Ens."
                              {...restField}
                              name={[name, 'unity']}
                              {...invoiceLineLayout}
                            >
                              <Checkbox
                                onChange={() => {
                                  checked.splice(key, 1, !checked[key]);
                                  form.setFieldsValue({
                                    articles: form
                                      .getFieldValue('articles')
                                      .map((values, index) =>
                                        index === key
                                          ? {
                                              ...values,
                                              unity: checked[key]
                                            }
                                          : { ...values }
                                      )
                                  });
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              label="Libellé"
                              className="invoice-form"
                              {...restField}
                              name={[name, 'label']}
                              rules={[
                                { required: true, message: 'Missing wording' }
                              ]}
                              {...invoiceLineLayout}
                            >
                              <Input placeholder="Libellé" />
                            </Form.Item>

                            <Form.Item
                              label="Quantité"
                              {...restField}
                              name={[name, 'quantity']}
                              rules={[
                                {
                                  required: true,

                                  message: 'Missing quantity'
                                }
                              ]}
                              {...invoiceLineLayout}
                            >
                              <InputNumber
                                onChange={() => {
                                  onInputChange();

                                  updateArticleSubtotal(key);
                                }}
                                placeholder="Quantité"
                              />
                            </Form.Item>

                            <Form.Item
                              label="Prix unitaire HT"
                              {...restField}
                              name={[name, 'unit_price']}
                              rules={[
                                {
                                  required: true,

                                  message: 'Missing unit price'
                                }
                              ]}
                              {...invoiceLineLayout}
                            >
                              <InputNumber
                                onChange={() => {
                                  onInputChange();

                                  updateArticleSubtotal(key);
                                }}
                                placeholder="Prix unitaire HT"
                              />
                            </Form.Item>

                            <Form.Item {...invoiceLineLayout}>
                              <Button
                                type="link"
                                onClick={() => onShowDiscount(key)}
                              >
                                {t('invoices.form.articles.discount')}
                              </Button>

                              {displayedDiscounts?.includes(key) && (
                                <Input.Group compact>
                                  <Form.Item
                                    name={[name, 'discount']}
                                    {...invoiceLineLayout}
                                  >
                                    <InputNumber
                                      onChange={() => {
                                        onInputChange();

                                        updateArticleSubtotal(key);
                                      }}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name={[name, 'discount_type']}
                                    initialValue="CURRENCY"
                                    {...invoiceLineLayout}
                                  >
                                    <Select
                                      options={[
                                        {
                                          label: '€ HT',

                                          value: 'CURRENCY'
                                        },

                                        {
                                          label: '%',

                                          value: 'PERCENT'
                                        }
                                      ]}
                                      onChange={onInputChange}
                                    />
                                  </Form.Item>
                                </Input.Group>
                              )}
                            </Form.Item>

                            <Form.Item {...invoiceLineLayout}>
                              <strong>
                                {t('invoices.form.articles.subtotal')}
                              </strong>

                              <Title level={3}>
                                {`${
                                  lineSubtotals[key]
                                    ? lineSubtotals[key].toFixed(2)
                                    : 0.0
                                }€`}
                              </Title>
                            </Form.Item>
                            <Col
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0 0 10px 10px'
                              }}
                            >
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col span={24}>
                              <Form.Item
                                className="invoice-form"
                                name={[name, 'description']}
                                {...invoiceLineLayout}
                              >
                                <TextArea placeholder="Description" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}

                    <Form.Item {...invoiceLineLayout}>
                      <Button
                        onClick={() => {
                          add();
                          setChecked([...checked, false]);
                        }}
                        block
                        type="dashed"
                        icon={<PlusOutlined />}
                      >
                        Ajouter une nouvelle ligne
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: '20px' }}>
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.discount`)}</Title>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Button
              type="link"
              onClick={() => setDiscountDisplay(!displayDiscount)}
            >
              {displayDiscount
                ? t('invoices.form.title.hide')
                : t('invoices.form.title.display')}
              {displayDiscount ? (
                <UpOutlined style={{ marginLeft: 10 }} />
              ) : (
                <DownOutlined style={{ marginLeft: 10 }} />
              )}
            </Button>
          </Col>
          <Col span={24}>
            <Row style={{ marginBottom: '16px' }}>
              {displayDiscount && (
                <Col span={24}>
                  <Form.Item
                    label={t(`${resource}.form.global_discount.value`)}
                  >
                    <Input.Group compact>
                      <Form.Item
                        style={{ width: '80%' }}
                        name={['global_discount', 'value']}
                        initialValue={0}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          onChange={() => {
                            onInputChange();
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '20%' }}
                        name={['global_discount', 'type']}
                        initialValue="CURRENCY"
                      >
                        <Select
                          style={{ width: '100%' }}
                          options={[
                            {
                              label: '€ HT',
                              value: 'CURRENCY'
                            },
                            {
                              label: '%',
                              value: 'PERCENT'
                            }
                          ]}
                          onChange={() => {
                            onInputChange();
                          }}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={t(`${resource}.form.global_discount.comment`)}
                    name={['global_discount', 'comment']}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            background: 'var(--bodyBackground)',
            border: '1px solid var(--borderColor)',
            marginTop: '20px',
            marginBottom: '35px',
            padding: '10px'
          }}
        >
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.recap`)}</Title>
          </Col>
          <Col span={24}>
            {fields2.map(field => generateFields(resource, field))}
          </Col>
        </Row>

        <Row>
          <Col
            span={2}
            offset={8}
            onClick={() => {
              setTotalAmount(
                totalMode === 'HT'
                  ? form.getFieldValue('total')
                  : form.getFieldValue('subTotal')
              );
              setMode(totalMode === 'HT' ? 'TTC' : 'HT');
            }}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <strong>{t(`${resource}.form.total`)}</strong>
            <Title level={3} style={{ margin: 0 }}>
              {`${parseFloat(totalAmount).toFixed(2)}€`}
            </Title>
            <Button type="link">{totalMode}</Button>
          </Col>
          <Col span={4} offset={2}>
            <Button
              style={{ float: 'right' }}
              type="add"
              htmlType="submit"
              disabled={
                !(purpose === 'edit'
                  ? initialValues.articles && initialValues.articles.length > 0
                  : form.getFieldsValue().articles &&
                    form.getFieldsValue().articles.length > 0)
              }
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
            <Button
              style={{ float: 'right' }}
              type="link"
              danger
              onClick={() => history.goBack()}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

InvoiceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    global_discount_value: PropTypes.bool,
    subTotal: PropTypes.number,
    customer: PropTypes.shape({}),
    articles: PropTypes.shape({
      forEach: PropTypes.func,
      length: PropTypes.number
    })
  }).isRequired,
  fields1: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields2: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  personalFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  companyFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({})),
  addArticle: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCompanies: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  purpose: PropTypes.string.isRequired,
  genders: PropTypes.arrayOf(PropTypes.string).isRequired
};

InvoiceForm.defaultProps = {
  articles: undefined
};

export default InvoiceForm;
