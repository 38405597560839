import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components';
import useColumns from '../Interventions/columns';
import { SearchAddBar } from '../../utils/constants/customButton';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { RangePicker } = DatePicker;

const InterventionsTable = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [enums, setEnums] = useState();
  const columns = useColumns(enums);
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState();
  const { pathname } = useLocation();
  const splitURL = pathname.split('/');
  const resource = splitURL[1];
  const [dateRange, setDateRange] = useState();
  const [refresh, setRefresh] = useState(false);

  const onChangeRange = (_, dateString) => {
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      setDateRange();
    } else {
      setDateRange(dateString);
    }
    setRefresh(!refresh);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/interventions/enums'
      });
      const interventionsStatus = data.status.map(stat => ({
        text: t(`interventions.columns.status_enums.${stat}`),
        value: stat
      }));
      const interventionsTypes = data.type.map(typ => ({
        text: t(`interventions.columns.types.${typ}`),
        value: typ
      }));
      const interventionsObjects = data.object.map(o => ({
        text: t(`intervention.columns.objects.${o}`),
        value: o
      }));
      setEnums({
        interventionsStatus,
        interventionsTypes,
        interventionsObjects
      });
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <>
      <RangePicker onChange={onChangeRange} />
      <SearchAddBar
        setSearchValue={setSearchValue}
        addPath={`/interventions/create/${id}_${resource}`}
      />
      <Datatable
        resourceName="interventions"
        columns={columns}
        populate="building,lot,customer"
        extraQuery={
          dateRange
            ? `building=${id}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
            : `building=${id}`
        }
        searchValue={searchValue}
        path="/interventions"
        forceRefresh={refresh}
      />
    </>
  );
};

export default InterventionsTable;
