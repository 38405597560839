import React from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { tailFormItemLayout } from './formLayout';
import DangerPopconfirm from './customPopconfirm';

const { Search } = Input;

const ButtonAdd = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      onClick={onClick}
      style={{
        color: '#fff',
        float: 'right',
        backgroundColor: 'rgb(71, 210, 103)',
        border: 'rgb(71, 210, 103)'
      }}
    >
      <span style={{ fontSize: '15px' }}>{t('buttons.create')}</span>{' '}
      <PlusOutlined />
    </Button>
  );
};

const FormButton = ({ cancelButton, customLayout }) => {
  const { t } = useTranslation();
  const layout = customLayout || tailFormItemLayout;
  return (
    <Form.Item {...layout} style={{ display: 'flex', justifyContent: 'end' }}>
      <Button
        type="primary"
        htmlType="submit"
        style={{
          background: 'rgb(71, 210, 103)',
          color: '#fff',
          border: 'rgb(71, 210, 103)',
          borderRadius: 3,
          float: 'right',
          marginLeft: 10
        }}
      >
        {t('buttons.save')} <CheckOutlined />
      </Button>
      <Button
        onClick={cancelButton}
        style={{
          background: '#fff',
          color: 'red',
          borderColor: 'red',
          borderRadius: 3,
          float: 'right'
        }}
      >
        {t('buttons.cancel')} <CloseOutlined />
      </Button>
      <br />
    </Form.Item>
  );
};

const DetailsButton = ({ path, id, deleteRessource }) => {
  const { t } = useTranslation();
  return (
    <Space>
      <DangerPopconfirm deleteRessource={deleteRessource} id={id} />
      <Link to={path + id}>
        <Button type="primary">
          <span>{t('buttons.edit')}</span> <EditOutlined />
        </Button>
      </Link>
    </Space>
  );
};

const SearchAddBar = ({ addPath, setSearchValue, onClick }) => {
  const { t } = useTranslation();

  const isReductScreen = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <Row style={{ marginBottom: 30, marginTop: 30 }}>
      <Col span={20}>
        <Search
          placeholder={t('searchaddbar.placeholder')}
          onSearch={value => setSearchValue(value)}
          style={{ width: isReductScreen ? 300 : 500 }}
        />
      </Col>
      <Col span={4} style={{ display: 'block' }}>
        <Link to={addPath}>
          <ButtonAdd onClick={onClick} />
        </Link>
      </Col>
    </Row>
  );
};

export { ButtonAdd, FormButton, DetailsButton, SearchAddBar };
