import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, Select } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { TextArea } = Input;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [projects, setProjects] = useState([]);

  const fields = [
    {
      name: ['customer'],
      rules: [{ required: true }],
      input: (
        <Select
          options={companies
            .filter(c => c.type === 'CUSTOMERS')
            .map(({ name, _id }) => ({ label: name, value: _id }))}
          loading={isLoading}
        />
      )
    },
    {
      name: ['contact'],
      rules: [{ required: true }],
      input: (
        <Select
          options={contacts.map(({ first_name, last_name, _id }) => ({
            label: `${first_name} ${last_name}`,
            value: _id
          }))}
          loading={isLoading}
        />
      )
    },
    {
      name: ['project'],
      input: (
        <Select
          options={projects.map(({ name, _id }) => ({
            label: name,
            value: _id
          }))}
          loading={isLoading}
        />
      )
    },
    {
      name: ['reception_date'],
      input: <DatePicker />
    },
    {
      name: ['subject'],
      rules: [{ required: true }]
    },
    {
      name: ['comment'],
      input: <TextArea />
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContacts = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts' });
      setContacts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects' });
      setProjects(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getCompanies();
    await getContacts();
    await getProjects();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    fields
  };
};

export default useFields;
