import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Switch,
  Table,
  Tag,
  Radio
} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  savedItem,
  onRadioChange,
  index,
  children,
  selectType,
  ...restProps
}) => {
  const inputNode =
    selectType === 'status' ? (
      <Radio name="status" onChange={() => onRadioChange(savedItem._id)} />
    ) : (
      <Input required />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const QuotesTable = ({
  quotes,
  editQuote,
  deleteQuote,
  radioValue,
  onRadioChange
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [editingKey, setEditingKey] = useState();
  const isEditing = savedItem => savedItem._id === editingKey;
  const { message } = useErrorMessage();

  const edit = savedItem => {
    form.setFieldsValue({
      label: '',
      status: '',
      ...savedItem
    });
    setEditingKey(savedItem._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async savedItem => {
    try {
      const row = await form.validateFields();
      if (radioValue === savedItem._id) {
        row.status = true;
      }
      editQuote(savedItem, row, radioValue);
      setEditingKey('');
    } catch (err) {
      if (err.response) message.error(err.response.status);
    }
  };

  const columns = [
    {
      title: t('quotes.table.quote'),
      dataIndex: 'quote',
      width: '40%',
      editable: true
    },
    {
      title: t('quotes.table.author'),
      dataIndex: 'author',
      width: '15%',
      editable: true
    },
    {
      title: t('quotes.table.status'),
      dataIndex: 'status',
      width: '15%',
      editable: true,
      render: (value, record) => {
        return (
          <Radio
            name="status"
            checked={radioValue === record._id}
            disabled
            onChange={() => onRadioChange(record._id)}
          />
        );
      }
    },
    {
      title: t('quotes.table.operation'),
      dataIndex: 'operation',
      width: '30%',
      render: (_, savedItem) => {
        const editable = isEditing(savedItem);
        return editable ? (
          <span>
            <Button onClick={() => save(savedItem)}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              onClick={() => edit(savedItem)}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteQuote(savedItem)}
              disabled={editingKey !== ''}
            >
              <Button type="danger" style={{ borderRadius: 0 }}>
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: savedItem => ({
        savedItem,
        selectType: col.dataIndex === 'status' ? 'status' : '',
        dataIndex: col.dataIndex,
        title: col.title,
        onRadioChange,
        editing: isEditing(savedItem)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={quotes}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default QuotesTable;
