import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('rentals.columns.locataire_name'),
      key: 'tenant.name',
      dataIndex: 'tenant',
      render: tenant => tenant.name,
      sorter: true
    },
    {
      title: t('rentals.columns.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      render: start_date => moment(start_date).format('L'),
      sorter: true
    },
    {
      title: t('rentals.columns.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: end_date => moment(end_date).format('L'),
      sorter: true
    },
    {
      title: t('rentals.columns.annual_rental'),
      key: 'amounts',
      dataIndex: 'amounts',
      sorter: true,
      render: amounts => amounts && amounts[amounts.length - 1].value
    },
    {
      title: t('rentals.columns.annual_charges'),
      key: 'taxes',
      render: data =>
        data?.rental_charges_contribution + data?.rental_taxes_contribution ||
        '-',
      sorter: true
    }
  ];
};

export default useColumns;
