import React from 'react';
import useColumns from './columns';
import EditableTable from '../../EditableTable/EditableTable';

const ManagingTable = ({
  dataSource,
  isLoading,
  selectedTrimester,
  enums,
  type,
  year,
  refresh,
  setRefresh
}) => {
  const columns = useColumns({ selectedTrimester, enums });

  return (
    <EditableTable
      defaultColumns={columns}
      data={dataSource}
      isLoading={isLoading}
      type={type}
      year={year}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
};

export default ManagingTable;
