import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Popconfirm, Switch, Table, Select } from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import { expensesSubCategories } from '../../../utils/constants/enums';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  savedItem,
  children,
  selectType,
  ...restProps
}) => {
  const { t } = useTranslation();
  const chooseNodeType = type => {
    switch (type) {
      case 'show':
        return <Switch defaultChecked={savedItem.show} />;

      case 'sub_category':
        return (
          <Select>
            {expensesSubCategories.map(sub_category => (
              <Option
                value={t(
                  `settings.expenses.table.sub_categories.${sub_category}`
                )}
                key={sub_category}
              >
                {t(`settings.expenses.table.sub_categories.${sub_category}`)}
              </Option>
            ))}
          </Select>
        );

      default:
        return <Input required />;
    }
  };

  const inputNode = chooseNodeType(selectType);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ExpensesTable = ({ expenses, editExpense, del }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');

  const isEditing = ({ _id }) => _id === editingKey;

  const edit = savedItem => {
    form.setFieldsValue({
      label: '',
      status: '',
      ...savedItem
    });
    setEditingKey(savedItem._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const setColor = async ({ color }) => {
    await form.setFieldsValue({
      color: colorPicker || color
    });
  };

  const save = async savedItem => {
    await setColor(savedItem);
    try {
      const row = await form.validateFields();
      editExpense(savedItem._id, row);
      setEditingKey('');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: t('settings.expenses.table.sub_category'),
      dataIndex: 'sub_category',
      width: '25%',
      editable: true
    },
    {
      title: t('settings.expenses.table.code'),
      dataIndex: 'code',
      width: '15%',
      editable: true
    },
    {
      title: t('settings.expenses.table.label'),
      dataIndex: 'label',
      width: '30%',
      editable: true
    },
    {
      title: t('settings.expenses.table.show'),
      dataIndex: 'show',
      width: '10%',
      editable: true,
      render: value => {
        return <Switch checked={value} disabled />;
      }
    },
    {
      title: t('settings.expenses.table.operation'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, savedItem) => {
        const editable = isEditing(savedItem);
        return editable ? (
          <span>
            <Button onClick={() => save(savedItem)}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(savedItem)}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => del(savedItem)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: savedItem => ({
        savedItem,
        selectType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        colorPicker,
        setColorPicker,
        editing: isEditing(savedItem)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={expenses}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  savedItem: PropTypes.element,
  selectType: PropTypes.string
};
EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  title: '',
  savedItem: {},
  selectType: ''
};

ExpensesTable.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editExpense: PropTypes.func.isRequired,
  del: PropTypes.func.isRequired
};

export default ExpensesTable;
