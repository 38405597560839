import React, { useState } from 'react';
import { DatePicker } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components';
import useColumns from '../../Interventions/columns';

const { RangePicker } = DatePicker;

const CustomerInterventionsTable = () => {
  const { t } = useTranslation();
  const columns = useColumns({ customAction: true });
  const { id } = useParams();
  const [dateRange, setDateRange] = useState();
  const [refresh, setRefresh] = useState(false);

  const onChangeRange = (_, dateString) => {
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      setDateRange();
    } else {
      setDateRange(dateString);
    }
    setRefresh(!refresh);
  };

  return (
    <>
      <RangePicker onChange={onChangeRange} />
      <Datatable
        style={{ overflow: 'auto' }}
        resourceName="interventions"
        columns={columns}
        populate="lot,building,customer"
        customActionColumn
        extraQuery={
          dateRange
            ? `customer=${id}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
            : `customer=${id}`
        }
        path="/interventions"
      />
    </>
  );
};

export default CustomerInterventionsTable;
