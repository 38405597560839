export const routes = {
  HOME: '/',
  USERS: '/users',
  HOLDINGS: '/holdings',
  COMPANIES: '/companies',
  BUILDINGS: '/buildings',
  LOT: '/lots',
  CUSTOMERS: '/customers',
  INTERVENTIONS: '/interventions',
  RENTALS: '/rentals',
  ACCOUNTING: '/accounting',
  MANAGINGS: '/managings',
  SETTINGS: '/settings'
};

export const subRoutes = {
  COMMERCIAL: {
    DEALS: '/deals',
    CONTACTS: '/contacts'
  },
  ACCOUNTING: {
    RECEIPTS: '/receipts',
    INVOICES: '/invoices',
    INCOMES: '/incomes',
    EXPENSES: '/expenses',
    LOANS: '/loans'
  },
  MANAGINGS: {
    PREVISIONAL: '/previsional',
    CASHFLOW: '/cashflow',
    ARCHIVE: '/archive'
  },
  PRODUCTION: {
    ARTICLES: '/articles',
    STOCKS: '/stocks'
  }
};

export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile'
};
