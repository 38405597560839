import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const BankReconciliationContext = createContext({});

export const BankReconciliationContextProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [visibleAccounts, setVisibleAccounts] = useState([]);

  return (
    <BankReconciliationContext.Provider value={{ user, setUser, visibleAccounts, setVisibleAccounts }}>
      {children}
    </BankReconciliationContext.Provider>
  );
};

BankReconciliationContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(BankReconciliationContext);
