import { createGlobalStyle } from 'styled-components';

// #8ed6ca lightgreen
// #107564 darkgreen
// #e4eae9 grey

const LightTheme = createGlobalStyle`
  body {
    --sorterUnselected: #bdd0d7;
    --backgroundCard: rgba(38, 100, 123, 0.04);
    --menuMainColor: #DDEEEC;
    --menuListColor: #BEDED9;
    --primaryColor: #82C4B6;
    --textColor: #26647B;
    --blackText: #000;
    --whiteColor: #fff;
    --clientColor: #e4eae9;
    --addColor: #26647B;
    --itemHoverColor: var(--addColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--primaryColor);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #e3f2ef;
    --secondaryColor: #ff4d4f;
    --disabledColor: #bfbfbf;
    --bodyBackground: #f0f2f5;
    --textColorSecondary: rgba(0, 0, 0, 0.65);
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: #fff;
    --borderColor: #d9d9d9;
    --contrastBackground: #f5f5f5;
    --opacitySVG: 1;
    --errorColor: #ff4d4f;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;

export default LightTheme;
