import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      title: t('companies.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('companies.form.siren_number'),
      key: 'siren_number',
      dataIndex: 'siren_number',
      sorter: true,
      render: item => item || '-'
    },
    {
      title: t('companies.form.legal_form'),
      key: 'legal_form',
      dataIndex: 'legal_form',
      sorter: true,
      render: item => t(`companies.legalForms.${item}`),
      filters:
        enums.legalForms &&
        enums.legalForms.map(f => ({
          text: t(`companies.legalForms.${f}`),
          value: f
        }))
    },
    {
      title: t('companies.form.buildings'),
      key: 'buildings',
      dataIndex: 'buildings',
      render: items => items.map(({ name }) => `${name}. `),
      sorter: true
    },
    {
      title: t('companies.form.manager'),
      key: 'manager',
      dataIndex: 'manager',
      render: manager => manager?.name || '-',
      sorter: true
    }
  ];
};

export default useColumns;
