const headers = [
  {
    label: 'name',
    key: 'name'
  },
  {
    label: 'company',
    key: 'company.name'
  },
  {
    label: 'status',
    key: 'company.type'
  },
  {
    label: 'address',
    key: 'address'
  },
  {
    label: 'nb_lots',
    key: 'lots.length'
  }
];

export default headers;
