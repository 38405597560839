import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateIncome = ({ purpose }) => {
  const { id } = useParams();

  const { fields, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: data => {
        return {
          ...data,
          emission_date: data.emission_date && moment(data.emission_date),
          deadline_date: data.deadline_date && moment(data.deadline_date)
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      resource="incomes"
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      withSubRoutes
      baseUrl="incomes"
      config={config}
    />
  );
};

export default CreateUpdateIncome;
