import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable/Datatable';

const ListUnpaid = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('invoices.columns.customer'),
      dataIndex: 'customer',
      render: customer => customer && customer.name,
      sorter: true
    },
    {
      title: t('invoices.columns.lot_reference'),
      dataIndex: 'lot',
      sorter: true
    },
    {
      title: t('invoices.columns.building'),
      dataIndex: 'building',
      sorter: true
    },
    {
      title: t('invoices.columns.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('invoices.columns.total'),
      dataIndex: 'total',
      render: total => `${parseFloat(total).toFixed(2)}€`,
      sorter: true
    }
  ];

  return (
    <DataTable
      columns={columns}
      resourceModelName="Invoice"
      resourceName="invoices"
      path="/accounting/invoices"
      populate="customer,emitter"
      extraQuery={`unpaid=${true}`}
    />
  );
};

export default ListUnpaid;
