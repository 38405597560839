import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const InterventionStatusTag = ({ status }) => {
  let statusTag;
  const { t } = useTranslation();
  switch (status) {
    case 'COMING':
      statusTag = (
        <Tag color="red">{t('interventions.form.status_enums.COMING')}</Tag>
      );
      break;
    case 'ONGOING':
      statusTag = (
        <Tag color="orange">{t('interventions.form.status_enums.ONGOING')}</Tag>
      );
      break;
    case 'OBSERVATION':
      statusTag = (
        <Tag color="blue">
          {t('interventions.form.status_enums.OBSERVATION')}
        </Tag>
      );
      break;
    default:
      statusTag = (
        <Tag color="green">{t('interventions.form.status_enums.FINISHED')}</Tag>
      );
      break;
  }
  return statusTag;
};

export default InterventionStatusTag;
