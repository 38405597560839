import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  List,
  Button,
  Popconfirm,
  Skeleton,
  Tabs,
  Input,
  Switch,
  Typography,
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import BuildingsLotsTable from './BuildingsLotsTable';
import InterventionsTable from './InterventionsTable';
import ImportFiles from '../../components/ImportFiles';
import CustomFilesSpace from './customFilesSpace';
import ContentInformation from '../../components/ContentInformation/ContentInformation';
import CommentCard from '../../components/CommentCard/CommentCard';

const { TabPane } = Tabs;
const { Title } = Typography;

const styles = {
  tabTitle: {
    textTransform: 'uppercase',
    fontSize: '0.8rem'
  }
};

const ShowBuilding = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [building, setBuilding] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/buildings/${id}?populate=company,syndic`
      });
      setBuilding(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleOk = () => {
    setVisible(false);
    fetchData();
  };

  const updateData = async values => {
    try {
      await dispatchAPI('PATCH', {
        url: `/buildings/${id}`,
        body: values
      });
      handleOk();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    })();
  }, [refresh]);

  const deleteDelivery = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/buildings/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (value, field) => {
    const body = {
      [field]: value
    };
    try {
      await dispatchAPI('PATCH', { url: `/buildings/${id}`, body });
      if (field.includes('linky')) setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('buildings.show.title')}
        extra={
          <>
            <Link to={{ pathname: `/buildings/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteDelivery}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <DescriptionList
            title="Informations"
            data={listContent(building)}
            avatar={building && building.avatar}
            hasAvatar
            translate
          />
        </Skeleton>
      </ContentInformation>
      <br />
      <ContentCustom>
        <Tabs>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>{t('buildings.show.lots')}</Title>
            }
            key="1"
          >
            <BuildingsLotsTable />
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>{t('buildings.show.pdl')}</Title>
            }
            key="2"
          >
            <List size="small" bordered style={{ width: '50%' }}>
              <List.Item>
                <div>{t('buildings.form.n_pdl_common_parts_water')}</div>
                <Input
                  defaultValue={building?.n_pdl_common_parts_water}
                  style={{ float: 'right', width: '50%' }}
                  onBlur={e =>
                    updateResource(e.target.value, 'n_pdl_common_parts_water')
                  }
                />
              </List.Item>
              <List.Item>
                <div>{t('buildings.form.n_pdl_extern_parts_water')}</div>
                <Input
                  defaultValue={building?.n_pdl_extern_parts_water}
                  style={{ float: 'right', width: '50%' }}
                  onBlur={e =>
                    updateResource(e.target.value, 'n_pdl_extern_parts_water')
                  }
                />
              </List.Item>
              <List.Item>
                <div>{t('buildings.form.n_pdl_common_parts_elec')}</div>
                <Input
                  defaultValue={building?.n_pdl_common_parts_elec}
                  style={{ float: 'right', width: '50%' }}
                  onBlur={e =>
                    updateResource(e.target.value, 'n_pdl_common_parts_elec')
                  }
                />
              </List.Item>
              <List.Item>
                <div>{t('buildings.form.n_pdl_extern_parts_elec')}</div>
                <Input
                  defaultValue={building?.n_pdl_extern_parts_elec}
                  style={{ float: 'right', width: '50%' }}
                  onBlur={e =>
                    updateResource(e.target.value, 'n_pdl_extern_parts_elec')
                  }
                />
              </List.Item>
              <List.Item>
                <div>{t('buildings.form.is_linky_common_parts')}</div>
                <Switch
                  checked={building?.is_linky_common_parts}
                  onChange={value =>
                    updateResource(value, 'is_linky_common_parts')
                  }
                />
              </List.Item>
              {building && building.is_linky_common_parts && (
                <List.Item>
                  <div>{t('buildings.form.n_electric_meter_common_parts')}</div>
                  <Input
                    defaultValue={building?.n_electric_meter_common_parts}
                    style={{ float: 'right', width: '50%' }}
                    onBlur={e =>
                      updateResource(
                        e.target.value,
                        'n_electric_meter_common_parts'
                      )
                    }
                  />
                </List.Item>
              )}
              <List.Item>
                <div>{t('buildings.form.is_linky_extern_parts')}</div>
                <Switch
                  checked={building?.is_linky_extern_parts}
                  onChange={value =>
                    updateResource(value, 'is_linky_extern_parts')
                  }
                />
              </List.Item>
              {building && building.is_linky_extern_parts && (
                <List.Item>
                  <div>{t('buildings.form.n_electric_meter_extern_parts')}</div>
                  <Input
                    defaultValue={building?.n_electric_meter_extern_parts}
                    style={{ float: 'right', width: '50%' }}
                    onBlur={e =>
                      updateResource(
                        e.target.value,
                        'n_electric_meter_extern_parts'
                      )
                    }
                  />
                </List.Item>
              )}
            </List>
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('buildings.show.interventionsList')}
              </Title>
            }
            key="3"
          >
            <InterventionsTable />
          </TabPane>
        </Tabs>
      </ContentCustom>
      <Row>
        <Col span={20}>
          <ContentCustom>
            <Title
              style={{
                paddingLeft: 24,
                textTransform: 'uppercase',
                fontSize: '1rem'
              }}
            >
              {t('buildings.show.documents')}
            </Title>
            <CustomFilesSpace
              id={id}
              files={building.files}
              forceRefresh={refresh}
              setForceRefresh={setRefresh}
              resource="buildings"
            />
          </ContentCustom>
        </Col>
        <Col
          span={4}
          style={{
            padding: '24px 24px 16px 0px',
            marginTop: 2,
            background: 'var(--componentBackground)',
            display: 'flex'
          }}
        >
          <CommentCard
            visible={visible}
            setVisible={setVisible}
            updateData={updateData}
            data={building}
          />
        </Col>
      </Row>
    </>
  );
};
export default ShowBuilding;
