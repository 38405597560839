import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  Button,
  Skeleton,
  Tabs,
  Spin,
  Row,
  Col,
  Typography,
  DatePicker,
  Modal,
  Popconfirm
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  LoadingOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import ContentInformation from '../../components/ContentInformation/ContentInformation';
import CompanyBuildingTable from './CompanyBuildingsTable';
import CashFlowDashboard from './CashFlowDashboards/index';
import CustomFilesSpace from './customFilesSpace';
import CashFlow from './CashFlow/Cashflow';
import Distribution from './Distribution/Distribution';
import CommentCard from '../../components/CommentCard/CommentCard';
import BankSynchronisation from './BankReconciliation/BankSynchronisation';
import BankReconciliation from './BankReconciliation/BankReconciliation';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;
const { Title } = Typography;

const styles = {
  tabTitle: {
    textTransform: 'uppercase',
    fontSize: '0.8rem'
  }
};

const ShowCompany = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const currentYear = moment().format('YYYY');
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [datas, setDatas] = useState({});
  const [showAccountsSettings, setShowAccountsSettings] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleCommentModal, setVisibleCommentModal] = useState(false);
  const [year, setYear] = useState(Number(currentYear));

  const onSelectYear = (_, dateString) => {
    setYear(Number(dateString));
  };

  const getDatas = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/cashflow/${id}?year=${year}&populate=buildings,lots,rentals,holding,manager,rental.building,rental`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id, refresh]);

  const handleOk = () => {
    setVisibleCommentModal(false);
    getDatas();
  };

  const updateData = async values => {
    try {
      await dispatchAPI('PATCH', {
        url: `/companies/${id}`,
        body: values
      });
      handleOk();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getDatas();
    })();
  }, [id, refresh]);

  const deleteCompany = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/companies/${id}` });
      history.goBack();
    } catch (e) {
      message.error(e);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <PageHeaderCustom
        title={t('companies.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.COMPANIES}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCompany}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          {datas?.company && (
            <DescriptionList
              title="Informations"
              data={listContent(datas.company, t)}
              avatar={datas.company.avatar}
              hasAvatar
              translate
            />
          )}
        </Skeleton>
      </ContentInformation>

      <br />

      <ContentCustom>
        <Tabs>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('companies.show.dashboard')}
              </Title>
            }
            key="1"
          >
            {isLoading ? (
              <Spin indicator={antIcon} />
            ) : (
              <>
                <div style={{ margin: '16px 0' }}>
                  <Row justify="space-between">
                    <Col>
                      <span style={{ marginRight: '8px' }}>
                        Selectionner une période :
                      </span>
                      <DatePicker
                        onChange={onSelectYear}
                        picker="year"
                        defaultValue={moment()}
                      />
                    </Col>
                    <Col>
                      <Button icon={<DownloadOutlined />}>
                        Exporter en PDF
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CashFlow id={id} year={year} />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Distribution
                      type="incomes"
                      title="Répartition: Total Entrées"
                      id={id}
                      year={year}
                    />
                  </Col>
                  <Col span={12}>
                    <Distribution
                      type="expenses"
                      title="Répartition: Total Sorties"
                      id={id}
                      year={year}
                    />
                  </Col>
                </Row>
                {datas && <CashFlowDashboard datas={datas} year={year} />}
              </>
            )}
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('companies.show.bank_reconciliation')}
              </Title>
            }
            key="2"
          >
            <Skeleton loading={isLoading}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  margin: '16px 0'
                }}
              >
                <Title style={styles.tabTitle}>VOS COMPTES</Title>
                <Button
                  onClick={() => setShowAccountsSettings(!showAccountsSettings)}
                  style={{
                    marginLeft: '8px',
                    backgroundColor: 'var(--textColor)',
                    color: 'rgba(255, 255, 255)'
                  }}
                  icon={<SettingOutlined />}
                >
                  Paramètrer les comptes
                </Button>
                <br />
              </div>
              {showAccountsSettings ? (
                <div
                  style={{
                    backgroundColor: 'rgba(125, 190, 181, 0.13)',
                    padding: '16px',
                    borderRadius: '6px'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <span style={{ marginRight: '8px' }}>
                      <SettingOutlined />
                    </span>
                    <Title
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '0.8rem',
                        textDecoration: 'underline'
                      }}
                    >
                      Gérer vos comptes pour le rapprochement bancaire
                    </Title>
                  </div>
                  <Button
                    style={{
                      marginTop: '16px',
                      backgroundColor: 'var(--textColor)',
                      color: 'rgba(255, 255, 255)'
                    }}
                    icon={<PlusCircleOutlined />}
                    onClick={() => setVisible(!visible)}
                  >
                    Connecter une nouvelle banque
                  </Button>
                  <Modal
                    destroyOnClose
                    onCancel={handleCancel}
                    visible={visible}
                    footer={null}
                    width={1000}
                  >
                    <BankSynchronisation />
                  </Modal>
                  {accounts.map(account => (
                    <>
                      <Row
                        style={{ display: 'flex', alignItems: 'baseline' }}
                        justify="space-evenly"
                      >
                        <Col span={3}>
                          <Title
                            style={{
                              fontSize: '1rem',
                              color: 'var(--textColorSecondary)'
                            }}
                          >
                            {account?.institution_id.split('_')[0]}
                          </Title>
                        </Col>
                        <Col span={1}>
                          <Button
                            onClick={() =>
                              updateData({
                                $pull: { accounts_ids: account.id }
                              })
                            }
                            style={{ marginLeft: '16px', color: 'red' }}
                            type="link"
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                        <Col span={16}>
                          <Button
                            size="large"
                            style={{
                              marginLeft: '16px',
                              backgroundColor: 'var(--primaryColor)',
                              color: 'rgba(255, 255, 255)',
                              fontSize: '0.9rem'
                            }}
                          >
                            {`Compte ${datas?.company?.name} : ${account.balances.amount}`}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ))}
                </div>
              ) : null}
              <BankReconciliation
                showAccountsSettings={showAccountsSettings}
                setAllAccounts={setAccounts}
              />
            </Skeleton>
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('companies.show.buildingsTable')}
              </Title>
            }
            key="3"
          >
            <CompanyBuildingTable />
          </TabPane>
        </Tabs>
      </ContentCustom>
      <Row>
        <Col span={20}>
          <ContentCustom>
            <Title
              style={{
                paddingLeft: 24,
                textTransform: 'uppercase',
                fontSize: '1rem'
              }}
            >
              {t('buildings.show.documents')}
            </Title>
            <CustomFilesSpace
              id={id}
              files={datas && datas.company && datas.company.files}
              forceRefresh={refresh}
              setForceRefresh={setRefresh}
              resource="buildings"
            />
          </ContentCustom>
        </Col>
        <Col
          span={4}
          style={{
            padding: '24px 24px 16px 0px',
            marginTop: 2,
            background: 'var(--componentBackground)',
            display: 'flex'
          }}
        >
          <CommentCard
            visible={visibleCommentModal}
            setVisible={setVisibleCommentModal}
            updateData={updateData}
            data={datas && datas.company}
          />
        </Col>
      </Row>
    </>
  );
};

export default ShowCompany;
