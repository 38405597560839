import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Form, Input, Typography, Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title } = Typography;
const { Meta } = Card;

const CommentCard = ({ visible, setVisible, updateData, data, extraStyle }) => {
  const [form] = Form.useForm();

  const setFieldsValues = () => {
    form.setFieldsValue({
      comment: data.comment
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleEditComment = () => {
    setFieldsValues();
    setVisible(true);
  };

  const handleSubmit = async values => {
    await updateData(values);
  };

  const commentsHeader = (
    <>
      <Row justify="space-around" align="middle">
        <Col span={20}>
          <Title style={{ fontSize: '1rem', ...extraStyle }}>Commentaire</Title>
        </Col>
        <Col span={4}>
          <Button type="link" onClick={handleEditComment}>
            <EditOutlined />
          </Button>
        </Col>
      </Row>
      <Modal
        visible={visible}
        title="Commentaire"
        onCancel={handleCancel}
        onOk={handleSubmit}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="comment">
            <TextArea
              value={data?.comment}
              placeholder="Entrez votre commentaire"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Row justify="center" gutter={[8, 0]}>
            <Col>
              <Button onClick={handleCancel}>Annuler</Button>
            </Col>
            <Col>
              <Button htmlType="submit" onClick={handleSubmit}>
                Sauvegarder
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );

  return (
    <Card
      bordered={false}
      style={{
        background: 'rgb(38, 100, 123, 0.04)',
        borderRadius: '6px',
        minHeight: '200px',
        flex: 1
      }}
    >
      <Meta
        style={{ fontSize: '0.9rem' }}
        title={commentsHeader}
        description={data?.comment}
      />
    </Card>
  );
};

CommentCard.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    comment: PropTypes.string
  }),
  extraStyle: PropTypes.shape({})
};

CommentCard.defaultProps = {
  data: null,
  extraStyle: null
};

export default CommentCard;
