import React, { useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Datatable } from '../../../components';
import useColumns from '../../RentReviews/columns';
import { SearchAddBar } from '../../../utils/constants/customButton';

const RentReviewsTable = () => {
  const columns = useColumns();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState();
  const { pathname } = useLocation();
  const split = pathname.split('/');

  return (
    <>
      <SearchAddBar
        setSearchValue={setSearchValue}
        addPath={`/rent-reviews/create/${id}`}
      />
      <Datatable
        resourceName="rent-reviews"
        customActionColumn
        columns={columns}
        populate="lot,customer"
        extraQuery={`customer=${id}`}
        searchValue={searchValue}
        path="/rent-reviews"
      />
    </>
  );
};

export default RentReviewsTable;
