import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateRentals = ({ purpose }) => {
  const [works, setWorks] = useState(false);
  const [fees, setFees] = useState(false);
  const { fields, isFieldsLoading, per } = useFields(
    works,
    setWorks,
    fees,
    setFees
  );

  const config = {
    onGetResource: {
      setFields: data => {
        setWorks(data.works);
        setFees(data.fees);
        return {
          ...data,
          start_date: moment(data.start_date)
        };
      }
    },
    onCreateResource: {
      setBody: data => {
        return {
          ...data,
          works,
          fees
        };
      }
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          works,
          fees
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="rentals"
      resource="rentals"
      loadingFields={isFieldsLoading}
      per={per}
      withSubRoutes
      config={config}
    />
  );
};

CreateUpdateRentals.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateRentals;
