import React, { useCallback, useEffect, useState } from 'react';
import { Input, DatePicker, Select, Switch, Upload, Form } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = ({
  customerType,
  setCustomerType,
  managerType,
  setManagerType,
  handleSiretChange,
  sirenStatus
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [address, setAddress] = useState('');
  const [addressManager, setAddressManager] = useState('');
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState();

  const fileToBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const commonFields = [
    {
      label: 'email',
      name: ['email'],
      rules: [{ required: true }]
    },
    {
      label: 'phone',
      name: 'phone',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'hq_address',
      name: ['hq_address'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Rechercher ...',
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'bill_address',
      name: ['bill_address'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Rechercher ...',
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'bill_mode',
      name: 'bill_mode',
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.billMode || []).map(form => (
            <Option key={form} value={form}>
              {t(`customers.billMode.${form}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'bill_period',
      name: 'bill_period',
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.billPeriod || []).map(period => (
            <Option key={period} value={period}>
              {t(`customers.billPeriod.${period}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'payment_method',
      name: 'payment_method',
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.paymentMethod || []).map(method => (
            <Option key={method} value={method}>
              {t(`customers.paymentMethod.${method}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['avatar'],
      input: (
        <Dragger {...draggerProps} style={{ borderRadius: '10px' }}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  const businessFields = [
    {
      name: ['type'],
      input: (
        <Switch
          checkedChildren={t('customers.type.business')}
          unCheckedChildren={t('customers.type.personal')}
          onClick={() => setCustomerType(!customerType)}
          checked={customerType}
        />
      )
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      label: 'date_of_birth',
      name: ['date_of_birth'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      label: 'place_of_birth',
      name: ['place_of_birth'],
      rules: [{ required: true }]
    },
    {
      name: ['legal_form'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.legalForms || []).map(form => (
            <Option key={form} value={form}>
              {t(`customers.legalForms.${form}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['ape_number'],
      rules: [{ required: true }]
    },
    {
      name: ['ape_description'],
      rules: [{ required: true }]
    },
    {
      name: ['siren'],
      rules: [{ required: true }]
    },
    {
      name: ['social_capital']
    },
    ...commonFields,
    {
      name: ['manager', 'type'],
      input: (
        <Select
          options={['Personne morale', 'Personne physique'].map(label => ({
            label,
            value: label
          }))}
          onChange={value => setManagerType(value)}
        />
      )
    },
    {
      name: ['manager', 'gender'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.gender || []).map(title => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['manager', 'first_name']
    },
    {
      name: ['manager', 'last_name']
    },
    ...(managerType === 'Personne morale'
      ? [
          {
            name: ['manager', 'rcs_number'],
            rules: [{ required: true, message: t('errors.form.SIREN') }],
            hasFeedback: sirenStatus,
            input: <Input onChange={handleSiretChange} />
          },
          {
            name: ['manager', 'email']
          },
          {
            name: ['manager', 'phone']
          },
          {
            name: ['manager', 'address'],
            input: (
              <PlacesAutocomplete
                value={addressManager}
                onChange={value => setAddressManager(value)}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        placeholder: 'Rechercher ...',
                        className: 'location-search-input'
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )
          }
        ]
      : [])
  ];

  const personalFields = [
    {
      name: ['type'],
      input: (
        <Switch
          checkedChildren={t('customers.type.business')}
          unCheckedChildren={t('customers.type.personal')}
          onClick={() => setCustomerType(!customerType)}
          checked={customerType}
        />
      )
    },
    {
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.gender || []).map(title => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'date_of_birth',
      name: ['date_of_birth'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      label: 'place_of_birth',
      name: ['place_of_birth'],
      rules: [{ required: true }]
    },
    ...commonFields
  ];

  const fields = customerType ? businessFields : personalFields;

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64
  };
};

export default useFields;
