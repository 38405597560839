import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components';
import useColumns from '../../Receipts/columns';
import { routes, subRoutes } from '../../../utils/constants/routes';

const CustomerReceiptsTable = () => {
  const { t } = useTranslation();
  const columns = useColumns(t, 'receipts');
  const { id } = useParams();

  return (
    <Datatable
      resourceName="receipts"
      columns={columns}
      populate="rental,building,lot,customer"
      extraQuery={`customer=${id}`}
      path="/receipts"
      showAction={{
        pathname: record =>
          `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.RECEIPTS}/show/${record?.key}`
      }}
      editAction={{
        pathname: record =>
          `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.RECEIPTS}/edit/${record?.key}`
      }}
    />
  );
};

export default CustomerReceiptsTable;
