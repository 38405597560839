import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Typography, Row, Col, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';

const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  hasAvatar,
  avatar
}) => {
  const { t } = useTranslation();

  return (
    <div className="information-card">
      <h1>
        {title}
      </h1>
      <Row gutter={[16, 16]}>
        {hasAvatar && (
          <Col span={4}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {avatar ? (
                <Avatar size={116} src={avatar} />
              ) : (
                <Avatar size={116} icon={<UserOutlined />} />
              )}
            </div>
          </Col>
        )}
        <Col span={hasAvatar ? 20 : 24}>
          <Descriptions
            layout="vertical"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            {data &&
              data.map(
                (item, idx) =>
                  (item.content !== '-' || !skipEmpty) && (
                    <Descriptions.Item
                      label={translate ? t(`${item.label}`) : item.label}
                      span={item.span || 1}
                      key={idx}
                    >
                      <Typography style={{ fontWeight: 700 }}>
                        {item.content || '-'}
                      </Typography>
                    </Descriptions.Item>
                  )
              )}
          </Descriptions>
        </Col>
      </Row>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false
};

export default DescriptionList;
