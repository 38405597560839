const useListContent = ({
  name,
  address_hq,
  siren,
  siret,
  number_tva,
  manager,
  share_capital
}) => {
  const content = [
    {
      label: 'holdings.form.name',
      content: name
    },
    {
      label: 'holdings.form.address_hq',
      content: address_hq
    },
    {
      label: 'holdings.form.siren',
      content: siren
    },
    {
      label: 'holdings.form.siret',
      content: siret
    },
    {
      label: 'holdings.form.number_tva',
      content: number_tva
    },
    {
      label: 'holdings.form.manager,last_name',
      content: manager && manager?.last_name
    },
    {
      label: 'holdings.form.manager,first_name',
      content: manager && manager?.first_name
    },
    {
      label: 'holdings.form.manager,email',
      content: manager && manager?.email
    },
    {
      label: 'holdings.form.manager,phone',
      content: manager && manager?.phone
    },
    {
      label: 'holdings.form.share_capital',
      content: share_capital
    }
  ];
  return content;
};

export default useListContent;
