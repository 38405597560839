const headers = [
  {
    label: 'company',
    key: ('company' && 'company.name') || ''
  },
  {
    label: 'lot',
    key: ('lot' && 'lot.reference') || ''
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'tenant',
    key: 'tenant' && 'tenant.name'
  },
  {
    label: 'start_date',
    key: 'start_date'
  },
  {
    label: 'end_date',
    key: 'end_date'
  }
];

export default headers;
