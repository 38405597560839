import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('archives.columns.filename'),
      key: 'filename',
      dataIndex: 'filename',
      sorter: true
    },
    {
      title: t('archives.columns.period'),
      key: 'period',
      dataIndex: 'period',
      sorter: true
    },
    {
      title: t('archives.columns.type'),
      key: 'type',
      dataIndex: 'type',
      sorter: true
    }
    // add action column to handle download action
  ];
};

export default useColumns;
