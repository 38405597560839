import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/plots';
import { Typography, Spin, Empty } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import colors from './Colors';

const { Title } = Typography;

const styles = {
  container: {
    backgroundColor: 'rgba(38, 100, 123, 0.06)',
    padding: '24px',
    borderRadius: '6px'
  }
};

const Distribution = ({ type, id, title, year }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/distribution/${type}/${id}?year=${year}`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    })();
  }, [year]);

  const config = {
    appendPadding: 10,
    data: type === 'incomes' ? datas.incomes || [] : datas.expenses || [],
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'outer',
      labelHeight: 28,
      content: '{name}\n{percentage}'
    },
    tooltip: {
      formatter: values => {
        return {
          name: values.type,
          value: `${values.value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        };
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    color: values => colors(type, values.type)
  };

  return type === 'incomes' ? (
    <div style={styles.container}>
      <Title level={5}>{title}</Title>
      <Spin spinning={isLoading}>
        {!datas?.incomes?.length ? <Empty /> : <Pie {...config} />}
      </Spin>
    </div>
  ) : (
    <div style={styles.container}>
      <Title level={5}>{title}</Title>
      <Spin spinning={isLoading}>
        {!datas?.expenses?.length ? <Empty /> : <Pie {...config} />}
      </Spin>
    </div>
  );
};

Distribution.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired
};

export default Distribution;
