import React from 'react';
import useColumns from './columns';
import EditableTable from '../../EditableTable/EditableTable';

const ManagingTaxesCFE = ({
  dataSource,
  isLoading,
  year,
  type,
  refresh,
  setRefresh
}) => {
  const columns = useColumns({ year });

  return (
    <EditableTable
      defaultColumns={columns}
      data={dataSource}
      isLoading={isLoading}
      year={year}
      type={type}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
};

export default ManagingTaxesCFE;
