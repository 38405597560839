import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ContextProvider } from './context';
import List from './index';
import CreateUpdate from './createUpdate';
import Show from './show';

const Router = () => {
  const { path } = useRouteMatch();

  return (
    <ContextProvider collection="receipts">
      <Switch>
        <Route
          path={`${path}/create`}
          render={() => <CreateUpdate purpose="create" />}
        />
        {/* <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdate purpose="edit" />}
        /> */}
        <Route path={`${path}/show/:id`} render={() => <Show />} />
        <Route path={path} component={List} />
      </Switch>
    </ContextProvider>
  );
};

export default Router;
