import React, { useCallback, useEffect, useState } from 'react';
import {
  Select,
  Input,
  DatePicker,
  Checkbox,
  Form,
  Space,
  Button,
  InputNumber
} from 'antd';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { rentalEnums } from '../../utils/constants/enumsTrad';

const { Option } = Select;

const useFields = (works, setWorks, fees, setFees) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [lots, setLots] = useState([]);
  const [filteredLots, setFilteredLots] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [duration, setDuration] = useState(false);
  const [deposit, setDeposit] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [per, setPer] = useState(0);

  const setPart = event => {
    setPer(Number(100 - event));
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onSelectBuilding = value => {
    const building = buildings.find(({ _id }) => _id === value);
    const buildingLots = building.lots;
    const newLots = lots.filter(({ _id }) => buildingLots.includes(_id));
    setFilteredLots(newLots);
  };

  const onSelectType = value => {
    if (value === 'SHORT' || value === 'FIRM') return setDuration(true);
    return setDuration(false);
  };

  const getLots = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/lots' });
      setLots(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/rentals/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBuildings = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/buildings' });
      setBuildings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/suppliers?type=611a2116479a656c248c358a'
      });
      setSuppliers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCustomers();
    await getSuppliers();
    await getLots();
    await getBuildings();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      label: 'building',
      name: ['building'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          onSelect={onSelectBuilding}
          allowClear
        >
          {(buildings || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lots',
      name: ['lot'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(filteredLots || []).map(elem => (
            <Option key={elem._id} value={elem._id}>
              {elem.reference}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'tenant',
      name: ['tenant'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(customers || []).map(elem => (
            <Option key={elem.name} value={elem._id}>
              {elem.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'type',
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear onSelect={onSelectType}>
          {(enums.type || []).map(el => (
            <Option key={el} value={el}>
              {t(`rentals.type.${el}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'duration',
      name: ['duration'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      label: 'start_date',
      name: ['start_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      label: 'rent_free',
      name: ['rent_free'],
      input: (
        <Input
          type="number"
          min="0"
          placeholder={t('rentals.form.rent_free_ph')}
        />
      )
    },
    {
      label: 'rent_period',
      name: ['rent_period'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.period || []).map(elem => (
            <Option key={elem} value={elem}>
              {t(`rentals.form.period.${elem}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'amounts',
      name: ['amounts'],
      rules: [{ required: true }],
      input: (
        <Form.List name={['amounts']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                    justifyContent: 'center'
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'year']}
                    fieldKey={[fieldKey, 'year']}
                    initialValue={fields.length}
                  >
                    {`${t('rentals.form.addAmount')} ${name + 1}`}
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    fieldKey={[fieldKey, 'value']}
                  >
                    <InputNumber
                      step="0.01"
                      formatter={value => `${value}€`}
                      parser={value => value.replace('€', '')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  {`${t('rentals.form.addAmount')} ${fields.length + 1}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      label: 'payment_method',
      name: ['payment_method'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.payment_method || []).map(elem => (
            <Option key={elem} value={elem}>
              {t(`rentals.form.payment_methods.${elem}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'insee_type',
      name: ['insee_type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.insee_type || []).map(elem => (
            <Option key={elem} value={elem}>
              {elem}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'rental_charges_contribution',
      name: ['rental_charges_contribution'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          min={0}
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      label: 'rental_taxes_contribution',
      name: ['rental_taxes_contribution'],
      input: (
        <InputNumber
          min={0}
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      label: 'charges_revision',
      name: ['charges_revision'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {enums.charges_revision?.map(el => (
            <Option key={el} value={el}>
              {rentalEnums[el]}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'fees',
      name: ['fees'],
      input: <Checkbox checked={fees} onChange={() => setFees(!fees)} />
    },
    {
      label: 'commercial_supplier',
      name: ['commercial_supplier'],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {suppliers.map(el => (
            <Option key={el._id} value={el._id}>
              {el.name}
            </Option>
          ))}
        </Select>
      ),
      hidden: !fees
    },
    {
      label: 'commercial_fees',
      name: ['commercial_fees'],
      input: <InputNumber min={0} max={100} style={{ width: '100%' }} />,
      hidden: !fees
    },
    {
      label: 'works',
      name: ['works'],
      input: <Checkbox checked={works} onChange={() => setWorks(!works)} />
    },
    ...(works
      ? [
          {
            label: 'works_amount',
            name: ['works_amount'],
            input: <InputNumber min={0} style={{ width: '100%' }} />
          },
          {
            label: 'tenant_part',
            name: ['tenant_part'],
            input: (
              <InputNumber
                min={0}
                max={100}
                maxlength={2}
                onChange={setPart}
                style={{ width: '100%' }}
              />
            )
          },
          {
            label: 'bailleur_part',
            name: ['bailleur_part'],
            input: (
              <InputNumber
                min={0}
                max={100}
                disabled="disabled"
                style={{ width: '100%' }}
                value={per}
              />
            )
          }
        ]
      : []),
    {
      label: 'recap_works',
      name: ['recap_works'],
      input: (
        <Form.List name={['recap_works']}>
          {(work, { add, remove }) => (
            <>
              {work.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  align="baseline"
                >
                  <Form.Item {...restField} name={[name]}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  {`${t('rentals.form.add_work')} ${work.length + 1}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      label: 'anticipation_works',
      name: ['anticipation_works'],
      input: (
        <Form.List name={['anticipation_works']}>
          {(work, { add, remove }) => (
            <>
              {work.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  align="baseline"
                >
                  <Form.Item {...restField} name={[name]}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  {`${t('rentals.form.add_work')} ${work.length + 1}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      label: 'deposit',
      name: ['deposit'],
      input: (
        <Checkbox checked={deposit} onChange={() => setDeposit(!deposit)} />
      )
    },
    {
      label: 'period_deposit',
      name: ['period_deposit'],
      input: <InputNumber min={0} style={{ width: '100%' }} />,
      hidden: !deposit
    },
    {
      label: 'payment_deposit',
      name: ['payment_deposit'],
      hidden: !deposit,
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.period || []).map(elem => (
            <Option key={elem} value={elem}>
              {t(`rentals.form.payment_deposits.${elem}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'exemptions',
      name: ['exemptions'],
      input: (
        <Form.List name={['exemptions']}>
          {(work, { add, remove }) => (
            <>
              {work.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  align="baseline"
                >
                  <Form.Item {...restField} name={[name]}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  {`${t('rentals.form.exemptions_add')} ${work.length + 1}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
  ];

  return {
    fields,
    isFieldsLoading,
    per
  };
};

export default useFields;
