import { useTranslation } from 'react-i18next';

const useColumns = ({ companies, typeFilters }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('buildings.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('buildings.form.company'),
      key: 'company',
      dataIndex: 'company',
      render: company => company && company.name,
      sorter: true,
      filters: companies,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.company && record.company._id.startsWith(value)
    },
    {
      title: t('buildings.form.type_company'),
      key: 'type_company',
      dataIndex: 'type_company',
      render: type => t(`buildings.form.${type}`),
      sorter: true,
      filters: typeFilters,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.type_company && record.type_company.startsWith(value)
    },
    {
      title: t('buildings.form.address'),
      key: 'address',
      dataIndex: 'address',
      sorter: true,
      width: '35%'
    },
    {
      title: t('buildings.form.lots'),
      key: 'lots',
      dataIndex: 'lots',
      render: lots => lots.length,
      sorter: true
    }
  ];
};

export default useColumns;
