import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components';

const CompaniesTable = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const columns = [
    {
      title: t('companies.form.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('companies.form.siret'),
      dataIndex: 'siret_number',
      sorter: true
    },
    {
      title: t('companies.form.legal_form'),
      dataIndex: 'legal_form',
      sorter: true
    }
  ];

  return (
    <Datatable
      resourceName="companies"
      columns={columns}
      extraQuery={`holding=${id}`}
      path="/companies"
    />
  );
};

export default CompaniesTable;
