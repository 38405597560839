import React, { useState } from 'react';
import { Modal, DatePicker } from 'antd';
import useContext from '../context';

const ReceiptModal = ({
  isModalVisible,
  setIsModalVisible,
  refresh,
  setRefresh
}) => {
  const { t, collection, postResource } = useContext();
  const [date, setDate] = useState();

  const handleOk = async () => {
    await postResource({ date });
    setRefresh(!refresh);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={t(`${collection}.new_receipts`)}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <DatePicker
        picker="month"
        value={date}
        onChange={dates => setDate(dates)}
        style={{ width: '100%' }}
        format="MMMM YYYY"
      />
    </Modal>
  );
};

export default ReceiptModal;
