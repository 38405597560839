import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Select, Input, DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ManagingTable from './Managing/ManagingTable';
import ManagingTaxesTVATable from './TaxesTVA/ManagingTaxesTVATable';
import ManagingCPTable from './ManagingCP/ManagingCPTable';
import ManagingCFETable from './ManagingCFE/ManagingCFETable';
import ManagingTaxesTFTable from './TaxesTF/ManagingTaxesTFTable';
import ManagingTaxesCFETable from './TaxesCFE/ManagingTaxesCFETable';
import ManagingLoansTable from './ManagingLoans/ManagingLoansTable';
import ManagingExerciceResultTable from './ManagingExerciceResult/ManagingExerciceResultTable';
import ManagingISTable from './ManagingIS/ManagingISTable';
import ContentInformation from '../../../components/ContentInformation/ContentInformation';
import ExportButton from '../../../components/ExportButton/ExportButton';
import managingHeaders from './Managing/headers';
import managingCFEHeaders from './ManagingCFE/headers';
import managingCPHeaders from './ManagingCP/headers';
import managingISHeaders from './ManagingIS/headers';
import managingTaxesTFHeaders from './TaxesTF/headers';
import managingTaxesCFEHeaders from './TaxesCFE/headers';
import managingTaxesTVAHeaders from './TaxesTVA/headers';
import managingLoansHeaders from './ManagingLoans/headers';
import formatter from '../utils';

const { Option } = Select;
const { Search } = Input;

const ShowManaging = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [managingTypes, setManagingTypes] = useState([]);
  const [filters, setFilters] = useState([]);
  const [activeTable, setActiveTable] = useState();
  const [holdings, setHoldings] = useState([]);
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();
  const [holdingID, setHoldingID] = useState();
  const [managing, setManaging] = useState([]);
  const [year, setYear] = useState(moment());
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTrimester, setSelectedTrimester] = useState();
  const [headers, setHeaders] = useState([]);
  const [disableExportButton, setDisableExportButton] = useState(true);

  const getHoldings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/holdings'
      });
      setHoldings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const handleOnSelectSelectedTrimester = value => {
    setSelectedTrimester(value);
  };

  const handleOnchangeyear = date => {
    setYear(moment(date));
  };

  const handleOnManagingType = (value, data, trimester) => {
    let input;
    switch (value) {
      case 'managing':
        input = (
          <Row gutter={[16, 16]}>
            <Col>
              <Select
                value={trimester}
                onSelect={handleOnSelectSelectedTrimester}
                placeholder="Selectionner un trimestre"
              >
                {(data.managing.filters || []).map(f => (
                  <Option key={f} value={f}>
                    {t(`managings.trimesters.${f}`)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <DatePicker
                picker="year"
                defaultValue={year}
                onChange={handleOnchangeyear}
              />
            </Col>
          </Row>
        );
        break;
      default:
        input = (
          <DatePicker
            picker="year"
            defaultValue={year}
            onChange={handleOnchangeyear}
          />
        );
        break;
    }
    if (trimester) {
      setSelectedTrimester(trimester);
    }
    setActiveTable(value);
    setFilters(input);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/managings/enums' });
      const enumsList = Object.entries(data).map(d => d[0]);
      setManagingTypes(enumsList);
      setActiveTable(enumsList[0]);
      if (data[enumsList[0]].filters) {
        setSelectedTrimester(data[enumsList[0]].filters);
        handleOnManagingType(enumsList[0], data, data.managing.filters[0]);
      } else {
        handleOnManagingType(enumsList[0], data);
      }
      setEnums(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getManaging = async (holding_id, active_table) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/managings?holding=${holding_id}&type=${active_table}&year=${active_table !==
          'managing_exercice_result' && year.year()}`
      });
      if (activeTable === 'managing') {
        data.forEach(d => {
          if (d.managing && d.managing.levy_date) {
            d.managing.levy_date = moment(d.managing.levy_date);
          }
        });
      } else if (activeTable === 'managing_loans') {
        data.forEach(d => {
          if (d.managing && d.managing.deadline_date) {
            d.managing.deadline_date = moment(d.managing.deadline);
          }
        });
      }
      setManaging(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getHoldings();
      await getEnums();
    })();
  }, []);

  useEffect(() => {
    if (selectedTrimester && activeTable && enums) {
      handleOnManagingType(activeTable, enums, selectedTrimester);
    }
  }, [selectedTrimester, activeTable, enums]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (activeTable && holdingID) {
        await getManaging(holdingID, activeTable);
        switch (activeTable) {
          case 'managing':
            setHeaders(managingHeaders);
            break;
          case 'managing_cfe':
            setHeaders(managingCFEHeaders);
            break;
          case 'managing_cp':
            setHeaders(managingCPHeaders);
            break;
          case 'managing_is':
            setHeaders(managingISHeaders);
            break;
          case 'managing_taxes_tva':
            setHeaders(managingTaxesTVAHeaders);
            break;
          case 'managing_taxes_cfe':
            setHeaders(managingTaxesCFEHeaders);
            break;
          case 'managing_taxes_tf':
            setHeaders(managingTaxesTFHeaders);
            break;
          case 'managing_loans':
            setHeaders(managingLoansHeaders);
            break;
          default:
            break;
        }
        if (holdingID) {
          setDisableExportButton(false);
        }
      }
    })();
    setIsLoading(false);
  }, [activeTable, holdingID, refresh, year]);

  const handleOnSelectHolding = value => {
    setHoldingID(value);
  };

  const renderTables = table => {
    let render = null;
    switch (table) {
      case 'managing':
        render = (
          <ManagingTable
            dataSource={managing}
            isLoading={isLoading}
            enums={enums && enums.managing}
            selectedTrimester={selectedTrimester}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_taxes_tva':
        render = (
          <ManagingTaxesTVATable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_cp':
        render = (
          <ManagingCPTable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_cfe':
        render = (
          <ManagingCFETable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_taxes_tf':
        render = (
          <ManagingTaxesTFTable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_taxes_cfe':
        render = (
          <ManagingTaxesCFETable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_loans':
        render = (
          <ManagingLoansTable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_exercice_result':
        render = (
          <ManagingExerciceResultTable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      case 'managing_is':
        render = (
          <ManagingISTable
            dataSource={managing}
            isLoading={isLoading}
            year={year.year()}
            type={activeTable}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
        break;
      default:
        break;
    }
    return render;
  };

  return (
    <>
      <PageHeaderCustom title={t('managings.show.title')} />
      <ContentInformation>
        <Row gutter={16} justify="space-between">
          <Col>
            <Row gutter={16}>
              <Col>
                <Select
                  onSelect={value => handleOnManagingType(value, enums)}
                  value={activeTable}
                >
                  {managingTypes.map(type => (
                    <Option key={type} value={type}>
                      {t(`managings.enums.${type}`)}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col>
                <Select placeholder="Holding" onSelect={handleOnSelectHolding}>
                  {holdings.map(hold => (
                    <Option key={hold._id} value={hold._id}>
                      {hold.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col>{filters}</Col>
              <Col>
                <Search size="middle" />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={16}>
              <Col>
                <Button>Saisir un nouvel exercice</Button>
              </Col>
              <Col>
                <Button disabled={disableExportButton}>
                  <ExportButton
                    dataName="managings"
                    headers={headers}
                    url={`/managings?holding=${holdingID}&type=${activeTable}&year${activeTable !==
                      'managing_exercice_result' && year.year()}`}
                    fileName="managings.csv"
                    populate="company"
                    formatter={formatter}
                    // extraQuery={extraQuery}
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {renderTables(activeTable)}
      </ContentInformation>
    </>
  );
};

export default ShowManaging;
