import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const LoginForm = ({ switchForm }) => {
  const location = useLocation();
  const { isValid, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { from } = (location && location.state) || { from: { pathname: '/' } };

  const loginUser = async (email, password, rememberMe) => {
    setLoading(true);
    try {
      await dispatchAPI('LOGIN', {
        email,
        password,
        rememberMe
      });
      message.success(t('login.success'));
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async values => {
    await loginUser(
      values.username.toLowerCase(),
      values.password,
      values.remember
    );
  };

  return (
    <Form
      requiredMark={false}
      onFinish={handleSubmit}
      initialValues={{ remember: true }}
      layout="vertical"
    >
      {isValid && <Redirect to={from} />}
      <Form.Item
        label={<label style={{ color: 'white' }}>{t('login.username')}</label>}
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
        />
      </Form.Item>
      <Form.Item
        label={<label style={{ color: 'white' }}>{t('login.password')}</label>}
        name="password"
        style={{ marginTop: 30 }}
        rules={[{ required: true, message: t('login.pwdMissing') }]}
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'left' }}>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox style={{ color: 'white', borderRadius: '5px' }}>
            {t('login.rememberMe')}
          </Checkbox>
        </Form.Item>
        <Button
          type="link"
          style={{ float: 'right', padding: 0, height: 'auto', color: 'white' }}
          onClick={() => switchForm('forgotPwd')}
        >
          {t('login.forgotPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: '100%',
            backgroundColor: '#26647B',
            borderColor: 'white'
          }}
          loading={isLoading}
        >
          {t('login.connect')}
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default LoginForm;
