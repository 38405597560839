import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import headers from './headers';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const { Option } = Select;

const ListBuildings = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [holdings, setHoldings] = useState([]);
  const [companies, setcompanies] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [extraQuery, setExtraQuery] = useState(null);
  const [typeFilters, setTypeFilters] = useState([]);
  const columns = useColumns({ companies, typeFilters });

  const getHoldings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/holdings'
      });
      setHoldings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      const listCompaniesName = data.map(({ name, _id }) => {
        return {
          text: name,
          value: _id
        };
      });
      setcompanies(listCompaniesName);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/buildings/enums'
      });
      const company_type = data.company_type.map(type => {
        return {
          text: t(`buildings.form.${type}`),
          value: type
        };
      });
      setTypeFilters(company_type);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const onChangeHolding = value => {
    setExtraQuery(value);
    setRefresh(!refresh);
  };

  useEffect(() => {
    (async () => {
      await getHoldings();
      await getCompanies();
      await getEnums();
    })();
  }, []);

  const holdingSelection = (
    <Select
      onChange={onChangeHolding}
      placeholder="Holding"
      allowClear
      style={{ marginRight: '16px' }}
    >
      {holdings.map(({ _id, name }) => (
        <Option key={_id} value={_id}>
          {name}
        </Option>
      ))}
    </Select>
  );

  return (
    <SimpleResourceLandingLayout
      resourceName="buildings"
      columns={columns}
      headers={headers}
      populate="company,lots"
      extraQuery={extraQuery && `holding=${extraQuery}`}
      resourceModelName="Building"
      sortDefault="name"
      withUploadButton
      extraButtons={holdingSelection}
      forceRefresh={refresh}
      scroll={{ x: 1300 }}
    />
  );
};

export default ListBuildings;
