import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, InputNumber, Select, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useFields = purpose => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);

  const fields1 = [{ name: ['wording'], rules: [{ required: true }] }];
  if (purpose === 'edit') {
    fields1.push({
      name: ['number'],
      input: <Input disabled />,
      initialValue: `P${moment().format('YYYYMMDD')}00001`
    });
  }
  fields1.push(
    {
      name: ['emitter'],
      rules: [{ required: true }],
      input: (
        <Select
          options={companies.map(({ name, _id }) => ({
            label: name,
            value: _id
          }))}
          loading={isLoading}
        />
      )
    },
    {
      name: ['date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      name: ['payment'],
      rules: [{ required: true }],
      input: (
        <Select
          options={(enums.payments || []).map(payment => ({
            label: (
              <Tag color="blue">{t(`invoices.form.payments.${payment}`)}</Tag>
            ),
            value: payment
          }))}
          loading={isLoading}
        />
      )
    }
  );

  const fields2 = [
    {
      name: ['total_discount'],
      input: (
        <InputNumber
          disabled
          style={{ width: '100%' }}
          defaultValue={0}
          formatter={value => value && `${value}€`}
          parser={value => value.replace('€', '')}
        />
      )
    },
    {
      name: ['subTotal'],
      input: (
        <InputNumber
          disabled
          style={{ width: '100%' }}
          defaultValue={0}
          formatter={value => value && `${value}€`}
          parser={value => value.replace('€', '')}
        />
      )
    },
    {
      name: ['total_VAT'],
      input: (
        <InputNumber
          disabled
          defaultValue={0}
          style={{ width: '100%' }}
          formatter={value => value && `${value}€`}
          parser={value => value.replace('€', '')}
        />
      )
    },
    {
      name: ['total'],
      input: (
        <InputNumber
          defaultValue={0}
          style={{ visibility: 'hidden' }}
          disabled
          formatter={value => value && `${value}€`}
          parser={value => value.replace('€', '')}
        />
      ),
      noLabel: true
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    await getCompanies();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    fields1,
    fields2
  };
};

export default useFields;
