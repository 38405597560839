import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateHolding = ({ purpose }) => {
  const { fields, base64 } = useFields();

  const config = {
    onGetResource: {
      setFields: data => {
        return {
          ...data,
          manager: {
            ...data.manager,
            date_of_birth: moment(data?.manager?.date_of_birth)
          }
        };
      }
    },
    onCreateResource: {
      setBody: data => {
        return {
          ...data,
          logo: base64
        };
      }
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          ...(base64 ? { logo: base64 } : {})
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="holdings"
      resource="holdings"
      config={config}
    />
  );
};

CreateUpdateHolding.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateHolding;
