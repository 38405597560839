import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Popconfirm, Table, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useHandleResize from '../../utils/HandleResize';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const iconSize = 18;

const Datatable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  populate,
  extraQuery,
  forceRefresh,
  editAction,
  showAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable
}) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });

  const { width } = useHandleResize();
  const isXSScreen = width < 861;

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);

      const searchURL = searchValue ? `/search/${searchValue}` : null;

      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;
      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers['x-total-count'], 10)
        });
        setResources(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [
      searchValue,
      currentPage,
      pageSize,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery
    ]
  );

  const deleteResource = async id => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e.response) message.error(e.response.data.message);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach(el => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    history.push({
      pathname,
      search: `?p=${page.current}&pS=${page.pageSize}${sortingParameter || ''}${
        filterParameter ? `&f=${filterParameter}` : ''
      }${searchValue ? `&k=${searchValue}` : ''}`
    });
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      fixed: 'right',
      width: 120,
      // eslint-disable-next-line react/prop-types
      render: record => (
        <div style={{ float: 'right' }}>
          {showAction && (
            <Tooltip placement="top" title="Ouvrir">
              <Link
                to={{
                  pathname: showAction.pathname
                    ? showAction.pathname(record)
                    : `${path || pathname}/show/${record.key}`
                }}
                style={{
                  color: 'var(--textColor)'
                }}
              >
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
            </Tooltip>
          )}
          {editAction && (
            <>
              <Divider type="vertical" />
              <Tooltip placement="top" title="Editer">
                <Link
                  to={{
                    pathname: editAction.pathname
                      ? editAction.pathname(record)
                      : `${path || pathname}/edit/${record.key}`
                  }}
                  style={{
                    color: 'var(--textColor)'
                  }}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
              </Tooltip>
            </>
          )}
          {deleteAction && (
            <>
              <Divider type="vertical" />
              <Tooltip placement="top" title="Supprimer">
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteResource(record.key)}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined
                    style={{ color: '#b51010', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </Tooltip>
            </>
          )}
        </div>
      )
    }
  ];

  const adjustedColumns = columns.map(col => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList.forEach(f => {
        if (f.split('=').length) {
          const [key, values] = f.split('=');
          filters[key] = values;
        }
      });
    }

    return {
      ...col,
      ...(col.dataIndex === orderKey || col.key === orderKey
        ? {
            sortOrder: order
          }
        : { sortOrder: false }),
      ...(filters[col.dataIndex] || filters[col.key]
        ? { filteredValue: filters[col.dataIndex || col.key].split(',') }
        : { filteredValue: [] })
    };
  });

  return (
    <>
      <Table
        scroll={scroll}
        style={{
          position: 'relative',
          // eslint-disable-next-line no-nested-ternary
          top: resourceName !== 'tickets' ? 0 : isXSScreen ? 10 : -24
        }}
        rowClassName="rowStyle"
        onRow={record => ({
          ...(onDoubleClickAction
            ? {
                onDoubleClick: () =>
                  onDoubleClickAction.action
                    ? onDoubleClickAction.action(record)
                    : history.push({
                        pathname: `${path || pathname}/show/${record.key}`
                      })
              }
            : {})
        })}
        dataSource={resources}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={{ ...pagination, current: currentPage, pageSize }}
        columns={
          customActionColumn
            ? adjustedColumns
            : [...adjustedColumns, ...actionColumn]
        }
        expandable={
          !isLoading && resources.length && expandable
            ? {
                ...expandable,
                expandedRowKeys:
                  expandable.expandedRowKeys || resources.map(r => r.key)
              }
            : undefined
        }
      />
      <style>
        {`
          .rowStyle {
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      dataIndex: PropTypes.string,
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ),
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string)
  })
};

Datatable.defaultProps = {
  path: null,
  columns: [],
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  editAction: true,
  showAction: true,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined
};

export default Datatable;
