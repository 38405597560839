import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './Fields';

const CreateUpdateExpense = ({ purpose }) => {
  const { id } = useParams();
  const [documents, setDocuments] = useState([]);

  const { fields, areFieldsLoading } = useFields({
    id,
    documents,
    setDocuments
  });

  const config = {
    onCreateResource: {
      setBody: data => {
        return {
          ...data,
          proofs: documents
        };
      }
    },
    onGetResource: {
      setFields: data => {
        setDocuments(data.proofs);
        return {
          ...data,
          date: data.date && moment(data.date)
        };
      }
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          proofs: documents
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      resource="expenses"
      fields={fields}
      loadingFields={areFieldsLoading}
      purpose={purpose}
      withSubRoutes
      baseUrl="expenses"
      config={config}
    />
  );
};

CreateUpdateExpense.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateExpense;
