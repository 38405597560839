import React, { useState, useEffect } from 'react';
import { Select, DatePicker, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import headers from './headers';
import formatter from './formatter';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ListRentals = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [holdings, setHoldings] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [lots, setLots] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [extraQuery, setExtraQuery] = useState(null);
  const [dateRange, setDateRange] = useState();
  const columns = useColumns({ customers, companies, lots, typeFilters });

  const getHoldings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/holdings'
      });
      setHoldings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers'
      });
      const listCustomersName = data.map(({ name, _id }) => {
        return {
          text: name,
          value: _id
        };
      });
      setCustomers(listCustomersName);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getLots = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/lots'
      });
      const listLotsReference = data.map(({ _id, reference }) => {
        return {
          text: reference,
          value: _id
        };
      });
      setLots(listLotsReference);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      const listCompaniesName = data.map(({ name, _id }) => {
        return {
          text: name,
          value: _id
        };
      });
      setCompanies(listCompaniesName);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/rentals/enums'
      });
      const types = data.type.map(type => {
        return {
          text: t(`rentals.type.${type}`),
          value: type
        };
      });
      setTypeFilters(types);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getHoldings();
      await getCompanies();
      await getCustomers();
      await getLots();
      await getEnums();
    })();
  }, []);

  const onChangeHolding = value => {
    setExtraQuery(value);
    setRefresh(!refresh);
  };

  const onChangeRange = (_, dateString) => {
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      setDateRange();
    } else {
      setDateRange(dateString);
    }
    setRefresh(!refresh);
  };

  const extraButtons = (
    <Row>
      <Col style={{ marginRight: '16px' }}>
        <Select onChange={onChangeHolding} placeholder="Holding" allowClear>
          {holdings.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col style={{ marginRight: '16px' }}>
        <RangePicker onChange={onChangeRange} />
      </Col>
    </Row>
  );

  const extraQueryToSend = () => {
    let finalQuery;
    if (dateRange && extraQuery) {
      finalQuery = `holding=${extraQuery}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
    } else if (dateRange) {
      finalQuery = `startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
    } else if (extraQuery) {
      finalQuery = `holding=${extraQuery}`;
    }
    return finalQuery;
  };

  return (
    <SimpleResourceLandingLayout
      headers={headers}
      formatter={formatter}
      columns={columns}
      extraQuery={extraQueryToSend()}
      resourceName="rentals"
      populate="building lot tenant building.company"
      withSubRoutes
      withUploadButton
      resourceModelName="Rental"
      extraButtons={extraButtons}
      scroll={{ x: 1000 }}
    />
  );
};

export default ListRentals;
