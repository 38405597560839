import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import listContent from './listContent';
import ContentInformation from '../../components/ContentInformation/ContentInformation';

const ShowRentReview = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [rentReview, setRentReview] = useState({});

  const getRentRevision = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rent-reviews/${id}?populate=lot,customer`
      });
      setRentReview(data);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getRentRevision();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('rentReviews.show.title')} />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(rentReview)} translate />
        </Skeleton>
      </ContentInformation>
    </>
  );
};

export default ShowRentReview;
