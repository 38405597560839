import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, InputNumber, Upload, Button, Form, Row } from 'antd';
import { CameraOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [address, setAddress] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [holdings, setHoldings] = useState([]);
  const [managers, setManagers] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState();

  const fileToBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setBase64();
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const formFields = [
    {
      name: ['avatar'],
      input: (
        <Dragger {...draggerProps} style={{ borderRadius: '10px' }}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['holding'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          options={(holdings || []).map(({ _id, name }) => ({
            label: name,
            value: _id
          }))}
        />
      )
    },
    {
      name: ['legal_form'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.legalForms || []).map(form => (
            <Option key={form} value={form}>
              {t(`companies.legalForms.${form}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['portion'],
      input: (
        <InputNumber
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      name: ['address'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Rechercher ...',
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      name: ['phone'],
      rules: [{ required: true }]
    },
    {
      name: ['siret_number'],
      rules: [{ required: true }]
    },
    {
      name: ['siren_number'],
      rules: [{ required: true }]
    },
    {
      name: ['no_tva'],
      rules: [{ required: true }]
    },
    {
      name: ['share_capital'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      name: ['manager'],
      rules: [{ required: true }],
      input: (
        <Select
          options={managers.map(({ _id, name }) => ({
            label: name,
            value: _id
          }))}
        />
      )
    },
    {
      name: ['accounts'],
      label: t('bank_accounts'),
      rules: [{ required: true }],
      input: (
        <Form.List name="accounts" label="bank_accounts">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} className="company-account-form">
                  <>
                    <Form.Item
                      label={t('companies.form.account_name')}
                      {...restField}
                      name={[name, 'account_name']}
                      rules={[{ required: true }]}
                      width="100%"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t('companies.form.bank_name')}
                      {...restField}
                      name={[name, 'bank_name']}
                      rules={[{ required: true }]}
                      width="100%"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t('companies.form.bank_address')}
                      {...restField}
                      name={[name, 'bank_address']}
                      rules={[{ required: true }]}
                      width="100%"
                    >
                      <PlacesAutocomplete
                        value={bankAddress}
                        onChange={value => setBankAddress(value)}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading
                        }) => (
                          <div>
                            <Input
                              {...getInputProps({
                                placeholder: 'Rechercher ...',
                                className: 'location-search-input'
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer'
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer'
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Item>
                    <Form.Item
                      label={t('companies.form.iban')}
                      {...restField}
                      name={[name, 'iban']}
                      rules={[{ required: true }]}
                      width="100%"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t('companies.form.bic')}
                      {...restField}
                      name={[name, 'bic']}
                      rules={[{ required: true }]}
                      width="100%"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t('companies.form.account_number')}
                      {...restField}
                      name={[name, 'account_number']}
                      rules={[{ required: true }]}
                      width="100%"
                    >
                      <Input />
                    </Form.Item>
                  </>
                  <CloseOutlined onClick={() => remove(name)} />
                </Row>
              ))}
              <Form.Item>
                <Button onClick={() => add()} block icon={<PlusOutlined />}>
                  Ajouter une banque
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getHoldings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/holdings'
      });
      setHoldings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getManagers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/configurations?type=MANAGER'
      });
      setManagers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getManagers();
    await getHoldings();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    formFields,
    isFieldsLoading,
    base64
  };
};

export default useFields;
