import React from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import headers from './headers';

const ListCustomers = () => {
  const columns = useColumns();

  return (
    <SimpleResourceLandingLayout
      resourceName="customers"
      columns={columns}
      headers={headers}
      withUploadButton
      withSubRoutes
      resourceModelName="Customer"
      sortDefault="name"
    />
  );
};

export default ListCustomers;
