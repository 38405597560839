import React, { useState } from 'react';
import { Button } from 'antd';
import useContext from './context';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import ReceiptsModal from './components/modalReceipt';

const List = () => {
  const { t, collection, deleteResource } = useContext();
  const columns = useColumns(t, collection, deleteResource);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const extraHeader = (
    <Button type="primary" onClick={() => setIsModalVisible(true)}>
      {t(`${collection}.new_receipts`)}
    </Button>
  );

  return (
    <>
      <SimpleResourceLandingLayout
        columns={columns}
        resourceName={collection}
        withCreateButton={false}
        populate="rental rental.building rental.building.company rental.tenant rental.lot"
        extraHeader={extraHeader}
        forceRefresh={refresh}
        scroll={{ x: 1000 }}
      />
      <ReceiptsModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default List;
