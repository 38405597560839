import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { default as invoicesColumns } from '../../Invoices/columns';
import { default as expensesColumns } from '../../Expenses/ConstantColumns';

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;
const { Title } = Typography;

const MultipleReconciliation = ({ type, elements, transaction, onSelectionChange, selectedRowKeys, setSelectedRowKeys }) => {
  const { t } = useTranslation();
  const [parsedElements, setParsedElements] = useState([]);
  const [totalSelected, setTotalSelected] = useState(0);
  const correspondingColumns = type === 'invoices' ? [ ...invoicesColumns(t) ] : [ ...expensesColumns() ];

  const getTotalSelected = () => {
    let color = totalSelected > Math.abs(transaction.value) ? 'red' : 'inherit';
    color = totalSelected === Math.abs(transaction.value) ? 'green' : color;

    return (
      <span style={{ color }}>{`${totalSelected.toFixed(2)}€`}</span>
    )
  };

  useEffect(() => {
    if (elements) {
      const tmpElements = [...elements];
      tmpElements.forEach((element) => {
        element.key = element._id;
      });
      setParsedElements(tmpElements);
    }
  }, [elements]);

  useEffect(() => {
    if (selectedRowKeys) {
      let tmpTotal = 0;
      selectedRowKeys.forEach((key) => {
        const element = elements.find(element => element._id === key);
        if (element) {
          tmpTotal += Math.abs(type === 'invoices' ? element.total : element.amount_ht);
        }
      });
      onSelectionChange(tmpTotal !== Math.abs(transaction.value));
      setTotalSelected(tmpTotal);
    }
  }, [selectedRowKeys]);

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Title level={2}>{getTotalSelected()}&nbsp;/&nbsp;{`${Math.abs(transaction.value).toFixed(2)} €`}</Title>
      </div>
      <StyledTable
        as={Table}
        rowClassName="rowStyle"
        dataSource={parsedElements}
        rowSelection={{
          onChange: setSelectedRowKeys
        }}
        columns={correspondingColumns}
        pagination={false}
      />
    </>
  )
};

MultipleReconciliation.defaultProps = {
  type: 'invoices'
};

MultipleReconciliation.propTypes = {
  type: PropTypes.string,
  elements: PropTypes.array.isRequired,
  transaction: PropTypes.shape({
    formatted_value: PropTypes.number
  }).isRequired,
  onSelectionChange: PropTypes.func,
  selectedRowKeys: PropTypes.array.isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired
};

export default MultipleReconciliation;
