import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const CreateUpdateIntervention = ({ purpose }) => {
  const { resourceId, id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [lot, setLot] = useState();
  const [building, setBuilding] = useState();
  const [buildings, setBuildings] = useState();
  const [filteredLots, setFilteredLots] = useState();
  const { message } = useErrorMessage();
  const [choice, setChoice] = useState(
    resourceId
      ? resourceId.split('_')[1] === 'buildings'
        ? 'building'
        : 'lot'
      : 'building'
  );
  const [documents, setDocuments] = useState([]);

  const handleChoiceChange = e => {
    setChoice(e.target.value);
  };

  const { fields, isFieldsLoading } = useFields({
    building,
    filteredLots,
    setFilteredLots,
    lot,
    setLot,
    choice,
    handleChoiceChange,
    purpose,
    buildings,
    documents,
    setDocuments,
    id
  });
  const form = useRef(null);
  const getResourcesWithResourceID = async () => {
    try {
      let responseBuilding;
      let responseLots;
      if (resourceId.split('_')[1] === 'buildings') {
        responseBuilding = await dispatchAPI('GET', {
          url: `/buildings/${resourceId.split('_')[0]}?populate=lots`
        });
        setBuilding(responseBuilding.data);
        form.current.setFieldsValue({
          building: responseBuilding.data.name
        });
        setFilteredLots(responseBuilding.data.lots);
      } else if (resourceId.split('_')[1] === 'lots') {
        responseLots = await dispatchAPI('GET', {
          url: `/lots/${resourceId.split('_')[0]}?populate=building`
        });
        setLot(responseLots.data);
        setBuilding(responseLots.data.building);
        form.current.setFieldsValue({
          building: responseLots.data.building.name,
          lot: responseLots.data.reference
        });
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getResources = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/buildings?populate=lots'
      });
      setBuildings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBuilding = async ID => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/buildings/${ID}?populate=lots`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (resourceId) {
      (async () => {
        await getResourcesWithResourceID();
      })();
    } else {
      (async () => {
        await getResources();
      })();
    }
  }, []);

  const config = {
    onCreateResource: {
      setBody: data => {
        if (resourceId) {
          if (resourceId.split('_')[1] === 'buildings') {
            return {
              ...data,
              building: building._id,
              interventionChoice: choice,
              files: documents
            };
          }
          return {
            ...data,
            building: building._id,
            lot: lot._id,
            interventionChoice: choice,
            files: documents
          };
        }
        return {
          ...data,
          interventionChoice: choice,
          files: documents
        };
      }
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          files: documents
        };
      }
    },
    onGetResource: {
      setFields: async data => {
        const builds = await getBuilding(data.building);
        const lt = builds.lots.find(({ _id }) => _id === data.lot);
        setFilteredLots(builds.lots);
        setDocuments(data.files);
        if (data.interventionChoice === 'building') {
          return {
            ...data,
            interventionChoice: data.interventionChoice,
            demandDate: moment(data.demandDate),
            interventionDate: moment(data.interventionDate)
          };
        }
        if (data.interventionChoice === 'lot') {
          return {
            ...data,
            interventionChoice: data.interventionChoice,
            demandDate: moment(data.demandDate),
            interventionDate: moment(data.interventionDate),
            lot: lt._id
          };
        }
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      ref={form}
      purpose={purpose}
      config={config}
      baseUrl="interventions"
      resource="interventions"
      tradKey="interventions"
    />
  );
};

CreateUpdateIntervention.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateIntervention;

