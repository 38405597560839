import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/expenses-categories'
      });
      setCategories(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return [
    {
      title: t('expenses.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      render: date => (date && moment(date).format('L')) || '-'
    },
    {
      title: t('expenses.form.company'),
      key: 'company',
      dataIndex: 'company',
      sorter: true,
      render: company => company && company.name
    },
    {
      title: t('expenses.form.category'),
      key: 'category',
      dataIndex: 'category',
      sorter: true,
      render: ({ label }) => label,
      filters:
        categories &&
        categories.map(({ _id, label }) => ({ text: label, value: _id }))
    },
    {
      title: t('expenses.form.supplier'),
      key: 'supplier',
      dataIndex: 'supplier',
      sorter: true
    },
    {
      title: t('expenses.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('expenses.form.payment_type'),
      key: 'payment_type',
      dataIndex: 'payment_type',
      sorter: true,
      render: paymentType => t(`expenses.paymentTypeEnums.${paymentType}`)
    },
    {
      title: t('expenses.form.amount_ttc'),
      key: 'amount_ttc',
      sorter: true,
      render: (_, record) =>
        `${parseFloat(record?.amount_ht + record?.amount_tva)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
    }
  ];
};

export default useColumns;
