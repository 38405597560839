import moment from 'moment';

const useListContent = ({
  category,
  building,
  charge_tenants,
  payment_type,
  date,
  wording,
  company,
  lot,
  supplier,
  amount_ht,
  amount_tva
}) => {
  return [
    {
      label: 'expenses.form.category',
      content: category && category.label
    },
    {
      label: 'expenses.form.building',
      content: building && building.name
    },
    {
      label: 'expenses.form.charge_tenants',
      content: charge_tenants ? 'OUI' : 'NON'
    },
    {
      label: 'expenses.form.payment_type',
      content: payment_type
    },
    {
      label: 'expenses.form.date',
      content: moment(date).format('L')
    },
    {
      label: 'expenses.form.wording',
      content: wording
    },
    {
      label: 'expenses.form.company',
      content: (company && company.name) || '-'
    },
    {
      label: 'expenses.form.lot',
      content: (lot && lot.reference) || '-'
    },
    {
      label: 'expenses.form.supplier',
      content: supplier
    },
    {
      label: 'expenses.form.amount_ht',
      content: `${parseFloat(amount_ht).toFixed(2)} €`
    },
    {
      label: 'expenses.form.amount_tva',
      content: `${parseFloat(amount_tva).toFixed(2)} €`
    },
    {
      label: 'expenses.form.amount_ttc',
      content: `${parseFloat(amount_tva + amount_ht).toFixed(2)} €`
    }
  ];
};

export default useListContent;
