import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, Space } from 'antd';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import InvoiceForm from './InvoiceForm';
import useFields from './fields';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useOtherCustomerFormFields from './Components/useOtherCustomerFormFields';

const CreateUpdateInvoice = ({ purpose }) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState({});
  const { fields1, fields2 } = useFields(purpose);
  const { personalFields, companyFields } = useOtherCustomerFormFields();
  const [customers, setCustomers] = useState([]);
  const [companies, setCompanies] = useState([]);

  const updateInvoice = async body => {
    try {
      await dispatchAPI('PATCH', { url: `/invoices/${id}`, body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createInvoice = async body => {
    try {
      await dispatchAPI('POST', {
        url: '/invoices',
        body: {
          ...body,
          ...(body.other_customer
            ? {
                other_customer: {
                  ...body?.other_customer,
                  customer_type: body?.other_customer.customer_type
                    ? 'COMPANY'
                    : 'INDIVIDUAL'
                }
              }
            : {})
        }
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers'
      });
      setCustomers(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getInvoice = useCallback(async () => {
    setIsLoading(true);
    await getCustomers();
    if (purpose === 'edit' && id) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/invoices/${id}?populate=customer`
        });
        data.articles.forEach(article => {
          article.subtotal = article.quantity * article.unit_price;
        });
        setInvoice({
          ...data,
          total: data.total,
          articles: data.articles,
          date: data.date && moment(data.date),
          global_discount_type: data.global_discount
            ? data.global_discount.type
            : 'CURRENCY',
          global_discount_value: data.global_discount
            ? data.global_discount.value
            : 0,
          global_discount_comment: data.global_discount
            ? data.global_discount.comment
            : '',
          customer: data.customer ? data.customer._id : null,
          customer_address: data?.customer?.bill_address
        });
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    getInvoice();
    getCompanies();
  }, [getInvoice, purpose, id]);

  const handleSubmit = async values => {
    const formattedArticles = [];
    let global_discount = null;
    let total_paid = null;

    Object.values(values.articles).map(element => {
      formattedArticles.push({
        ...element,
        discount: element.discount
          ? {
              type: element.discount_type,
              value: element.discount
            }
          : null
      });
      return null;
    });

    if (values.global_discount_value) {
      global_discount = {
        type: values.global_discount_type,
        value: values.global_discount_value,
        comment: values.global_discount_comment
      };
    }

    if (values.payment_date) {
      total_paid = values.total;
    }

    const formattedValues = {
      ...values,
      global_discount,
      total_paid,
      articles: formattedArticles
    };

    if (purpose === 'edit') await updateInvoice(formattedValues);
    if (purpose === 'create') await createInvoice(formattedValues);
  };

  return (
    <>
      <PageHeaderCustom title={t(`invoices.form.title.${purpose}`)} />
      <ContentCustom>
        {isLoading ? (
          <Space direction="vertical" style={{ width: '100%' }} align="center">
            {[...fields1, ...fields2].map(f => (
              <Skeleton.Input key={`${f.name}`} style={{ width: 300 }} active />
            ))}
          </Space>
        ) : (
          <>
            <InvoiceForm
              resource="invoices"
              isLoading={isLoading}
              initialValues={invoice}
              handleSubmit={handleSubmit}
              fields1={fields1}
              fields2={fields2}
              personalFields={personalFields}
              companyFields={companyFields}
              customers={customers}
              companies={companies}
              purpose={purpose}
            />
          </>
        )}
      </ContentCustom>
    </>
  );
};

CreateUpdateInvoice.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateInvoice;
