import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker, Select, InputNumber } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;

const useFields = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const [companies, setCompanies] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [lots, setLots] = useState([]);
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const [filteredLots, setFilteredLots] = useState([]);
  const [categories, setCategories] = useState([]);

  const onSelectCompany = value => {
    const company = companies.find(({ _id }) => _id === value);
    const companyBuildings = company.buildings;
    const newBuildings = buildings.filter(({ _id }) =>
      companyBuildings.includes(_id)
    );
    setFilteredBuildings(newBuildings);
  };

  const onSelectBuilding = value => {
    const building = buildings.find(({ _id }) => _id === value);
    const buildingLots = building.lots;
    const newLots = lots.filter(({ _id }) => buildingLots.includes(_id));
    setFilteredLots(newLots);
  };

  const fields = [
    {
      label: 'no_invoice',
      name: ['no_invoice'],
      rules: [{ required: true }]
    },
    {
      label: 'company',
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} onSelect={onSelectCompany} allowClear>
          {(companies || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'building',
      name: ['building'],
      input: (
        <Select
          loading={isFieldsLoading}
          onSelect={onSelectBuilding}
          allowClear
        >
          {(filteredBuildings || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot',
      name: ['lot'],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(filteredLots || []).map(({ _id, reference }) => (
            <Option key={_id} value={_id}>
              {reference}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'wording',
      name: ['wording'],
      rules: [{ required: true }]
    },
    {
      title: 'supplier',
      name: ['supplier'],
      rules: [{ required: true }]
    },
    {
      title: 'category',
      name: ['category'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {categories &&
            categories.map(cat => <Option value={cat._id}>{cat.label}</Option>)}
        </Select>
      )
    },
    {
      title: 'amount_ht',
      name: ['amount_ht'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: 'amount_tva',
      name: ['amount_tva'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: 'emission_date',
      name: ['emission_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      title: 'deadline_date',
      name: ['deadline_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    }
  ];

  const getLots = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/lots'
      });
      setLots(data);
      setFilteredLots(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const fetchDataCategories = useCallback(async () => {
    const { data } = await dispatchAPI('GET', { url: '/incomes-categories' });
    setCategories(data);
  }, []);

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getBuildings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/buildings'
      });
      setBuildings(data);
      setFilteredBuildings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getEnums = async () => {
    const { data } = await dispatchAPI('GET', { url: '/expenses/enums' });
    setEnums(data);
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getLots();
    await getCompanies();
    await getBuildings();
    await getEnums();
    await fetchDataCategories();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
