import numeral from 'numeral';

const useListContent = (
  {
    name,
    siret_number,
    siren_number,
    legal_form,
    address,
    manager,
    no_tva,
    share_capital,
    holding,
    portion
  },
  t
) => {
  return [
    {
      label: 'companies.form.name',
      content: name
    },
    {
      label: 'companies.form.manager',
      content: `${manager?.name}`
    },
    {
      label: 'companies.form.legal_form',
      content: t(`companies.legalForms.${legal_form}`)
    },
    {
      label: 'companies.form.siren',
      content: siren_number
    },
    {
      label: 'companies.form.siret',
      content: siret_number
    },
    {
      label: 'companies.form.share_capital',
      content: numeral(share_capital).format('0,0[.]00 $')
    },
    {
      label: 'companies.form.portion',
      content: `${portion} %`
    },
    {
      label: 'companies.form.holding',
      content: holding && holding.name
    },
    {
      label: 'companies.form.address',
      content: address
    },
    {
      label: 'companies.form.no_tva',
      content: no_tva
    }
  ];
};

export default useListContent;
