import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useColumns = ({ year }) => {
  const { t } = useTranslation();

  const restYearColumns = [];

  for (let i = year - 5; i <= year + 6; i += 1) {
    restYearColumns.push({
      title: `31/12/${i}`,
      dataIndex: ['managing', 'balances', i, 'result_exercice'],
      year: i,
      picker: 'year',
      editable: true,
      render: data => {
        if (data) return data;
        return '-';
      }
    });
  }

  return [
    {
      title: t('managings.columns.managing_taxes_cfe.name'),
      fixed: 'left',
      editable: false,
      dataIndex: ['company', 'name']
    },
    ...restYearColumns
  ];
};

export default useColumns;
