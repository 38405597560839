import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import {
  Table,
  Button,
  Row,
  Col,
  Image,
  Typography,
  Input,
  Select,
  DatePicker,
  Modal,
  Checkbox,
  Radio
} from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import useColumns from './columns';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const StyledTable = styled.div`
  .rowStyle {
    color: 'black';
    cursor: pointer;
  }
  .reconciled-style td {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
`;

const BankReconciliation = ({ showAccountsSettings, setAllAccounts }) => {
  const [visible, setVisible] = useState(false);
  const [account, setAccount] = useState();
  const [accounts, setAccounts] = useState([]);
  const [activeAccount, setActiveAccount] = useState();
  const [transactions, setTransactions] = useState([]);
  const [datasToReconcile, setDatasToReconcile] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [dataToModal, setDataToModal] = useState();
  const [dataCanBeReconciled, setDataCanBeReconciled] = useState([]);
  const [reconciliationLabel, setReconciliationLabel] = useState();
  const [datasSelected, setDatasSelected] = useState([]);
  const [totalToReconcile, setTotalToReconcile] = useState(0);
  const [period, setPeriod] = useState(moment().format('YYYY-MM'));
  const [loading, setLoading] = useState(false);
  const { location } = useHistory();
  const { message } = useErrorMessage();
  const [hasToken, setHasToken] = useState();
  const [validReconcile, setValidReconcile] = useState(true);
  const [radioValue, setRadioValue] = useState();

  const handleCancel = () => {
    setVisible(false);
    setTotalToReconcile(0);
    setDatasSelected([]);
    setDataToModal();
  };

  const addToDatasSelected = item => {
    const fields = ['_id', 'amount', 'reconciliation'];
    const newItem = Object.fromEntries(
      Object.entries(item).filter(([key]) => fields.includes(key))
    );
    const index = datasSelected.findIndex(el => el._id === item._id);
    let result = 0;
    if (index < 0) {
      [...datasSelected, item].forEach(data => (result += data.amount));
      setDatasSelected([...datasSelected, newItem]);
      setTotalToReconcile(result);
    } else {
      const dataCopy = datasSelected;
      dataCopy.splice(index, 1);
      dataCopy.forEach(data => (result += data.amount));
      setDatasSelected(dataCopy);
      setTotalToReconcile(result);
    }
  };

  useEffect(() => {
    if (reconciliationLabel) {
      switch (reconciliationLabel.code) {
        case 'PP':
          if (radioValue) {
            setValidReconcile(false);
          } else {
            setValidReconcile(true);
          }
          break;
        case 'RP':
          if (
            Number(
              dataToModal &&
                dataToModal.transactionAmount &&
                dataToModal.transactionAmount.amount
            ) === totalToReconcile
          ) {
            setValidReconcile(false);
          } else {
            setValidReconcile(true);
          }
          break;
        default:
          break;
      }
    }
  }, [
    datasSelected,
    totalToReconcile,
    dataToModal,
    reconciliationLabel,
    radioValue
  ]);

  const authenticate = async body => {
    try {
      await dispatchAPI('POST', { url: '/bank/auth', body });
      setHasToken(true);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const onChangeRadio = event => {
    setRadioValue(event.target.value);
  };

  const datasReconciliation = (code, datas) => {
    let options;
    switch (code) {
      case 'PP':
        options = datas.map(data => (
          <Row
            style={{ width: '70%', margin: 'auto', marginTop: '16px' }}
            justify="space-evenly"
          >
            <Col span={20}>
              <Text>
                {`${moment(data.date).format('DD/MM/YYYY')} ${data.wording} : ${
                  data.amount
                }`}
              </Text>
            </Col>
            <Col span={4}>
              <Radio
                value={data._id}
                checked={radioValue === data._id}
                onChange={onChangeRadio}
              />
            </Col>
          </Row>
        ));
        break;
      case 'RP':
        options = datas.map(data => (
          <Row
            style={{ width: '70%', margin: 'auto', marginTop: '16px' }}
            justify="space-evenly"
          >
            <Col span={20}>
              <Text>
                {`${moment(data.date).format('DD/MM/YYYY')} ${data.wording} : ${
                  data.amount
                }`}
              </Text>
            </Col>
            <Col span={4}>
              <Checkbox
                style={{
                  marginLeft: '16px',
                  borderColor: 'var(--primaryColor'
                }}
                value={data._id}
                onChange={() => addToDatasSelected(data)}
              />
            </Col>
          </Row>
        ));
        break;
      case 'RC':
        options = datas.map(data => (
          <Row
            style={{ width: '70%', margin: 'auto', marginTop: '16px' }}
            justify="space-evenly"
          >
            <Col span={20}>
              <Text>
                {`${moment(data.date).format('DD/MM/YYYY')} ${data.wording} : ${
                  data.amount
                }`}
              </Text>
            </Col>
            <Col span={4}>
              <Button
                style={{
                  backgroundColor: '#E14068',
                  // marginRight: '8px',
                  color: 'white'
                }}
                type="link"
                width={8}
                height={8}
              >
                <SwapOutlined />
              </Button>
            </Col>
          </Row>
        ));
        break;
      default:
        break;
    }
    return options;
  };

  const getTransactions = async () => {
    try {
      setLoading(true);
      const startDate = moment(period).format('YYYY-MM-01');
      let endDate;
      if (moment().format('YYYY-MM') === moment(period).format('YYYY-MM')) {
        endDate = moment().format('YYYY-MM-DD');
      } else {
        endDate = moment(period)
          .add(1, 'month')
          .format('YYYY-MM-DD');
      }
      const { data } = await dispatchAPI('GET', {
        url: `/bank/transactions/${id}/${account?.accounts[0]}?date_from=${startDate}&date_to=${endDate}`
      });
      setDatasToReconcile(data.subjectToReconcile);
      setTransactions(data.transactions);
      setLoading(false);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const columns = useColumns({
    datasToReconcile,
    dispatchAPI,
    id,
    setVisible,
    setReconciliationLabel,
    dataToModal,
    setDataToModal,
    setDataCanBeReconciled,
    getTransactions,
    account,
    message
  });

  const getAllAccounts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bank/allAccounts/${id}`
      });
      setAccounts(data);
      setAllAccounts(data);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const handleValidationReconciliation = async (data, code) => {
    try {
      switch (code) {
        case 'PP':
          await dispatchAPI('PATCH', {
            url: '/expenses/partial-reconciliation',
            body: {
              amount_paid: data.transactionAmount.amount,
              transactionId: data.transactionId,
              id: radioValue
            }
          });
          break;
        case 'RP':
          await dispatchAPI('PATCH', {
            url: `/expenses/multiple-reconciliations`,
            body: { datasSelected, transaction_id: data.transactionId }
          });
          break;
        default:
          break;
      }
      getTransactions();
      handleCancel();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await authenticate({ company_id: id });
    })();
  }, [location]);

  useEffect(() => {
    if (hasToken) {
      (async () => {
        await getAllAccounts();
      })();
    }
  }, [hasToken]);

  useEffect(() => {
    if (account !== undefined) {
      (async () => {
        await getTransactions();
      })();
    }
  }, [account, period]);

  const onChangeDatePicker = (date, dateString) => {
    setPeriod(dateString);
  };

  return (
    <>
      <div
        style={{
          overflowX: 'auto'
        }}
      >
        {!showAccountsSettings &&
          accounts.map(acc => (
            <span
              style={{
                display: 'inline-block',
                padding: '16px'
              }}
            >
              <Button
                type="dashed"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '80px',
                  width: '250px',
                  borderRadius: '16px',
                  backgroundColor:
                    Array.isArray(acc.accounts) &&
                    acc.accounts[0] === account?.accounts[0]
                      ? 'orange'
                      : 'white',
                  borderColor: 'orange',
                  transition: 'background-color 0.5s ease-out'
                }}
                onClick={() => {
                  // setActiveAccount(acc.accounts[0]);
                  setAccount(acc);
                }}
              >
                <Image
                  src={`https://cdn.nordigen.com/ais/${acc.institution_id}.png`}
                  className="bank-image"
                  preview={false}
                />
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <h4
                    style={{
                      marginRight: '10px'
                    }}
                  >
                    <div
                      align="left"
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color:
                          Array.isArray(acc.accounts) &&
                          acc.accounts[0] === account?.accounts[0]
                            ? 'white'
                            : 'black'
                      }}
                    >
                      {acc.balances.amount}
                    </div>
                    <div
                      align="left"
                      style={{
                        fontWeight: '400',
                        fontSize: '12px',
                        color:
                          Array.isArray(acc.accounts) &&
                          acc.accounts[0] === account?.accounts[0]
                            ? 'white'
                            : 'black'
                      }}
                    >
                      {acc?.details?.linkedAccounts}
                    </div>
                  </h4>
                </div>
              </Button>
            </span>
          ))}
      </div>
      <div style={{ marginTop: '16px' }}>
        <Search size="large" style={{ width: '30%', marginRight: '16px' }} />
        <DatePicker
          picker="month"
          size="large"
          value={moment(period, 'YYYY-MM')}
          onChange={onChangeDatePicker}
        />
      </div>
      <StyledTable
        as={Table}
        loading={loading}
        style={{ marginTop: 24, overflowX: 'auto' }}
        rowClassName={(record, index) =>
          record?.reconciliation?.total_reconciled ||
          record?.reconciliation?.partially_reconciled
            ? 'rowStyle reconciled-style'
            : 'rowStyle'
        }
        className="table-striped-rows"
        columns={columns}
        dataSource={transactions}
      />
      <Modal
        title={
          <Text
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 700,
              color: 'white'
            }}
          >
            {reconciliationLabel?.name}
          </Text>
        }
        width="50%"
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        footer={null}
        className="modal-background"
        centered
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: '8px auto',
            width: '70%'
          }}
        >
          <Text
            style={{
              display: 'inherit',
              justifyContent: 'inherit'
            }}
          >
            Choisissez la dépense à rapprocher pour ce mouvement bancaire :
          </Text>
          <Text
            style={{
              display: 'inherit',
              justifyContent: 'space-evenly',
              marginBottom: '8px'
            }}
          >
            {dataToModal ? (
              <>
                <Text>{dataToModal.valueDate}</Text>
                <Text>
                  {dataToModal.transactionAmount > 0
                    ? 'Facture'
                    : 'Prélèvement'}
                </Text>
                <Text>{dataToModal.transactionAmount.amount}</Text>
              </>
            ) : null}
          </Text>
          <Search
            size="large"
            style={{
              width: '70%',
              margin: 'auto'
            }}
          />
          {datasReconciliation(reconciliationLabel?.code, dataCanBeReconciled)}
          <Text
            style={{
              display: 'inherit',
              justifyContent: 'inherit',
              margin: '16px'
            }}
          >
            Total des sommes selectionnées :
          </Text>
          <Text
            style={{
              display: 'inherit',
              justifyContent: 'inherit'
            }}
          >
            {totalToReconcile}
          </Text>
          <Button
            style={{
              marginTop: '16px',
              display: 'flex',
              justifyContent: 'center',
              width: '30%',
              backgroundColor: 'var(--primaryColor)',
              margin: 'auto'
            }}
            disabled={validReconcile}
            onClick={() =>
              handleValidationReconciliation(
                dataToModal,
                reconciliationLabel && reconciliationLabel.code
              )
            }
          >
            Valider
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default BankReconciliation;
