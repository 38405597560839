import React from 'react';
import { Table } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

const CustomTable = ({ datas, getIndexes }) => {
  const syncINSEE = (
    <SyncOutlined
      onClick={getIndexes}
      style={{ cursor: 'pointer', fontSize: '24px' }}
    />
  );

  const dataSource = Object.entries(datas[0])
    .filter(el => ['IRL', 'ILC', 'ILAT', 'ICC'].includes(el[0]))
    .map(it => ({
      label: it[0],
      key: it[0],
      period: it[1].values[0].period,
      value: it[1].values[0].value,
      dateJO: it[1].values[0].dateJO,
      values: it[1].values
    }));

  const columns = [
    { title: syncINSEE, dataIndex: 'label', key: 'label' },
    { title: 'Période', dataIndex: 'period', key: 'period' },
    { title: 'Valeur', dataIndex: 'value', key: 'value' },
    { title: 'Date de parution au JO', dataIndex: 'dateJO', key: 'dateJO' }
  ];

  const expandedRowRender = row => {
    const expendedColumns = [
      { title: 'Période', dataIndex: 'period', key: 'period' },
      { title: 'Valeur', dataIndex: 'value', key: 'value' },
      { title: 'Date de parution au JO', dataIndex: 'dateJO', key: 'dateJO' }
    ];

    const data = [];
    dataSource.forEach(({ key, values }) => {
      if (row.key === key) {
        values.forEach(({ period, value, dateJO }) => {
          data.push({ period, key, value, dateJO });
        });
      }
    });

    return (
      <Table columns={expendedColumns} dataSource={data} pagination={false} />
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 400 }}
      pagination={false}
      expandable={{ expandedRowRender }}
    />
  );
};

export default CustomTable;
