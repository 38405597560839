import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Table, InputNumber, Tooltip } from 'antd';
import {
  VerticalAlignBottomOutlined,
  ArrowLeftOutlined,
  StopOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useColumns from './columns';

const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  loan_id,
  interest,
  amortizations,
  refresh,
  setRefresh,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const save = async applyToAll => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (values[dataIndex] >= 0) {
        const body = record;
        body.paid_amount = values[dataIndex];
        body.applyToAll = applyToAll;
        try {
          await dispatchAPI('PATCH', {
            url: `/loans/amortization_table/${loan_id}`,
            body
          });
          setRefresh(!refresh);
        } catch (error) {
          if (error.response) message(error.response.status);
        }
      }
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item noStyle>
        <Form.Item
          noStyle
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}
        >
          <InputNumber
            decimalSeparator=","
            formatter={value =>
              `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            ref={inputRef}
            style={{ width: 120 }}
          />
        </Form.Item>
        <Tooltip placement="top" title={t('loans.form.validate_one')}>
          <ArrowLeftOutlined
            onClick={() => {
              save(false);
            }}
            style={{ marginLeft: 8, fontSize: 18 }}
          />
        </Tooltip>
        <Tooltip placement="top" title={t('loans.form.validate_all')}>
          <VerticalAlignBottomOutlined
            onClick={() => {
              save(true);
            }}
            style={{ marginLeft: 8, fontSize: 18 }}
          />
        </Tooltip>
        <Tooltip placement="top" title={t('loans.form.cancel')}>
          <StopOutlined
            onClick={() => {
              toggleEdit();
            }}
            style={{ marginLeft: 8, fontSize: 18 }}
          />
        </Tooltip>
      </Form.Item>
    ) : (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AmortizationTable = ({
  refresh,
  setRefresh,
  loan,
  updateAmortizations
}) => {
  const [dataSource, setDataSource] = useState([]);
  const defaultColumns = useColumns({ updateAmortizations });

  useEffect(() => {
    if (loan && loan.amortizations && loan.amortizations.length > 0) {
      setDataSource(loan.amortizations);
    }
  }, [loan?.amortizations]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };
  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        loan_id: loan?._id,
        interest: loan && loan.interest_rate,
        amortizations: loan?.amortizations,
        refresh,
        setRefresh
      })
    };
  });

  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row'}
      dataSource={dataSource}
      columns={columns}
    />
  );
};

EditableCell.propTypes = {
  title: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.element.isRequired,
  loan_id: PropTypes.string.isRequired,
  interest: PropTypes.number.isRequired,
  amortizations: PropTypes.arrayOf(
    PropTypes.shape({
      no_terms: PropTypes.number,
      month: PropTypes.shape({}),
      due_amount: PropTypes.number,
      repaid_capital: PropTypes.number,
      repaid_interest: PropTypes.number,
      due_capital: PropTypes.number
    })
  ).isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};

EditableCell.defaultProps = {
  editable: false
};

AmortizationTable.propTypes = {
  loan: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    interest_rate: PropTypes.number.isRequired,
    amortizations: PropTypes.arrayOf(
      PropTypes.shape({
        no_terms: PropTypes.number,
        month: PropTypes.shape({}),
        due_amount: PropTypes.number,
        repaid_capital: PropTypes.number,
        repaid_interest: PropTypes.number,
        due_capital: PropTypes.number
      })
    )
  }).isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  updateAmortizations: PropTypes.func.isRequired
};

export default AmortizationTable;
