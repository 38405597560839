import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('lot.columns.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('lot.columns.building'),
      key: 'building',
      dataIndex: 'building',
      render: building => building && building.name,
      sorter: true
    },
    {
      title: t('lot.columns.type'),
      key: 'type',
      dataIndex: 'type',
      sorter: true,
      filters: [
        {
          text: 'Habitation',
          value: 'Habitation'
        },
        {
          text: 'Local commercial',
          value: 'Local commercial'
        }
      ],
      filterSearch: true,
      onFilter: (value, record) => record && record.type && record.type.startsWith(value)
    },
    {
      title: t('lot.columns.surface'),
      key: 'surface',
      dataIndex: 'surface',
      render: item => `${item} m²`,
      sorter: true
    },
    {
      title: t('lot.columns.nb_parking_slot'),
      key: 'nb_parking_slot',
      dataIndex: 'nb_parking_slot',
      sorter: true
    },
    {
      title: t('lot.columns.state'),
      dataInkeydex: 'hire_state',
      dataIndex: 'hire_state',
      sorter: true,
      filters: [
        {
          text: 'Libre',
          value: 'Libre'
        },
        {
          text: 'Loué',
          value: 'Loué'
        }
      ],
      filterSearch: true,
      onFilter: (value, record) => record.hire_state.indexOf(value) === 0
    }
  ];
};

export default useColumns;
