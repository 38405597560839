import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const { Dragger } = Upload;

const CustomDragger = ({
  category,
  resource,
  id,
  forceRefresh,
  setForceRefresh
}) => {
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const [files, setFiles] = useState([]);

  const props = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/${resource}/files/${id}`,
    data: {
      category
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      setFiles(info.fileList);
      const { status } = info.file;
      if (status === 'done') {
        setForceRefresh(!forceRefresh);
      }
      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: false,
    style: { color: 'red' }
  };

  useEffect(() => {
    if (files.length) {
      const index = files.length - 1;
      if (files[index].status === 'done') {
        setForceRefresh(!forceRefresh);
      }
    }
  }, [files]);

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p>Cliquer ou déposer un fichier</p>
    </Dragger>
  );
};

CustomDragger.propTypes = {
  category: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  forceRefresh: PropTypes.string,
  setForceRefresh: PropTypes.func
};

CustomDragger.defaultProps = {
  forceRefresh: null,
  setForceRefresh: null
};

export default CustomDragger;
