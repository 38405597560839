import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListHoldings from './ListHoldings';
import CreateUpdateHolding from './CreateUpdateHolding';
import ShowHolding from './ShowHolding';

const HoldingRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/create`}
        render={() => <CreateUpdateHolding purpose="create" />}
      />
      <Route
        path={`${match.path}/edit/:id`}
        render={() => <CreateUpdateHolding purpose="edit" />}
      />
      <Route path={`${match.path}/show/:id`} render={() => <ShowHolding />} />
      <Route path={match.path} component={ListHoldings} />
    </Switch>
  );
};

export default HoldingRouter;
