import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components';

const CompanyBuildingTable = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const columns = [
    {
      title: t('buildings.form.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('buildings.form.type_company'),
      dataIndex: 'type_company',
      render: type => t(`buildings.form.${type}`),
      sorter: true
    },
    {
      title: t('buildings.form.address'),
      dataIndex: 'address',
      sorter: true
    },
    {
      title: t('buildings.form.lots'),
      dataIndex: 'lots',
      render: lots => lots.length,
      sorter: true
    }
  ];

  return (
    <Datatable
      resourceName="buildings"
      columns={columns}
      extraQuery={`company=${id}`}
      path="/buildings"
    />
  );
};

export default CompanyBuildingTable;
