import React from 'react';
import {
  PhoneOutlined,
  EditOutlined,
  CommentOutlined,
  DiffOutlined
} from '@ant-design/icons';

const RentalsTimelineLogos = memo => {
  let logo;
  switch (memo) {
    case 'UPKEEP':
      logo = <CommentOutlined />;
      break;
    case 'PLAN':
      logo = <EditOutlined />;
      break;
    case 'CALL':
      logo = <PhoneOutlined />;
      break;
    default:
      logo = <DiffOutlined />;
      break;
  }
  return logo;
};

export default RentalsTimelineLogos;
