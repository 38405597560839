import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Skeleton, Row, Col, Button, Popconfirm } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import listContent from './listContent';
import { routes } from '../../utils/constants/routes';
import dataFilter from '../../utils/filtersFunction';
import CustomLineFile from '../../components/Files/CustomLineFile';
import CommentCard from '../../components/CommentCard/CommentCard';
import ContentInformation from '../../components/ContentInformation/ContentInformation';

const ShowIntervention = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [intervention, setIntervention] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);

  const getIntervention = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/${id}?populate=lot,customer,building`
      });
      setIntervention(data);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
    setIsLoading(false);
  }, []);

  const handleOk = () => {
    setVisible(false);
    getIntervention();
  };

  const updateData = async values => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interventions/${id}`,
        body: values
      });
      handleOk();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    getIntervention();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom
        title={t('interventions.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.INTERVENTIONS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <Row gutter={[16, 16]}>
            <Col style={{ display: 'flex' }} span={14}>
              <DescriptionList
                title="Informations"
                style={{ flex: 1 }}
                data={dataFilter(listContent(intervention), [
                  'intervention_choice',
                  'type',
                  'status',
                  'building',
                  'object',
                  'lot',
                  'customer',
                  'phone',
                  'email',
                  'demandDate',
                  'interventionDate'
                ])}
                translate
              />
            </Col>
            <Col
              span={5}
              style={{
                display: 'flex',
                marginTop: 2,
                background: 'var(--componentBackground)'
              }}
            >
              <CommentCard
                visible={visible}
                setVisible={setVisible}
                updateData={updateData}
                data={intervention}
                extraStyle={{
                  fontSize: '1.3rem',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  color: 'black'
                }}
              />
            </Col>
            <Col span={5} style={{ display: 'flex' }}>
              <DescriptionList
                title="Documents"
                style={{ flex: 1, width: '100%' }}
                data={[
                  {
                    label: '',
                    content: (
                      <>
                        {intervention &&
                          (intervention.files || []).map(file => (
                            <CustomLineFile
                              id={id}
                              resource="interventions"
                              file={file}
                              forceRefresh={refresh}
                              setForceRefresh={setRefresh}
                            />
                          ))}
                      </>
                    )
                  }
                ]}
                translate
              />
            </Col>
          </Row>
        </Skeleton>
      </ContentInformation>
    </>
  );
};

export default ShowIntervention;
