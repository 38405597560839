import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import Table from './RentIndexesTable';

const Settings = () => {
  const { dispatchAPI } = useAuthContext();
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const url = '/configurations';

  const fetchDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `${url}?type=INSEE` });
      console.log('🚀 ~ fetchDatas ~ data', data);
      setDatas(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getToken = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/insee' });
      return data.access_token;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const postIndexes = async body => {
    try {
      const response = await dispatchAPI('POST', { url: '/insee', body });
      return response?.status;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getIndexes = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      const { data } = await dispatchAPI('GET', { url: `/insee/${token}` });
      await postIndexes(data);
      if (data.ICC.name !== 'Error') {
        message.success('Les indices INSEE ont bien été mis à jour');
        await fetchDatas();
      } else {
        message.error("Un problème est intervenue avec l'INSEE");
      }
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <>
      {datas.length > 0 && !isLoading ? (
        <Table datas={datas} getIndexes={getIndexes} />
      ) : (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      )}
    </>
  );
};
export default Settings;
