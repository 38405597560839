import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const renderIndexes = (trimester, trimestersValues) => {
  let indexes;
  if (trimestersValues) {
    switch (trimester) {
      case trimestersValues[0]:
        indexes = [0, 1, 2];
        break;
      case trimestersValues[1]:
        indexes = [3, 4, 5];
        break;
      case trimestersValues[2]:
        indexes = [6, 7, 8];
        break;
      case trimestersValues[3]:
        indexes = [9, 10, 11];
        break;
      default:
        break;
    }
  }
  return indexes;
};

const months = [
  'JANVIER',
  'FÉVRIER',
  'MARS',
  'AVRIL',
  'MAI',
  'JUIN',
  'JUILLET',
  'AOÛT',
  'SEPTEMBRE',
  'OCTOBRE',
  'NOVEMBRE',
  'DÉCEMBRE'
];

const useColumns = ({ selectedTrimester, enums }) => {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();

  useEffect(() => {
    if (selectedTrimester && enums && enums.filters) {
      setIndexes(renderIndexes(selectedTrimester, enums.filters));
    }
  }, [selectedTrimester, enums]);

  return [
    {
      title: t('managings.columns.managing.name'),
      fixed: 'left',
      editable: false,
      dataIndex: ['company', 'name']
    },
    {
      title: t('managings.columns.managing.balance_at_middle_of_year'),
      fixed: 'left',
      dataIndex: ['managing', 'balance_at_middle_of_year'],
      editable: true,
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing.levy_date'),
      fixed: 'left',
      dataIndex: ['managing', 'levy_date'],
      isDatePicker: true,
      editable: true,
      render: data => {
        if (data) {
          return moment(data).format('DD/MM/YYYY');
        }
        return '-';
      }
    },
    {
      title: `${t('managings.columns.managing.out')} ${
        months[indexes && indexes[0]]
      }`,
      editable: true,
      picker: 'month',
      dataIndex: ['managing', 'balances', indexes && indexes[0], 'out_amount'],
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: `${t('managings.columns.managing.out')} ${
        months[indexes && indexes[1]]
      }`,
      editable: true,
      picker: 'month',
      dataIndex: ['managing', 'balances', indexes && indexes[1], 'out_amount'],
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: `${t('managings.columns.managing.out')} ${
        months[indexes && indexes[2]]
      }`,
      editable: true,
      picker: 'month',
      dataIndex: ['managing', 'balances', indexes && indexes[2], 'out_amount'],
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: `${t('managings.columns.managing.in')} ${
        months[indexes && indexes[0]]
      }`,
      dataIndex: ['managing', 'balances', indexes && indexes[0], 'in_amount'],
      editable: true,
      picker: 'month',
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: `${t('managings.columns.managing.out')} ${
        months[indexes && indexes[1]]
      }`,
      dataIndex: ['managing', 'balances', indexes && indexes[1], 'in_amount'],
      editable: true,
      picker: 'month',
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: `${t('managings.columns.managing.out')} ${
        months[indexes && indexes[2]]
      }`,
      dataIndex: ['managing', 'balances', indexes && indexes[2], 'in_amount'],
      editable: true,
      picker: 'month',
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing.needs'),
      fixed: 'right',
      dataIndex: ['managing', 'needs'],
      editable: true,
      render: needs => {
        if (needs) {
          return needs;
        }
        return '-';
      }
    },
    {
      title: t('managings.columns.managing.available'),
      fixed: 'right',
      dataIndex: ['managing', 'available'],
      editable: true,
      render: available => {
        if (available) {
          return available;
        }
        return '-';
      }
    },
    {
      title: t('managings.columns.managing.duty'),
      fixed: 'right',
      dataIndex: ['managing', 'duty'],
      isText: true,
      editable: true,
      render: available => {
        if (available) {
          return available;
        }
        return '-';
      }
    }
  ];
};

export default useColumns;
