import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spin, Button } from 'antd';
import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import CashFlowTable from './CashFlowTable';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CashFlowDashboard = ({ datas, year }) => {
  const [isLoading, setIsLoading] = useState(true);
  const wrapperRef = useRef();

  useEffect(() => {
    if (datas) {
      setIsLoading(false);
    }
  }, [datas]);

  const onPrint = e => {
    const opt = {};
    const elem = wrapperRef.current;
    elem.style.width = 'max-content';

    html2canvas(elem, opt).then(canvas => {
      const iframe = document.createElement('iframe');
      iframe.name = 'printf';
      iframe.id = 'printf';
      iframe.height = 0;
      iframe.width = 0;
      document.body.appendChild(iframe);

      const imgUrl = canvas.toDataURL({
        format: 'jpeg',
        quality: '1.0'
      });

      const url = `<img src="${imgUrl}"/>`;
      const newWin = window.frames.printf;
      newWin.document.write(
        `<body onload="window.print()"><h1 style="font-family: sans-serif; text-align: center">CASHFLOW ${datas.company?.name}</h1>${url}</body>`
      );
      elem.style.width = '';
      newWin.document.close();
    });
  };

  return (
    <>
      {isLoading ? (
        <Spin indicator={antIcon} />
      ) : (
        <div>
          <CashFlowTable companyId={datas.company._id} year={year} />
        </div>
      )}
      <Button
        onClick={onPrint}
        type="primary"
        icon={<PrinterOutlined />}
        style={{ marginTop: 10, float: 'right' }}
      >
        Imprimer
      </Button>
    </>
  );
};

CashFlowDashboard.propTypes = {
  datas: PropTypes.element.isRequired,
  company: PropTypes.element.isRequired,
  year: PropTypes.number.isRequired
};

export default CashFlowDashboard;
