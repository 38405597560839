import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ShowLot from './ShowLot';
import CreateUpdateLot from './CreateUpdateLot';
import ListLots from './ListLots';
import { routes } from '../../utils/constants/routes';

const LotRouter = () => {
  return (
    <Switch>
      <Route
        path={`${routes.LOT}/create/:id`}
        render={() => <CreateUpdateLot purpose="create" />}
      />
      <Route
        path={`${routes.LOT}/create`}
        render={() => <CreateUpdateLot purpose="create" />}
      />
      <Route
        path={`${routes.LOT}/edit/:id`}
        render={() => <CreateUpdateLot purpose="edit" />}
      />
      <Route exact path={`${routes.LOT}/show/:id`} component={ShowLot} />
      <Route path={routes.LOT} component={ListLots} />
    </Switch>
  );
};

export default LotRouter;

