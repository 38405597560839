import React from 'react';
import { Row, Col } from 'antd';

const PageHeaderHome = ({ month, quote }) => {
  return (
    <>
      <div
        style={{
          margin: '24px 48px 0 48px',
          color: '#26647B'
        }}
      >
        <Row align="middle" justify="space-between">
          <Col
            span={6}
            align="left"
            style={{
              fontSize: '2.5rem',
              fontFamily: 'Pretty Queen',
              textTransform: 'capitalize'
            }}
          >
            {month}
          </Col>
          <Col span={18} align="right">
            <div
              style={{
                fontSize: '1.5rem'
              }}
            >
              {quote ? `"${quote.quote}"` : ''}
            </div>
            <div
              style={{
                fontSize: '0.9rem',
                fontFamily: 'Pretty Queen'
              }}
            >
              {quote ? quote.author : ''}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PageHeaderHome;
