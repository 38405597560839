const useListContent = ({
  building,
  reference,
  surface,
  nb_parking_slot,
  type,
  hire_state
}) => {
  return [
    {
      label: 'lot.display.reference',
      content: reference
    },
    {
      label: 'lot.display.building',
      content: building?.name
    },
    {
      label: 'lot.display.type',
      content: type
    },
    {
      label: 'lot.display.surface',
      content: `${surface} m²`
    },
    {
      label: 'lot.display.nb_parking_slot',
      content: nb_parking_slot
    },
    {
      label: 'lot.display.hire_state',
      content: hire_state
    }
  ];
};

export default useListContent;
