import React from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { Card, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Title } = Typography;

const Account = ({ account, visible, onVisibilityChange }) => {
  const { t } = useTranslation();

  const toggleButton = visible ? (
    <EyeOutlined onClick={() => onVisibilityChange(account.id)} style={{ fontSize: 20, color: '#1890ff', cursor: 'pointer' }} />
  ) : (
    <EyeInvisibleOutlined onClick={() => onVisibilityChange(account.id)} style={{ fontSize: 20, cursor: 'pointer' }} />
  );

  return (
    <Card title={account.original_name} extra={toggleButton}>
      {t('reconciliations.account.bank')}&nbsp;<strong style={{color: `#${account.connector.color}`}}>{account.connector.name}</strong>
      <br />
      {t('reconciliations.account.iban')}&nbsp;{account.iban}
      <div style={{width: '100%', textAlign: 'right', marginTop: 10}}>
        <Title style={{ margin: 0 }} level={2}>{numeral(account.balance).format('0,0[.]00 $')}</Title>
      </div>
    </Card>
  );
};

Account.defaultProps = {
  visible: true
};

Account.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    original_name: PropTypes.string,
    balance: PropTypes.number,
    connector: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired,
  visible: PropTypes.bool,
  onVisibilityChange: PropTypes.func
};

export default Account;
