const headers = [
  {
    label: 'company',
    key: 'company'
  },
  {
    label: 'balance_at_middle_of_year',
    key: 'balance_at_middle_of_year' || ''
  },
  {
    label: 'levy_date',
    key: 'levy_date' || ''
  },
  {
    label: 'out_amount_jan',
    key: ('balance0' && 'balance0.out_amount') || ''
  },
  {
    label: 'out_amount_fev',
    key: ('balance1' && 'balance1.out_amount') || ''
  },
  {
    label: 'out_amount_mar',
    key: ('balance2' && 'balance2.out_amount') || ''
  },
  {
    label: 'out_amount_avr',
    key: ('balance3' && 'balance3.out_amount') || ''
  },
  {
    label: 'out_amount_may',
    key: ('balance4' && 'balance4.out_amount') || ''
  },
  {
    label: 'out_amount_jun',
    key: ('balance5' && 'balance5.out_amount') || ''
  },
  {
    label: 'out_amount_jul',
    key: ('balance6' && 'balance6.out_amount') || ''
  },
  {
    label: 'out_amount_aug',
    key: ('balance7' && 'balance7.out_amount') || ''
  },
  {
    label: 'out_amount_sep',
    key: ('balance8' && 'balance8.out_amount') || ''
  },
  {
    label: 'out_amount_oct',
    key: ('balance9' && 'balance9.out_amount') || ''
  },
  {
    label: 'out_amount_nov',
    key: ('balance10' && 'balance10.out_amount') || ''
  },
  {
    label: 'out_amount_dec',
    key: ('balance11' && 'balance11.out_amount') || ''
  },
  {
    label: 'in_amount_jan',
    key: ('balance0' && 'balance0.in_amount') || ''
  },
  {
    label: 'in_amount_fev',
    key: ('balance1' && 'balance1.in_amount') || ''
  },
  {
    label: 'in_amount_mar',
    key: ('balance2' && 'balance2.in_amount') || ''
  },
  {
    label: 'in_amount_avr',
    key: ('balance3' && 'balance3.in_amount') || ''
  },
  {
    label: 'in_amount_may',
    key: ('balance4' && 'balance4.in_amount') || ''
  },
  {
    label: 'in_amount_jun',
    key: ('balance5' && 'balance5.in_amount') || ''
  },
  {
    label: 'in_amount_jul',
    key: ('balance6' && 'balance6.in_amount') || ''
  },
  {
    label: 'in_amount_aug',
    key: ('balance7' && 'balance7.in_amount') || ''
  },
  {
    label: 'in_amount_sep',
    key: ('balance8' && 'balance8.in_amount') || ''
  },
  {
    label: 'in_amount_oct',
    key: ('balance9' && 'balance9.in_amount') || ''
  },
  {
    label: 'in_amount_nov',
    key: ('balance10' && 'balance10.in_amount') || ''
  },
  {
    label: 'in_amount_dec',
    key: ('balance11' && 'balance11.in_amount') || ''
  },
  {
    label: 'needs',
    key: 'needs'
  },
  {
    label: 'available',
    key: 'available'
  },
  {
    label: 'duty',
    key: 'duty'
  }
];

export default headers;
