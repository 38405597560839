import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateUpdateBuilding from './CreateUpdateBuilding';
import ListBuildings from './listBuildings';
import { routes } from '../../utils/constants/routes';
import ShowBuilding from './ShowBuilding';

const BuildingRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateBuilding purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateBuilding purpose="edit" />}
      />
      <Route exact path={`${path}/show/:id`} component={ShowBuilding} />
      <Route path={`${path}`} render={() => <ListBuildings />} />
    </Switch>
  );
};

export default BuildingRouter;
