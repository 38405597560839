import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListSuppliers from './ListSuppliers';
import CreateUpdateSupplier from './CreateUpdateSupplier';
import ShowSuppliers from './showSuppliers';

const SuppliersRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateSupplier purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateSupplier purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowSuppliers />} />
      <Route path={path} component={ListSuppliers} />
    </Switch>
  );
};

export default SuppliersRouter;
