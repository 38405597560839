import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { companiesTypes } from '../../../utils/constants/tagColors';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [companies, setCompanies] = useState();

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanies();
    })();
  }, []);

  return [
    {
      title: t('contacts.form.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('contacts.form.legal_form'),
      dataIndex: 'legal_form',
      sorter: true
    },
    {
      title: t('contacts.form.category'),
      dataIndex: 'category',
      sorter: true
    }
  ];
};

export default useColumns;
