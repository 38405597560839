const headers = [
  {
    label: 'reference',
    key: 'reference'
  },
  {
    label: 'building',
    key: ('building' && 'building.name') || ''
  },
  {
    label: 'type',
    key: 'type' || ''
  },
  {
    label: 'surface',
    key: 'surface' || ''
  },
  {
    label: 'nb_parking_slot',
    key: 'nb_parking_slot' || ''
  },
  {
    label: 'hire_state',
    key: 'hire_state' || ''
  }
];

export default headers;
