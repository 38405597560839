import React, { useReducer } from 'react';
import { Button, Modal, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../contexts/AuthContext';
import useErrorMessage from '../utils/ErrorMessage';

function reducer(state, action) {
  switch (action.type) {
    case 'OVER':
      return { ...state, type: action.type };
    case 'LACK':
      return { ...state, type: action.type };
    case 'OPEN':
      return { ...state, isVisible: true };
    case 'CANCEL':
      return { ...state, isVisible: false };
    case 'VALUE':
      return { ...state, value: action.value };
    default:
      throw new Error();
  }
}

const initialState = { isVisible: false, type: null, value: 0 };

const RegulButton = ({ amount, customerId, reconcilation, datas }) => {
  console.log('🚀 ~ RegulButton ~ customerId', customerId);
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateBalance = async value => {
    try {
      await dispatchAPI('PATCH', {
        url: `/customers/${customerId}`,
        body: { updateBalance: value }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleOk = async () => {
    // check if value is lower amount otherwise send error message
    if (state.value >= amount) message(t('regulbutton.valueError'));

    // define the sign of value depend on type of regul
    const sign = state.type === 'OVER' ? '+' : '-';
    const value = Number.parseFloat(sign + state.value);

    // update the customer balance
    await updateBalance(value);

    // reconcilation of Inflow or outflow
    const { key, collection, refresh, subId } = datas;
    reconcilation(key, collection, true, refresh, subId);
  };

  return (
    <>
      <Button type="primary" onClick={() => dispatch({ type: 'OPEN' })}>
        {t('regulbutton.button')}
      </Button>
      <Modal
        title={t('regulbutton.title')}
        visible={state.isVisible}
        onOk={handleOk}
        onCancel={() => dispatch({ type: 'CANCEL' })}
      >
        <Select
          style={{ width: '100%', marginBottom: 10 }}
          options={['OVER', 'LACK'].map(el => ({
            label: t(`regulbutton.${el}`),
            value: el
          }))}
          onSelect={type => dispatch({ type })}
        />
        <Input
          type="number"
          onChange={e => dispatch({ type: 'VALUE', value: e.target.value })}
        />
      </Modal>
    </>
  );
};

RegulButton.propTypes = {
  amount: PropTypes.number.isRequired,
  customerId: PropTypes.string.isRequired,
  reconcilation: PropTypes.func.isRequired,
  datas: PropTypes.shape({}).isRequired
};

RegulButton.defaultProps = {};

export default RegulButton;
