import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Spin, Upload, Space } from 'antd';
import {
  InboxOutlined,
  FileOutlined,
  LoadingOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useErrorMessage from '../utils/ErrorMessage';
import useAuthContext from '../contexts/AuthContext';
import filesLogos from '../utils/constants/filesLogos';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  color: var(--textColor);
  &:hover {
    color: var(--primaryColor);
  }
`;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Dragger } = Upload;
const ImportFiles = ({
  id,
  files,
  forceRefresh,
  setForceRefresh,
  resource
}) => {
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState({});
  const [isDownloading, setIsDownloading] = useState({});
  const props = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}/${resource}/files/${id}`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        setForceRefresh(!forceRefresh);
      }
      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: false,
    style: { color: 'red' }
  };
  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };
  const deleteFile = async _id => {
    setIsLoading({ ...isDownloading, [_id]: true });
    const body = {
      _id
    };
    try {
      await dispatchAPI('PATCH', { url: `${resource}/files/${id}`, body });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return (
    <>
      <Row>
        <Space style={{ marginTop: 20 }} size={50}>
          {files &&
            files.map(({ _id, filename, type }) => (
              <div>
                <div
                  style={{ float: 'right', color: 'red', cursor: 'pointer' }}
                  onClick={() => deleteFile(_id)}
                >
                  {isLoading[_id] ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <DeleteOutlined />
                  )}
                </div>
                <Content onClick={() => downloadFile(_id, filename)}>
                  {filesLogos[type] || (
                    <FileOutlined style={{ fontSize: 40 }} />
                  )}
                  {filename.slice(0, 15)}
                </Content>
              </div>
            ))}
        </Space>
      </Row>
      <Row style={{ marginTop: 20, height: 150 }}>
        <div style={{ width: '100%' }}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('dragndrop')}</p>
          </Dragger>
        </div>
      </Row>
    </>
  );
};
ImportFiles.propTypes = {
  typeSelector: PropTypes.bool,
  callback: PropTypes.func
};
ImportFiles.defaultProps = {
  typeSelector: false,
  callback: history => history.push('/documents/templates')
};
export default ImportFiles;
