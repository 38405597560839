import React, { useState, useEffect } from 'react';
import { DatePicker, Table, Divider, Popconfirm } from 'antd';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useColumns from './LotsCustomersTable/columns';
import { routes } from '../../utils/constants/routes';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const { RangePicker } = DatePicker;

const LotCustomersTable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const columns = useColumns();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dateRange, setDateRange] = useState();
  const [refresh, setRefresh] = useState(false);
  const [customers, setCustomers] = useState([]);

  const getCustomers = async hasFilter => {
    try {
      let response = [];
      if (hasFilter) {
        response = await dispatchAPI('GET', {
          url: `/lots/${id}/customers?startDate=${dateRange[0]}&endDate=${dateRange[1]}&populate=lot,tenant`
        });
      } else {
        response = await dispatchAPI('GET', {
          url: `/lots/${id}/customers?populate=lot,tenant`
        });
      }
      setCustomers(response.data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const deleteTenant = async customer_id => {
    try {
      await dispatchAPI('DELETE', { url: `/customers/${customer_id}` });
      await getCustomers();
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const onChangeRange = (_, dateString) => {
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      setDateRange();
    } else {
      setDateRange(dateString);
    }
    setRefresh(!refresh);
  };

  const actionColumn = [
    {
      key: 'action',
      render: record => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.CUSTOMERS}/show/${record?.tenant?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `${routes.CUSTOMERS}/edit/${record?.tenant?._id}`
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteTenant(record?.tenant?._id)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'red', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </div>
      )
    }
  ];

  useEffect(() => {
    (async () => {
      if (dateRange) {
        await getCustomers(dateRange);
      } else {
        await getCustomers();
      }
    })();
  }, [refresh]);

  return (
    <>
      <RangePicker onChange={onChangeRange} />
      <Table
        columns={[...columns, ...actionColumn]}
        dataSource={customers}
        onRow={record => ({
          onDoubleClick: () =>
            history.push({
              pathname: `${routes.CUSTOMERS}/show/${record?.tenant?._id}`
            })
        })}
      />
    </>
  );
};

export default LotCustomersTable;
