import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import { userRoles } from '../../utils/constants/tagColors';

const ListUsers = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'Phone number country code',
      key: 'phone_number.country_code'
    },
    {
      label: 'Phone number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: role => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map(r => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('-').length > 1
            ? `${r.split('-')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },
    {
      title: t('users.form.phone_number.number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: true,
      render: data => {
        if (data) {
          return data.number;
        }
        return '-';
      }
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="users"
      columns={columns}
      headers={headers}
      resourceModelName="User"
      scroll={{ x: 1000 }}
    />
  );
};

export default ListUsers;
