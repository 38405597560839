import React from 'react';
import PropTypes from 'prop-types';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateCompany = ({ purpose }) => {
  const { formFields, base64, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: data => {
        return {
          ...data,
          holding: data.holding && data.holding._id
        };
      }
    },
    onCreateResource: {
      setBody: data => {
        return {
          ...data,
          ...(base64 ? { avatar: base64 } : {})
        };
      }
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          ...(base64 ? { avatar: base64 } : {})
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={formFields}
      purpose={purpose}
      loadingFields={isFieldsLoading}
      baseUrl="companies"
      resource="companies"
      config={config}
    />
  );
};

CreateUpdateCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCompany;
