import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateLoan = ({ purpose }) => {
  const { id } = useParams();
  const [documents, setDocuments] = useState([]);
  const [disabledStartDate, setDisabledStartDate] = useState(true);
  const form = useRef(null);

  const { fields, isFieldsLoading } = useFields({
    id,
    documents,
    setDocuments,
    disabledStartDate
  });

  const config = {
    onGetResource: {
      setFields: data => {
        return {
          ...data,
          start_date: data.start_date && moment(data.start_date),
          end_date: data.end_date && moment(data.end_date),
          amortization_table: documents
        };
      }
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          amortization_table: documents
        };
      }
    },
    onCreateResource: {
      setBody: data => {
        return {
          ...data,
          amortization_table: documents
        };
      }
    }
  };

  const onValuesChange = (_, values) => {
    if (values.duration) {
      setDisabledStartDate(false);
    }
    if (values.duration && values.start_date) {
      const end_date = moment(values.start_date).add(values.duration, 'months');
      form.current.setFieldsValue({
        end_date
      });
    }
  };

  return (
    <CreateUpdateContainer
      resource="loans"
      fields={fields}
      onValuesChange={onValuesChange}
      ref={form}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      withSubRoutes
      baseUrl="loans"
      config={config}
    />
  );
};

CreateUpdateLoan.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateLoan;
