import moment from 'moment';

const formatter = d => {
  let finalData = {};
  if (d) {
    finalData = { ...d };
    if (finalData.hasOwnProperty('start_date')) {
      finalData.start_date = moment(finalData.start_date).format('L');
    }
    if (finalData.hasOwnProperty('end_date')) {
      finalData.end_date = moment(finalData.end_date).format('L');
    }
  }
  return finalData;
};

export default formatter;
