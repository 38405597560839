import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// usr u98563939
// pwd ene512

const TinkAuthentication = () => {
  const history = useHistory();
  const { location } = history;

  useEffect(() => {
    if (location.search) {
      const parsed = location.search.split('?')[1];
      const params = parsed.split('&');
      const parsedParams = {};

      params.forEach(param => {
        const parts = param.split('=');
        parsedParams[parts[0]] = parts[1];
      });
      const { state, code, credentialsId } = parsedParams;
      if (code && state && credentialsId) {
        window.location.replace(
          `${window.location.origin}/companies/show/${state}?code=${code}&credentialsId=${credentialsId}`
        );
      }
    }
  }, [location]);

  return <div>Tink authentication</div>;
};

export default TinkAuthentication;
