import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const CreateUpdateRentReview = ({ purpose }) => {
  const { id } = useParams();
  const [customer, setCustomer] = useState();
  const [lot, setLot] = useState();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { fields, isFieldsLoading } = useFields({
    lot,
    customer,
    setLot,
    setCustomer
  });
  const form = useRef(null);
  const config = {
    onCreateResource: {
      setBody: data => ({
        ...data,
        customer,
        lot
      })
    },
    onUpdateResource: {
      setBody: data => ({
        ...data
      })
    }
  };

  const getResources = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}/rental`
      });
      setCustomer(data[0].tenant[0]);
      setLot(data[0].lot[0]);
      form.current.setFieldsValue({
        lot: data[0].lot[0].reference,
        customer: data[0].tenant[0].name,
        new_index: data[0].base_index
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      await getResources();
    })();
  }, [getResources]);

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      config={config}
      ref={form}
      baseUrl="rent-reviews"
      resource="rentReviews"
      tradKey="rentReviews"
    />
  );
};

CreateUpdateRentReview.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateRentReview;
