import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { OrderContextProvider } from './OrderContext';
import ListOrders from './ListOrders';
import CreateUpdateOrder from './CreateUpdateOrder';
import ShowOrder from './ShowOrder';

const OrderRouter = () => {
  const { path } = useRouteMatch();

  return (
    <OrderContextProvider>
      <Switch>
        <Route
          path={`${path}/create`}
          render={() => <CreateUpdateOrder purpose="create" />}
        />
        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdateOrder purpose="edit" />}
        />
        <Route path={`${path}/show/:id`} component={ShowOrder} />
        <Route path={path} component={ListOrders} />
      </Switch>
    </OrderContextProvider>
  );
};

export default OrderRouter;
