import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  Button,
  Tabs,
  List,
  Popconfirm,
  Input,
  Skeleton,
  Switch,
  Typography,
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './ListContent';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import CustomFilesSpace from './customFilesSpace';
import ContentInformation from '../../components/ContentInformation/ContentInformation';
import LotInterventionsTable from './LotInterventionsTable';
import CommentCard from '../../components/CommentCard/CommentCard';
import LotCustomersTable from './LotCustomersTable';

const { Title } = Typography;
const { TabPane } = Tabs;

const styles = {
  tabTitle: {
    textTransform: 'uppercase',
    fontSize: '0.8rem'
  },
  commentCard: {
    padding: '24px 24px 16px 0px',
    marginTop: 2,
    background: 'var(--componentBackground)',
    display: 'flex'
  }
};

const ShowLot = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [lot, setLot] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/lots/${id}?populate=building`
      });
      setLot(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id, refresh]);

  const handleOk = () => {
    setVisible(false);
    fetchData();
  };

  const updateData = async values => {
    try {
      await dispatchAPI('PATCH', { url: `/lots/${id}`, body: values });
      handleOk();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    })();
  }, [fetchData]);

  const deleteDelivery = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/lots/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const updateResource = async (value, field) => {
    const body = {
      [field]: value
    };
    try {
      await dispatchAPI('PATCH', { url: `/lots/${id}`, body });
      if (field.includes('linky')) setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('lot.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.LOT}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteDelivery}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <DescriptionList
            title="Informations"
            data={listContent(lot)}
            avatar={lot && lot.avatar}
            hasAvatar
            translate
          />
        </Skeleton>
      </ContentInformation>
      <br />
      <ContentCustom>
        <Tabs>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('lots.show.tenantsList')}
              </Title>
            }
            key="1"
          >
            <LotCustomersTable />
          </TabPane>
          <TabPane
            tab={<Title style={styles.tabTitle}>{t('lots.show.pdl')}</Title>}
            key="2"
          >
            <List size="small" bordered style={{ width: '50%' }}>
              <List.Item>
                <div>{t('lots.form.elec_delivery_point_number')}</div>
                <Input
                  defaultValue={lot?.elec_delivery_point_number}
                  style={{ float: 'right', width: '50%' }}
                  onBlur={e =>
                    updateResource(e.target.value, 'elec_delivery_point_number')
                  }
                />
              </List.Item>
              <List.Item>
                <div>{t('lots.form.water_delivery_point_number')}</div>
                <Input
                  defaultValue={lot?.water_delivery_point_number}
                  style={{ float: 'right', width: '50%' }}
                  onBlur={e =>
                    updateResource(
                      e.target.value,
                      'water_delivery_point_number'
                    )
                  }
                />
              </List.Item>
              <List.Item>
                <div>{t('lots.form.is_linky')}</div>
                <Switch
                  checked={lot?.is_linky}
                  onChange={value => updateResource(value, 'is_linky')}
                />
              </List.Item>
            </List>
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('lots.show.interventionsList')}
              </Title>
            }
            key="3"
          >
            <LotInterventionsTable />
          </TabPane>
        </Tabs>
      </ContentCustom>
      <Row>
        <Col span={20}>
          <ContentCustom>
            <Title
              style={{
                paddingLeft: 24,
                textTransform: 'uppercase',
                fontSize: '1rem'
              }}
            >
              {t('lots.show.documents')}
            </Title>
            <CustomFilesSpace
              id={id}
              files={lot.files}
              forceRefresh={refresh}
              setForceRefresh={setRefresh}
              resource="lots"
            />
          </ContentCustom>
        </Col>
        <Col span={4} style={styles.commentCard}>
          <CommentCard
            visible={visible}
            setVisible={setVisible}
            updateData={updateData}
            data={lot}
          />
        </Col>
      </Row>
    </>
  );
};

export default ShowLot;
