import moment from 'moment';
import { Tag } from 'antd';
import React from 'react';

const columns = ({ t, companies, customers }) => [
  {
    title: t('invoices.columns.emitter'),
    dataIndex: 'emitter',
    key: 'emitter',
    render: emitter => emitter && emitter.name,
    sorter: true,
    filters:
      companies &&
      companies.map(company => ({
        text: company.name,
        value: company._id
      }))
  },
  {
    title: t('invoices.columns.status'),
    key: 'status',
    sorter: true,
    render: record => {
      if (record.hasOwnProperty('reconciliation')) {
        if (
          record &&
          record.reconciliation.hasOwnProperty('total_reconciled') &&
          record.reconciliation.total_reconciled
        ) {
          return <Tag color="green">Payé</Tag>;
        }
        return <Tag color="orange">En attente</Tag>;
      }
      return <Tag color="red">Impayé</Tag>;
    }
  },
  {
    title: t('invoices.columns.number'),
    dataIndex: 'number',
    key: 'number',
    sorter: true
  },
  {
    title: t('invoices.columns.period'),
    dataIndex: 'period',
    key: 'period',
    render: period => moment(period).format('L'),
    sorter: true
  },
  {
    title: t('invoices.columns.customer'),
    dataIndex: 'customer',
    key: 'customer',
    render: customer => customer && customer.name,
    sorter: true,
    filters:
      customers &&
      customers.map(customer => ({
        text: customer.name,
        value: customer._id
      }))
  },
  {
    title: t('invoices.columns.wording'),
    dataIndex: 'wording',
    key: 'wording',
    sorter: true
  },
  {
    title: t('invoices.columns.total'),
    dataIndex: 'total',
    key: 'total',
    render: total =>
      `${parseFloat(total)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`,
    sorter: true
  }
];

export default columns;
