import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Popconfirm, Typography, Skeleton, Col, Row } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RingProgress } from '@ant-design/plots';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { routes, subRoutes } from '../../../utils/constants/routes';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import ContentInformation from '../../../components/ContentInformation/ContentInformation';
import SubExpensesTable from './SubExpensesTable';
import { Datatable } from '../../../components';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import listContent from './listContent';
import CustomLineFileFields from './CustomLineFileFields';
import CommentCard from '../../../components/CommentCard/CommentCard';
import useColumns from './columns';
import ListSettlement from './ListSettlement';

const style = {
  background: 'rgb(38, 100, 123, 0.04)',
  padding: '16px',
  borderRadius: '6px'
};

const Details = () => {
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [expense, setExpense] = useState({});
  const [documents, setDocuments] = useState([]);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState();
  const columns = useColumns();

  const getExpense = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/expenses/${id}?populate=company,building,category,lot`
      });
      setExpense(data);
      setDocuments(data.proofs);
      // if (data.reconciliation && data.reconciliation.total_reconciled) {
      //   setStatus(1);
      // } else {
      //   setStatus(0);
      // }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getExpense();
    setIsLoading(false);
  }, [id]);

  const deleteResource = (key, callback) => {
    dispatchAPI('DELETE', { url: `/expenses/${key}` });
    callback();
  };

  const handleOk = () => {
    setVisible(false);
    fetchData();
  };

  const updateData = async values => {
    try {
      await dispatchAPI('PATCH', {
        url: `/expenses/${id}`,
        body: values
      });
      handleOk();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, refresh]);

  const config = {
    height: 200,
    width: 200,
    autoFit: true,
    percent: status,
    color: ['#5B8FF9', '#E8EDF3']
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`expenses.title`)}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.EXPENSES}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteResource(id, history.goBack)}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <Row gutter={[16, 16]}>
            <Col span={18} style={{ display: 'flex' }}>
              <DescriptionList
                title="Informations"
                data={listContent(expense)}
                translate
              />
            </Col>
            <Col span={6} style={{ display: 'flex' }}>
              <div style={{ ...style, flex: 1 }}>
                <h1
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: 600,
                    textTransform: 'uppercase'
                  }}
                >
                  Statut du règlement
                </h1>
                <RingProgress style={{ marginTop: '10%' }} {...config} />
              </div>
            </Col>
          </Row>
        </Skeleton>
      </ContentInformation>
      <ContentInformation>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ display: 'flex' }}>
            <div style={{ ...style, flex: 1 }}>
              <h1
                style={{
                  fontSize: '1.3rem',
                  fontWeight: 600,
                  textTransform: 'uppercase'
                }}
              >
                Liste des règlements
              </h1>
              {/* <Datatable
                resourceName="expenses"
                columns={columns}
                path="/expenses"
              /> */}
              {/* {expense.sub_expenses?.length > 0 && ( */}
              <ListSettlement t={t} />
              {/* )} */}
            </div>
          </Col>
          <Col span={6} style={{ display: 'flex' }}>
            <div style={{ ...style, flex: 1 }}>
              <h1
                style={{
                  fontSize: '1.3rem',
                  fontWeight: 600,
                  textTransform: 'uppercase'
                }}
              >
                Pièces
              </h1>
              {documents.map(doc => (
                <CustomLineFileFields
                  id={id}
                  resource="holdings"
                  documents={documents}
                  setDocuments={setDocuments}
                  file={doc}
                  purpose="purpose"
                />
              ))}
            </div>
          </Col>
          <Col span={6} style={{ display: 'flex' }}>
            <CommentCard
              style={{ flex: 1 }}
              visible={visible}
              setVisible={setVisible}
              updateData={updateData}
              data={expense}
            />
          </Col>
        </Row>
      </ContentInformation>
    </>
  );
};

export default Details;
