import React, { useState, useEffect } from 'react';
import {
  Card,
  Avatar,
  Select,
  Image,
  Input,
  Row,
  Col,
  Typography,
  Button
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import franceFlag from '../../../assets/images/FR.png';

const { Search } = Input;
const { Title } = Typography;

const countries = [
  { key: 'gb', logo: 'GB', name: 'Angleterre' },
  { key: 'fr', logo: 'FR', name: 'France' }
];

const CALLBACK_ORIGIN = window.location.origin;

const BankSynchronisation = () => {
  const [showListBanks, setShowListBanks] = useState(false);
  const [listBanksSelected, setListBanksSelected] = useState([]);
  const [countrySelected, setCountrySelected] = useState();
  const [institution_idSelected, setInstitution_idSelected] = useState();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();

  const getInstitutions = async country => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bank/institutions/${id}/${country}`
      });
      setListBanksSelected(data);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const postRequisitions = async () => {
    try {
      // add new params for requisitions
      const body = {
        redirect: `${CALLBACK_ORIGIN}/companies/show/${id}`,
        institution_id: institution_idSelected,
        user_language: 'EN'
      };
      const { data } = await dispatchAPI('POST', {
        url: `/bank/requisitions/${id}`,
        body
      });
      if (data.link) {
        window.location.replace(data.link);
      }
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  useEffect(() => {
    if (countrySelected) {
      (async () => {
        await getInstitutions(countrySelected);
        setShowListBanks(true);
      })();
    }
  }, [countrySelected]);

  useEffect(() => {
    if (institution_idSelected) {
      (async () => {
        await postRequisitions();
      })();
    }
  }, [institution_idSelected]);

  return (
    <div
      style={{
        width: '80%',
        margin: 'auto',
        background: 'rgba(255, 255, 255, 0.6)'
      }}
    >
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col span={20}>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '8px'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <Button
                type="link"
                onClick={() => setShowListBanks(!showListBanks)}
              >
                <LeftOutlined />
              </Button>
              <Title level={3} style={{ marginLeft: '16px' }}>
                Selectionner votre banque :
              </Title>
            </div>
            <Search
              style={{
                marginBottom: 20,
                display: 'block',
                width: '70%'
              }}
              placeholder="input search text"
              allowClear
              size="large"
            />

            <Row
              gutter={[16, 16]}
              style={{
                overflowY: 'scroll',
                maxHeight: '500px'
              }}
            >
              {!showListBanks
                ? countries.map(c => (
                    <Col span={12}>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '80px',
                          width: '100%',
                          borderRadius: '16px'
                        }}
                        onClick={() => setCountrySelected(c.key)}
                      >
                        <Image
                          src={franceFlag}
                          width={50}
                          height={50}
                          style={{ marginRight: '10px' }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            direction: 'ltr',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'clip'
                          }}
                        >
                          <h4
                            style={{
                              marginRight: '10px'
                            }}
                          >
                            {c.name}
                          </h4>
                          <span>{'>'}</span>
                        </div>
                      </Button>
                    </Col>
                  ))
                : listBanksSelected.map(bank => (
                    <Col span={12}>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '100px',
                          width: '100%'
                        }}
                        onClick={() => setInstitution_idSelected(bank.id)}
                      >
                        <Image
                          src={bank.logo}
                          width={50}
                          height={50}
                          style={{ marginRight: '10px' }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            direction: 'ltr',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'clip'
                          }}
                        >
                          <h4
                            style={{
                              marginRight: '10px'
                            }}
                          >
                            {bank.name}
                          </h4>
                          <span>{'>'}</span>
                        </div>
                      </Button>
                    </Col>
                  ))}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BankSynchronisation;
