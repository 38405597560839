import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

const percent = (v1, v2) => {
  try {
    return (v1 / v2).toFixed(2);
  } catch (error) {
    return 0;
  }
};

const showRent = amounts => {
  let result = 0;
  try {
    result = amounts[amounts.length - 1].value;
  } catch (error) {
    result = 0;
  }
  return result;
};

const useListContent = ({
  lot,
  tenant,
  building,
  receipts,
  start_date,
  end_date,
  type,
  rents,
  amounts,
  rent_period,
  rent_free,
  insee_type,
  rental_charges_contribution,
  works_amount,
  tenant_part,
  bailleur_part,
  rental_taxes_contribution,
  base_index,
  review_index
}) => {
  const { t } = useTranslation();
  return [
    {
      label: 'rentals.display.lot',
      content: lot && lot.reference
    },
    {
      label: 'rentals.display.insee_type',
      content: insee_type
    },
    {
      label: 'rentals.display.start_date',
      content: moment(start_date).format('DD-MM-YYYY')
    },
    {
      label: 'rentals.display.tenant',
      content: tenant && tenant.name
    },
    {
      label: 'rentals.display.base_index',
      content: base_index
    },
    {
      label: 'rentals.display.review_index',
      content: review_index
    },
    {
      label: 'rentals.display.end_date',
      content: moment(end_date).format('DD-MM-YYYY')
    },
    {
      label: 'rentals.display.type',
      content: t(`rentals.type.${type}`)
    },
    {
      label: 'rentals.display.rent_free',
      content: `${rent_free || 0} jours`
    },
    {
      label: 'rentals.display.rentperiod',
      content: t(`rentals.form.period.${rent_period}`)
    },
    {
      label: 'rentals.display.rent_ht_month',
      content: `${numeral(showRent(amounts) / 12).format('0.00')} €`,
      span: 5
    },
    {
      label: 'rentals.display.rent_ht_m2_month',
      content: `${numeral(showRent(amounts) / 12 / building?.surface).format(
        '0.00'
      )} €`,
      span: 5
    },
    {
      label: 'rentals.display.rent_ht_year',
      content: `${numeral(showRent(amounts)).format('0.00')} €`,
      span: 5
    },
    {
      label: 'rentals.display.rent_ht_m2_year',
      content: `${numeral(showRent(amounts) / building?.surface).format(
        '0.00'
      )} €`,
      span: 5
    },
    {
      label: 'rentals.display.rental_charges_contribution_month',
      content: `${numeral(rental_taxes_contribution / 12).format('0.00')} €`,
      span: 2
    },
    {
      label: 'rentals.display.rental_taxes_contribution_month',
      content: `${numeral(rental_charges_contribution / 12).format('0.00')} €`,
      span: 2
    },
    {
      label: 'rentals.display.rental_charges_contribution_m2_month',
      content: `${numeral(
        rental_taxes_contribution / 12 / building?.surface
      ).format('0.00')} €`,
      span: 2
    },
    {
      label: 'rentals.display.rental_taxes_contribution_m2_month',
      content: `${numeral(
        rental_charges_contribution / 12 / building?.surface
      ).format('0.00')} €`,
      span: 2
    },
    {
      label: 'rentals.display.rental_charges_contribution_year',
      content: `${numeral(rental_taxes_contribution / 12).format('0.00')} €`,
      span: 2
    },
    {
      label: 'rentals.display.rental_taxes_contribution_year',
      content: `${numeral(rental_charges_contribution / 12).format('0.00')} €`,
      span: 2
    },
    {
      label: 'rentals.display.rental_charges_contribution_m2_year',
      content: `${numeral(rental_taxes_contribution / building?.surface).format(
        '0.00'
      )} €`,
      span: 2
    },
    {
      label: 'rentals.display.rental_taxes_contribution_m2_year',
      content: `${numeral(
        rental_charges_contribution / building?.surface
      ).format('0.00')} €`,
      span: 2
    }
  ];
};

export default useListContent;
