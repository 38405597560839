import moment from 'moment';
import React from 'react';

const columns = (t, companies) => [
  {
    title: t('orders.list.column.title.number'),
    dataIndex: 'number',
    key: 'number',
    sorter: true
  },
  {
    title: t('orders.list.column.title.subject'),
    dataIndex: 'subject',
    key: 'subject',
    sorter: true
  },
  {
    title: t('orders.list.column.title.customer'),
    dataIndex: 'customer',
    key: 'customer',
    render: customer => customer && customer.name,
    sorter: true,
    filters: companies && companies.map(c => ({
      text: c.name, value: c._id
    }))
  },
  {
    title: t('orders.list.column.title.reception_date'),
    dataIndex: 'reception_date',
    key: 'reception_date',
    render: date => date && moment(date).format('DD/MM/YYYY'),
    sorter: true
  }
];

export default columns;
