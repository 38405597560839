import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import InterventionStatusTag from './InterventionStatusTag';
import InterventionObjectIcon from './InterventionObjectIcon';

const useColumns = enums => {
  const { t } = useTranslation();
  moment.locale('fr');

  return [
    {
      title: t('interventions.columns.status'),
      dataIndex: 'status',
      render: status => InterventionStatusTag({ status }),
      sorter: true,
      filters: enums && enums.interventionsStatus,
      filterSearch: true,
      onFilter: (value, record) => record && record.status && record.status.startsWith(value)
    },
    {
      title: t('interventions.columns.building'),
      dataIndex: 'building',
      render: building => building && building.name,
      sorter: true
    },
    {
      title: t('interventions.columns.lot'),
      dataIndex: 'lot',
      render: lot => (lot && lot.reference) || '-',
      sorter: true
    },
    {
      title: t('interventions.columns.customer'),
      dataIndex: 'customer',
      render: customer => (customer && customer.name) || '-',
      sorter: true
    },
    {
      title: t('interventions.columns.intervener_name'),
      dataIndex: 'intervener',
      render: intervener => (intervener && intervener.name) || '-',
      sorter: true
    },
    {
      title: t('interventions.columns.type'),
      dataIndex: 'type',
      render: type => t(`interventions.columns.types.${type}`),
      sorter: true,
      filters: enums && enums.interventionsTypes,
      filterSearch: true,
      onFilter: (value, record) => record && record.status && record.status.startsWith(value)
    },
    {
      title: t('interventions.columns.object'),
      dataIndex: 'object',
      render: object => (
        <>
          <span style={{ marginRight: '5px' }}>
            {InterventionObjectIcon({ object })}
          </span>
          {object && t(`interventions.columns.objects.${object}`)}
        </>
      ),
      sorter: true,
      filters: enums && enums.interventionsObjects,
      filterSearch: true,
      onFilter: (value, record) => record && record.status && record.status.startsWith(value)
    },
    {
      title: t('interventions.columns.demandDate'),
      dataIndex: 'demandDate',
      render: demandDate => moment(demandDate).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('interventions.columns.interventionDate'),
      dataIndex: 'interventionDate',
      render: interactionDate => moment(interactionDate).format('DD/MM/YYYY'),
      sorter: true
    }
  ];
};

export default useColumns;
