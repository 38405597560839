import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Tabs,
  message,
  Skeleton,
  Col,
  Row,
  Typography,
  Popconfirm
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  WarningOutlined,
  DeleteOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import ContentInformation from '../../../components/ContentInformation/ContentInformation';
import { routes } from '../../../utils/constants/routes';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import CustomersLocationsTable from './CustomersLocationsTable';
import CustomersInvoicesTable from './CustomersInvoicesTable';
import CustomFilesSpace from './customFilesSpace';
import CustomerReceiptsTable from './CustomerReceiptsTable';
import CustomerInterventionsTable from './CustomerInterventionsTable';
import CommentCard from '../../../components/CommentCard/CommentCard';
import CustomerRentReviewsTable from './CustomerRentReviewsTable';
import { useGenerateDocument } from '../../../utils/generateDocument';

const { TabPane } = Tabs;
const { Title } = Typography;

const styles = {
  tabTitle: {
    textTransform: 'uppercase',
    fontSize: '0.8rem'
  }
};

const DetailCustomer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { generateDocument } = useGenerateDocument();
  const [customer, setCustomer] = useState({});
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [visible, setVisible] = useState(false);
  const [modalComment, setModalComment] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [docIsCreating, setDocIsCreating] = useState(false);
  const [docIsDownloading, setDocIsDownloading] = useState(false);

  const genrateQuittancesDoc = async () => {
    setDocIsCreating(true);
    await generateDocument(id, 'quittances');
    setDocIsCreating(false);
  };

  const genrateInvoicesDoc = async () => {
    setDocIsDownloading(true);
    await generateDocument(id, 'invoices');
    setDocIsDownloading(false);
  };

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}`
      });
      const newData = data;
      newData.type === 'BUSINESS'
        ? (newData.type = 'BUSINESS')
        : (newData.type = 'PERSONAL');
      setCustomer(data);
      setModalComment(data.comment);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsloading(false);
  }, [id]);

  const fetchReceipts = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/receipts` });
      setReceipts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      await fetchReceipts();
    })();
  }, [fetchData, refresh]);

  const deleteCustomer = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/customers/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const updatePayments = async (key, is_reconciled, type) => {
    try {
      const collection = type === 'RECEIPTS' ? 'receipts' : 'expenses';
      await dispatchAPI('PATCH', {
        url: `/${collection}/${key}`,
        body: { is_reconciled }
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleOk = () => {
    setModalComment();
    setVisible(false);
    fetchData();
  };

  const updateCustomer = async values => {
    try {
      setConfirmLoading(true);
      await dispatchAPI('PATCH', {
        url: `/customers/${customer._id}`,
        body: values
      });
      handleOk();
      setConfirmLoading(false);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`customers.show.title`)}
        extra={
          <>
            <Button
              type="primary"
              loading={docIsCreating}
              onClick={() => genrateQuittancesDoc()}
            >
              {`${t('buttons.print_quittances')} `}
              <PrinterOutlined />
            </Button>
            <Button
              type="primary"
              loading={docIsDownloading}
              onClick={() => genrateInvoicesDoc()}
            >
              {`${t('buttons.print_invoices')} `}
              <PrinterOutlined />
            </Button>
            <Link
              to={{
                pathname: `${routes.CUSTOMERS}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCustomer}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />

      <ContentInformation>
        <Row gutter={[24, 0]}>
          <Col span={17} style={{ display: 'flex' }}>
            <Skeleton loading={isLoading} active>
              <DescriptionList
                style={{ flex: 1 }}
                title="Informations"
                data={listContent(customer).filter(
                  d =>
                    d.label !== 'customers.form.manager_email' &&
                    d.label !== 'customers.form.manager_telephone' &&
                    d.label !== 'customers.form.manager_first_name' &&
                    d.label !== 'customers.form.manager_last_name' &&
                    d.label !== 'customers.form.first_name' &&
                    d.label !== 'customers.form.last_name'
                )}
                translate
                resourceName="customer"
                avatar={customer && customer.avatar}
                hasAvatar
              />
            </Skeleton>
          </Col>
          <Col span={7} style={{ display: 'flex' }}>
            <Skeleton loading={isLoading} active>
              <DescriptionList
                style={{ flex: 1 }}
                title="Correspondant principal"
                data={listContent(customer).filter(
                  d =>
                    d.label === 'customers.form.manager_email' ||
                    d.label === 'customers.form.manager_telephone' ||
                    d.label === 'customers.form.manager_first_name' ||
                    d.label === 'customers.form.manager_last_name'
                )}
                translate
                resourceName="customer"
              />
            </Skeleton>
          </Col>
        </Row>
      </ContentInformation>
      <br />
      <ContentCustom>
        <Tabs>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('customers.show.leasesList')}
              </Title>
            }
            key="1"
          >
            <CustomersLocationsTable />
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('customers.show.interventionsList')}
              </Title>
            }
            key="2"
          >
            <CustomerInterventionsTable />
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('customers.show.rentalsList')}
              </Title>
            }
            key="3"
          >
            <CustomerReceiptsTable />
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('customers.show.paymentsList')}
              </Title>
            }
            key="4"
          >
            <CustomersInvoicesTable />
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('customers.show.reviewsOfRentalList')}
              </Title>
            }
            key="5"
          >
            <CustomerRentReviewsTable />
          </TabPane>
        </Tabs>
      </ContentCustom>
      <Row style={{ marginRight: 24 }}>
        <Col span={20} style={{ display: 'flex' }}>
          <ContentCustom style={{ flex: 1 }}>
            <Title
              style={{
                paddingLeft: 24,
                textTransform: 'uppercase',
                fontSize: '1rem'
              }}
            >
              {t('customers.show.documents')}
            </Title>
            <CustomFilesSpace
              id={id}
              files={customer.files}
              forceRefresh={refresh}
              setForceRefresh={setRefresh}
              resource="customers"
            />
          </ContentCustom>
        </Col>
        <Col
          span={4}
          style={{
            padding: '24px 0px 16px 0px',
            marginTop: 2,
            display: 'flex'
          }}
        >
          <CommentCard
            style={{ flex: 1 }}
            visible={visible}
            setVisible={setVisible}
            setModalComment={setModalComment}
            updateData={updateCustomer}
            data={customer}
          />
        </Col>
      </Row>
    </>
  );
};

export default DetailCustomer;
