import React, { useState } from 'react';
import { Calendar, Badge, Button, Row, Col, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { BellOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreateUpdateModal from '../CreateUpdateModal';

const { Option } = Select;

const Agenda = ({
  getEvents,
  events,
  setAlerts,
  enums,
  handleChangePanel,
  month,
  onAlerts,
  setEvents,
  user,
  year
}) => {
  moment.locale('fr');
  const [valueOfDay, setValueOfDay] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());
  const [visible, setVisible] = useState(false);
  const [purpose, setPurpose] = useState();
  const [dataToModal, setDataToModal] = useState();
  const { t } = useTranslation();
  const [yearActive, setYearActive] = useState(false);
  const [resource, setResource] = useState('agendas');

  const handleOk = () => {
    setPurpose();
    setDataToModal();
    setVisible(false);
    if (yearActive) {
      getEvents(null, year);
    } else {
      getEvents(month, year);
    }
  };

  const handleCancel = () => {
    setPurpose();
    setDataToModal();
    setVisible(false);
  };

  const handleEventSelect = (action, item) => {
    if (item) {
      setDataToModal(item);
    }
    setPurpose(action);
    setVisible(true);
  };

  const getListData = value => {
    const listData = [];
    if (events.length !== 0 && enums) {
      events.forEach(event => {
        if (
          value.format('YYYY-MM-DD') === moment(event.date).format('YYYY-MM-DD')
        ) {
          listData.push({
            ...event,
            content:
              event?.resource === 'agenda'
                ? (event?.title_events).charAt(0).toUpperCase() +
                  event?.title_events.slice(1)
                : t(`interventions.form.objects.${event?.object}`),
            color:
              event?.resource === 'agenda'
                ? enums[event?.type_events]?.color
                : 'green'
          });
        }
      });
    }
    return listData;
  };

  const dateCellRender = value => {
    const listData = getListData(value);
    return (
      <>
        <Button
          type="link"
          style={{ position: 'absolute', top: 0, left: 0 }}
          onClick={() => handleEventSelect('create')}
        >
          <PlusCircleOutlined />
        </Button>
        <div style={{ margin: '0 16px', color: 'var(--textColorSecondary)' }}>
          <span>
            {listData.filter(item => item.reminder).length > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: 6,
                  right: 46,
                  color: 'red'
                }}
              >
                <Badge
                  count={listData.filter(item => item.reminder).length}
                  size="small"
                >
                  <BellOutlined />
                </Badge>
              </div>
            )}
          </span>
          <span>
            {listData.map(item => {
              const content = (
                <div
                  style={{
                    direction: 'ltr',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'clip'
                  }}
                  key={item._id}
                >
                  <Badge color={item.color} />
                  <Button
                    type="link"
                    onClick={() => handleEventSelect('update', item)}
                    style={{ margin: 0, padding: 0, color: 'inherit' }}
                  >
                    {item.content}
                  </Button>
                </div>
              );
              return content;
            })}
          </span>
        </div>
      </>
    );
  };

  const getMonthData = value => {
    const listData = [];
    if (events.length !== 0 && enums) {
      events.forEach(event => {
        if (value.format('YYYY-MM') === moment(event.date).format('YYYY-MM')) {
          listData.push({
            ...event,
            content:
              event?.resource === 'agenda'
                ? (event?.title_events).charAt(0).toUpperCase() +
                  event?.title_events.slice(1)
                : t(`interventions.form.objects.${event?.object}`),
            color:
              event?.resource === 'agenda'
                ? enums[event?.type_events]?.color
                : 'green'
          });
        }
      });
    }
    return listData;
  };

  const monthCellRender = value => {
    const listData = getMonthData(value);
    return (
      <>
        <div style={{ margin: '0 16px' }}>
          <span>
            {listData.filter(item => item.reminder).length > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: 6,
                  right: 46,
                  color: 'red'
                }}
              >
                <Badge count={listData.length} size="small">
                  <BellOutlined />
                </Badge>
              </div>
            )}
          </span>
          <span style={{ color: 'var(--textColorSecondary)' }}>
            {listData.map(item => {
              const content = (
                <div
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                  key={item._id}
                >
                  <Badge color={item.color} />
                  <Button
                    type="link"
                    onClick={() => handleEventSelect('update', item)}
                    style={{ color: 'inherit' }}
                  >
                    {item.content}
                  </Button>
                </div>
              );
              return content;
            })}
          </span>
        </div>
      </>
    );
  };

  const onPanelChange = (value, mode) => {
    handleChangePanel(
      moment(value).format('MMMM'),
      moment(`${value.year()}-${value.month() + 1}`).format('MM'),
      value.year()
    );
    if (mode === 'month') {
      getEvents(value.month() + 1, value.year());
    } else {
      getEvents(null, value.year());
    }
    setValueOfDay(value);
  };

  const handleOnChangeEvents = value => {
    if (value === 'all') {
      if (yearActive) {
        getEvents(null, year);
      } else {
        getEvents(month, year);
      }
    } else if (value === 'self') {
      const selfEvents = events.filter(e =>
        e.resource === 'agenda'
          ? e.user && user && e.user[0]._id === user._id && !e.common_events
          : true
      );
      setEvents(selfEvents);
      setAlerts(onAlerts(selfEvents));
    }
  };

  const handleRadioChangeYearMonth = e => {
    if (e.target.value === 'month') setYearActive(false);
    else {
      setYearActive(true);
      getEvents(null, year);
    }
  };

  const onSelect = value => {
    setValueOfDay(value);
    setSelectedValue(value);
  };

  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = value.clone();
    const localData = value.localeData();
    const months = [];

    for (let i = 0; i < 12; i += 1) {
      current.month(i);
      months.push(localData.monthsShort(current));
    }

    for (let index = start; index < end; index += 1) {
      monthOptions.push(<Option key={index}>{months[index]}</Option>);
    }

    const mmmm = value.month();

    const yyyy = value.year();
    const options = [];
    for (let i = yyyy - 10; i < yyyy + 10; i += 1) {
      options.push(
        <Option key={i} value={i}>
          {months[i]}
        </Option>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <Row gutter={8}>
          <Col>
            <Button
              type="link"
              onClick={() => handleEventSelect('create')}
              style={{ color: 'var(--textColor)' }}
            >
              <span style={{ marginRight: '5px' }}>+</span>
              {t('agendas.calendar.new_event')}
            </Button>
          </Col>
          <Col>
            <Select
              defaultValue="Tous les évènements"
              onChange={v => handleOnChangeEvents(v)}
            >
              <Option value="all">{t('agendas.calendar.all')}</Option>
              <Option value="self">{t('agendas.calendar.self')}</Option>
            </Select>
          </Col>
          <Col>
            <Select
              dropdownMatchSelectWidth={false}
              className="my-year-select"
              onChange={newYear => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
              onSelect={y => getEvents(null, y)}
              value={String(yyyy)}
            >
              {options}
            </Select>
          </Col>
          {!yearActive ? (
            <Col>
              <Select
                dropdownMatchSelectWidth={false}
                value={String(mmmm)}
                onChange={selectedMonth => {
                  const newValue = value.clone();
                  newValue.month(parseInt(selectedMonth, 10));
                  onChange(newValue);
                }}
              >
                {monthOptions}
              </Select>
            </Col>
          ) : null}
          <Col>
            <Radio.Group
              onChange={e => {
                onTypeChange(e.target.value);
                handleRadioChangeYearMonth(e);
              }}
              value={type}
            >
              <Radio.Button value="month">
                {t('agendas.calendar.month')}
              </Radio.Button>
              <Radio.Button value="year">
                {t('agendas.calendar.year')}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <Calendar className="homeCalendar"
        value={valueOfDay}
        onSelect={onSelect}
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
        onPanelChange={onPanelChange}
        headerRender={headerRender}
      />
      <CreateUpdateModal
        visible={visible}
        setVisible={setVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        purpose={purpose}
        data={dataToModal}
        selectedValue={selectedValue}
        resource={resource}
        setResource={setResource}
      />
    </>
  );
};

Agenda.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getEvents: PropTypes.func.isRequired
};

export default Agenda;
