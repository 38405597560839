import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('amortizations.columns.no_terms'),
      dataIndex: 'no_terms'
    },
    {
      title: t('amortizations.columns.deadline_date'),
      dataIndex: 'month',
      render: month => moment(month).format('L')
    },
    {
      title: t('amortizations.columns.due_amount'),
      editable: true,
      dataIndex: 'due_amount',
      render: amount =>
        `${parseFloat(amount)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
    },
    {
      title: t('amortizations.columns.repaid_capital'),
      dataIndex: 'repaid_capital',
      editable: true,
      render: repaid_capital =>
        `${parseFloat(repaid_capital)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
    },
    {
      title: t('amortizations.columns.repaid_interest'),
      dataIndex: 'repaid_interest',
      render: repaid_interest =>
        `${parseFloat(repaid_interest)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
    },
    {
      title: t('amortizations.columns.due_capital'),
      dataIndex: 'due_capital',
      render: due_capital =>
        `${parseFloat(due_capital)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
    }
  ];
};

export default useColumns;
