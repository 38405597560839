const headers = [
  {
    label: 'name',
    key: 'name'
  },
  {
    label: 'address_hq',
    key: 'address_hq'
  },
  {
    label: 'number_tva',
    key: 'number_tva'
  },
  {
    label: 'manager_name',
    key: 'manager.name'
  },
  {
    label: 'manager_email',
    key: 'manager.email'
  },
  {
    label: 'manager_phone',
    key: 'manager.phone'
  }
];

export default headers;
