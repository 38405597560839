import { Button, Form, InputNumber, Table, Row, DatePicker, Input } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

moment.locale('fr');

const StyledTable = styled.div`
  .rowStyle {
    color: 'black';
    cursor: pointer;
  }
`;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  isDatePicker,
  isText,
  picker,
  editing,
  setEditing,
  ...restProps
}) => {
  // const [editing, setEditing] = useState(false);
  const { message } = useErrorMessage();
  const form = useContext(EditableContext);

  const toggleEdit = () => {
    if (Array.isArray(dataIndex) && dataIndex.length > 1) {
      switch (dataIndex.length) {
        case 2:
          if (
            record &&
            record[dataIndex[0]] &&
            record[dataIndex[0]][dataIndex[1]]
          ) {
            form.setFieldsValue({
              ...record,
              ...([dataIndex[0]] && {
                [dataIndex[0]]: {
                  ...record[dataIndex[0]],
                  ...([dataIndex[1]] && {
                    [dataIndex[1]]: record[dataIndex[0]][dataIndex[1]]
                  })
                }
              })
            });
          }
          break;
        case 4:
          if (typeof dataIndex[2] === 'number') {
            if (
              record &&
              record[dataIndex[0]] &&
              record[dataIndex[0]][dataIndex[1]] &&
              record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
            ) {
              form.setFieldsValue({
                ...record,
                [dataIndex[0]]: {
                  ...record[dataIndex[0]],
                  ...([dataIndex[1]] && {
                    [dataIndex[1]]: [...record[dataIndex[0]][dataIndex[1]]]
                  })
                }
              });
            }
          }
          break;
        default:
          break;
      }
    } else {
      form.setFieldsValue({
        ...record,
        [dataIndex]: record[dataIndex]
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      // toggleEdit();
      let newRecord = record;
      if (Array.isArray(dataIndex) && dataIndex.length > 1) {
        switch (dataIndex.length) {
          case 2:
            if (
              values &&
              values[dataIndex[0]] &&
              values[dataIndex[0]][dataIndex[1]]
            ) {
              if (
                record &&
                record[dataIndex[0]] &&
                record[dataIndex[0]].hasOwnProperty('changed')
              ) {
                values[dataIndex[0]].changed = true;
              }
              newRecord = {
                ...record,
                ...values,
                [dataIndex[0]]: {
                  ...record[dataIndex[0]],
                  ...values[dataIndex[0]],
                  ...([dataIndex[1]] && {
                    [dataIndex[1]]: values[dataIndex[0]][dataIndex[1]]
                  })
                }
              };
            }
            break;
          case 4:
            if (typeof dataIndex[2] === 'number') {
              if (
                values &&
                values[dataIndex[0]] &&
                values[dataIndex[0]][dataIndex[1]] &&
                values[dataIndex[0]][dataIndex[1]][dataIndex[2]] &&
                values[dataIndex[0]][dataIndex[1]][dataIndex[2]][dataIndex[3]]
              ) {
                let finalTable = [];
                const spread =
                  record &&
                  record[dataIndex[0]] &&
                  record[dataIndex[0]][dataIndex[1]];
                if (
                  Array.isArray(
                    record[dataIndex[0]] && record[dataIndex[0]][dataIndex[1]]
                  )
                ) {
                  finalTable = [...record[dataIndex[0]][dataIndex[1]]];
                  finalTable[dataIndex[2]] = {
                    [picker]: dataIndex[2],
                    ...([dataIndex[3]] && {
                      ...(spread &&
                        record[dataIndex[0]][dataIndex[1]][dataIndex[2]]),
                      [dataIndex[3]]:
                        values[dataIndex[0]][dataIndex[1]][dataIndex[2]][
                          dataIndex[3]
                        ]
                    })
                  };
                } else {
                  finalTable[dataIndex[2]] = {
                    [picker]: dataIndex[2],
                    ...([dataIndex[3]] && {
                      ...(spread &&
                        record[dataIndex[0]][dataIndex[1]][dataIndex[2]]),
                      [dataIndex[3]]:
                        values[dataIndex[0]][dataIndex[1]][dataIndex[2]][
                          dataIndex[3]
                        ]
                    })
                  };
                }
                if (
                  record &&
                  record[dataIndex[0]] &&
                  record[dataIndex[0]].hasOwnProperty('changed')
                ) {
                  values[dataIndex[0]].changed = true;
                }
                newRecord = {
                  ...record,
                  ...values,
                  [dataIndex[0]]: {
                    ...record[dataIndex[0]],
                    ...values[dataIndex[0]],
                    ...([dataIndex[1]] && { [dataIndex[1]]: finalTable })
                  }
                };
              } else if (record && record[dataIndex[0]] !== null) {
                values[dataIndex[0]].changed = true;
                if (
                  !values[dataIndex[0]][dataIndex[1]][dataIndex[2]][
                    dataIndex[3]
                  ]
                ) {
                  const finalTable = [...record[dataIndex[0]][dataIndex[1]]];
                  finalTable[dataIndex[2]] = null;
                  newRecord = {
                    ...record,
                    ...values,
                    [dataIndex[0]]: {
                      ...record[dataIndex[0]],
                      ...values[dataIndex[0]],
                      ...([dataIndex[1]] && { [dataIndex[1]]: finalTable })
                    }
                  };
                }
              }
            }
            break;
          default:
            break;
        }
      } else {
        if (record && record[dataIndex].hasOwnProperty('changed')) {
          values[dataIndex].changed = true;
        }
        newRecord = {
          ...record,
          ...values
        };
      }
      handleSave(newRecord);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  useEffect(() => {
    if (editing) {
      toggleEdit();
    }
  }, [editing]);

  let childNode = children;

  const renderInput = () => {
    if (isDatePicker) {
      return <DatePicker onPressEnter={save} onBlur={save} />;
    }
    if (isText) {
      return <Input onPressEnter={save} onBlur={save} />;
    }
    return <InputNumber onPressEnter={save} onBlur={save} />;
  };

  if (editing && editable) {
    childNode = (
      <Form.Item
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
        name={dataIndex}
      >
        {renderInput()}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableTable = ({
  defaultColumns,
  data,
  isLoading,
  year,
  type,
  refresh,
  setRefresh
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [editing, setEditing] = useState(false);
  const { dispatchAPI } = useAuthContext();

  useEffect(() => {
    (() => {
      if (data && data.length > 0) {
        setDataSource(data);
      }
    })();
  }, [data]);

  const handleOnClick = () => {
    setEditing(!editing);
    if (editing) {
      dataSource.forEach(async d => {
        if (d.managing) {
          if (!d.managing.hasOwnProperty('changed')) {
            if (type === 'managing_exercice_result') {
              if (d.managing.balances) {
                const firstIndexNotNull = d.managing.balances.findIndex(
                  b => b && b.result_exercice
                );
                const lastIndexNotNull = d.managing.balances.findLastIndex(
                  b => b && b.result_exercice
                );
                const newBalances = d.managing.balances.slice(
                  firstIndexNotNull,
                  lastIndexNotNull + 1
                );
                d.managing.balances = [...newBalances];
                await dispatchAPI('POST', {
                  url: '/managings',
                  body: { ...d.managing, company: d.company._id, type }
                });
              }
            } else {
              await dispatchAPI('POST', {
                url: '/managings',
                body: { ...d.managing, company: d.company._id, year, type }
              });
            }
          }
          if (d.managing.changed) {
            delete d.managing.changed;
            await dispatchAPI('PATCH', {
              url: `/managings/${d.managing._id}`,
              body: { ...d.managing }
            });
          }
        }
      });
    }
    setRefresh(!refresh);
  };

  const handleSave = row => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const mapColumns = col => {
    if (!editing) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        isDatePicker: col.isDatePicker,
        isText: col.isText,
        title: col.title,
        picker: col.picker,
        editing,
        setEditing,
        year,
        handleSave
      })
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  let columns;
  if (defaultColumns) {
    columns = defaultColumns?.map(mapColumns);
  }

  return (
    <>
      <StyledTable
        as={Table}
        style={{ marginTop: 24, overflowX: 'auto' }}
        scroll={{
          x: 'auto'
        }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
      />
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button onClick={handleOnClick}>
          {editing ? 'Enregistrer' : 'Modifier'}
        </Button>
      </Row>
    </>
  );
};

EditableTable.propTypes = {
  defaultColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  year: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

EditableTable.defaultProps = {
  data: [],
  isLoading: false
};

export default EditableTable;
