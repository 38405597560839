import React from 'react';
import { Col, Row, Typography, Card, Divider, Button, Badge } from 'antd';
import {
  BellOutlined,
  HomeOutlined,
  UsergroupDeleteOutlined,
  ContactsOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';

const { Title, Text } = Typography;
const { Meta } = Card;

const getColors = t => {
  const e = {};
  Object.entries(t || {}).map(item => (e[item[1].type] = item[1].color));
  return e;
};

const styles = {
  container: {
    marginTop: '4px',
    textAlign: 'right'
  }
};

const Alerts = ({ events, enums, t }) => {
  const e = getColors(enums);
  return (
    <>
      <div>
        <Title style={styles.container} level={4}>
          <BellOutlined style={{ marginRight: '10px' }} />
          {t('agendas.alerts.alert')}
        </Title>
      </div>
      <Divider />
      <div
        style={{
          overflowY: 'scroll',
          maxHeight: '700px',
          textTransform: 'capitalize'
        }}
      >
        {(events || []).map(item => (
          <Row gutter={[0, 16]} key={item._id}>
            <Col span={24}>
              <Card
                bordered={false}
                style={{
                  background: 'rgb(38, 100, 123, 0.04)',
                  padding: '8px',
                  borderRadius: '8px',
                  margin: '0 8px'
                }}
                hoverable
              >
                <Meta
                  title={
                    <div style={{ marginBottom: '10px', color: 'inherit' }}>
                      <Badge
                        color={e[item.type_events]}
                        text={moment(item.date).format('DD MMMM YYYY')}
                      />
                    </div>
                  }
                />
                <div
                  style={{
                    marginLeft: '16px',
                    color: 'var(--textColorSecondary)'
                  }}
                >
                  <Row gutter={[0, 16]}>
                    <Col span={4}>
                      <Button
                        icon={
                          item.building[0]?.length > 0 ? (
                            <UsergroupDeleteOutlined />
                          ) : (
                            <ContactsOutlined />
                          )
                        }
                        shape="circle"
                        size="small"
                        style={{ backgroundColor: 'inherit', color: 'inherit' }}
                      />
                    </Col>
                    <Col span={20}>
                      <Text style={{ color: 'inherit' }}>
                        {item.building[0]?.length > 0
                          ? item.building[0]?.name
                          : item.title_events}
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={4}>
                      <Button
                        icon={
                          item.building ? (
                            <HomeOutlined />
                          ) : (
                            <ScheduleOutlined />
                          )
                        }
                        shape="circle"
                        size="small"
                        style={{ backgroundColor: 'inherit', color: 'inherit' }}
                      />
                    </Col>
                    <Col span={20}>
                      <Text style={{ color: 'inherit' }}>
                        {item.building[0]?.length > 0
                          ? item.building[0]?.name
                          : item.type_events}
                      </Text>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};

Alerts.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
  enums: PropTypes.shape({}),
  t: PropTypes.func
};

Alerts.defaultProps = {
  events: [],
  enums: {},
  t: null
};

export default Alerts;
