import React, { useCallback, useEffect, useState } from 'react';
import { Select, Input, InputNumber, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Dragger } = Upload;
const { Option } = Select;

const useFields = disabledField => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [companies, setcompanies] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [filteredBuildings, setFilteredBuildings] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState();

  const onSelectCompany = value => {
    if (value) {
      const company = companies.find(({ _id }) => _id === value);
      const companyBuildings = company.buildings;
      const newBuildings = buildings.filter(({ _id }) =>
        companyBuildings.includes(_id)
      );
      setFilteredBuildings(newBuildings);
    }
  };

  const handleOnClearCompany = () => {
    setFilteredBuildings([]);
  };

  const fileToBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const fields = [
    {
      name: ['avatar'],
      input: (
        <Dragger {...draggerProps} style={{ borderRadius: '10px' }}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'lot.fields.company',
      name: ['company'],
      rules: [{ required: true, message: 'Ce champs est requis' }],
      input: (
        <Select
          allowClear
          loading={isFieldsLoading}
          onSelect={onSelectCompany}
          onClear={handleOnClearCompany}
        >
          {(companies || []).map(({ name, _id }) => (
            <Option key={name} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot.fields.building',
      name: ['building'],
      rules: [{ required: true, message: 'Ce champs est requis' }],
      input: (
        <Select allowClear loading={isFieldsLoading} disabled={disabledField}>
          {(filteredBuildings || []).map(elem => (
            <Option key={elem.name} value={elem._id}>
              {elem.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot.fields.reference',
      name: ['reference'],
      rules: [{ required: true, message: 'Ce champs est requis' }],
      input: (
        <InputNumber
          style={{ width: '100%' }}
          placeholder="LOT-"
          formatter={value => value && `LOT-${value}`}
          parser={value => value.replace('LOT-', '')}
        />
      )
    },
    {
      label: 'lot.fields.type',
      name: ['type'],
      rules: [{ required: true, message: 'Ce champs est requis' }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.building_type || []).map(form => (
            <Option key={form} value={form}>
              {t(`lot.fields.${form}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot.fields.floor',
      name: ['floor'],
      rules: [{ required: true, message: 'Ce champs est requis' }],
      input: <Input type="number" />
    },
    {
      label: 'lot.fields.surface',
      name: ['surface'],
      rules: [{ required: true }],
      input: <Input type="number" addonAfter="m²" />
    },
    {
      label: 'lot.fields.nb_parking_slot',
      name: ['nb_parking_slot'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'lot.fields.pieces_number',
      name: ['nb_pieces'],
      input: <Input type="number" />
    },
    {
      label: 'lot.fields.hire_type',
      name: ['hire_type'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.hire_type || []).map(form => (
            <Option key={form} value={form}>
              {form}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot.fields.equipments',
      name: ['equipments'],
      input: (
        <Select loading={isFieldsLoading} mode="multiple">
          {(enums.equipments || []).map(form => (
            <Option key={form} value={form}>
              {form}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot.fields.hire_state',
      name: ['hire_state'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.hire_state || []).map(form => (
            <Option key={form} value={form}>
              {form}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot.fields.condition',
      name: ['condition'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.condition || []).map(form => (
            <Option key={form} value={form}>
              {t(`lot.fields.${form}`)}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies'
      });
      setcompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getBuildings = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/buildings' });
      setBuildings(data);
      setFilteredBuildings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/lots/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getBuildings();
    await getCompanies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64
  };
};

export default useFields;
