import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Layout, Menu, message } from 'antd';
import {
  GlobalOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import useLanguageContext from '../../../contexts/LanguageContext';

const { Header } = Layout;

const StyledHeader = styled.div`
  background: var(--menuDropdownBackground);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 0;
  z-index: 8;
  width: 100%;
  position: fixed;
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-items: center;
`;
const StyledFullNameSpan = styled.span`
  color: white;
  font-weight: bold;
`;
const StyledRoleP = styled.p`
  font-size: 10px;
  color: white;
`;
const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0 12px;
  transition: all 0.3s;
  :hover {
    background: var(--contrastBackground);
  }
  &:hover ${StyledFullNameSpan} {
    color: var(--primaryColor);
  }
  &:hover ${StyledRoleP} {
    color: var(--primaryColor);
  }
`;

const TriggerCollapse = styled.div`
  color: var(--textColor);
  font-size: 20px;
  padding: 0 24px;
  cursor: pointer;
  margin-left: ${props => props.marginleft};
  z-index: 10000;
  :hover {
    color: var(--primaryColor);
  }
`;

const HeaderLayout = ({
  collapseSider,
  collapsed,
  collapseWidth,
  themeLogo
}) => {
  const history = useHistory();
  const { user, dispatchAPI } = useAuthContext();
  const { dispatch: dispatchLocale } = useLanguageContext();
  const { t, i18n } = useTranslation();
  const [marginTrigger, setMarginTrigger] = useState('0px');

  const languages = {
    en_GB: {
      label: 'English',
      icon: '🇬🇧'
    },
    fr_FR: {
      label: 'Français',
      icon: '🇫🇷'
    },
    es_ES: {
      label: 'Español',
      icon: '🇪🇸'
    }
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const onLanguageMenuClick = ({ key }) => {
    dispatchLocale({ type: 'SET_LOCALE', locale: key });
  };

  const test = () => {
    return (
      <Menu onClick={onLanguageMenuClick}>
        {Object.keys(languages).map(locale =>
          i18n.language === locale ? (
            <Menu.Item key={locale} style={{ background: '#e0e0e0' }}>
              <span>{languages[locale].icon}</span>
              {` ${languages[locale].label}`}
            </Menu.Item>
          ) : (
            <Menu.Item key={locale}>
              <span>{languages[locale].icon}</span>
              {` ${languages[locale].label}`}
            </Menu.Item>
          )
        )}
      </Menu>
    );
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button
            style={{
              width: '100%',
              height: 50
            }}
            type="link"
          >
            <StyledFullNameSpan>
              {`${user.first_name} ${user.last_name}`}
            </StyledFullNameSpan>
            <StyledRoleP>
              {t(`users.tags.${user.role.split(':')[1]}`)}
            </StyledRoleP>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="profile" onClick={() => history.push('/profile')}>
        <UserOutlined
          style={{
            fontSize: 16
          }}
        />{' '}
        {t('header.menu.user.profile')}
      </Menu.Item>
      <Menu.Item key="settings" onClick={() => history.push('/settings')}>
        <SettingOutlined
          style={{
            fontSize: 16
          }}
        />{' '}
        {t('header.menu.user.settings')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined
          style={{
            fontSize: 16
          }}
        />{' '}
        {t('header.menu.user.logout')}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setMarginTrigger('0px');
      } else {
        setMarginTrigger('80px');
      }
    } else {
      setMarginTrigger('256px');
    }
  }, [collapsed, collapseWidth]);

  return (
    <StyledHeader as={Header}>
      {window.innerWidth < 992 && (
        <TriggerCollapse
          as={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
          marginleft={marginTrigger}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      {themeLogo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={themeLogo}
          alt="Company logo"
        />
      )}
      <StyledContainer>
        <HeaderDropdown overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <StyledDropdownContainer>
              <UserOutlined />
            </StyledDropdownContainer>
          ) : (
            <StyledDropdownContainer>
              <Avatar
                size="medium"
                icon={user && user.photo ? '' : <UserOutlined />}
                src={user && user.photo ? user.photo : ''}
              >
                {user && `${user.first_name[0]}${user.last_name[0]}`}
              </Avatar>
              <Button type="link">
                <StyledFullNameSpan>
                  {`${user.first_name} ${user.last_name}`}
                </StyledFullNameSpan>
                <StyledRoleP>
                  {t(`users.tags.${user.role.split(':')[1]}`)}
                </StyledRoleP>
              </Button>
            </StyledDropdownContainer>
          )}
        </HeaderDropdown>
        {/* <HeaderDropdown overlayMenu={test}>
          <StyledDropdownContainer>
            <GlobalOutlined
              style={{
                fontSize: 16
              }}
            />
          </StyledDropdownContainer>
        </HeaderDropdown> */}
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayout.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired,
  themeLogo: PropTypes.string
};

HeaderLayout.defaultProps = {
  themeLogo: null
};

export default HeaderLayout;
