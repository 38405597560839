import React from 'react';
import { CreateUpdateContainer } from '../../../components';
import useFields from './fields';

const CreateUpdateSupplier = ({ purpose }) => {
  const {fields, isFieldsLoading} = useFields()
  return (
    <CreateUpdateContainer
      resource="suppliers"
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="suppliers"
    />
  )
}

export default CreateUpdateSupplier;
