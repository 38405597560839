import React from 'react';
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined
} from '@ant-design/icons';

const filesLogo = {
  'image/png': <FileImageOutlined style={{ fontSize: 40, color: '#9254DE' }} />,
  'application/pdf': (
    <FilePdfOutlined style={{ fontSize: 40, color: '#FF4D4F' }} />
  ),
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
    <FileExcelOutlined style={{ fontSize: 40, color: '#73D13D' }} />
  ),
  'text/plain': <FileTextOutlined style={{ fontSize: 40, color: '#40A9FF' }} />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
    <FileTextOutlined style={{ fontSize: 40, color: '#40A9FF' }} />
  )
};
export default filesLogo;
