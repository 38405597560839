import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker, InputNumber, Select, Upload } from 'antd';
import { PlusOutlined, PercentageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import CustomLineFileFields from './CustomLineFileField';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = ({ id, documents, setDocuments, disabledStartDate }) => {
  const { t } = useTranslation();
  const { token, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const [companies, setCompanies] = useState([]);

  const props = {
    name: 'file',
    multiple: true,
    headers: { Authorization: `Bearer ${token}` },
    action: `${process.env.REACT_APP_API_URL}/interventions/files`,

    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        setDocuments([...documents, info.file.response]);
      } else if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  const fields = [
    {
      label: 'company',
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(companies || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'establishment',
      name: ['establishment'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {((enums && enums.banks) || []).map(bank => (
            <Option key={bank} value={bank}>
              {t(`loans.banks.${bank}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'loan_type',
      name: ['loan_type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {((enums && enums.types) || []).map(type => (
            <Option key={type} value={type}>
              {t(`loans.types.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'duration',
      name: ['duration'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      title: 'start_date',
      name: ['start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker style={{ width: '100%' }} disabled={disabledStartDate} />
      )
    },
    {
      title: 'end_date',
      name: ['end_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} disabled />
    },
    {
      title: 'total_amount',
      name: ['total_amount'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          step="0.01"
          decimalSeparator=","
          formatter={value =>
            `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          }
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: 'interest_rate',
      name: ['interest_rate'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          addonAfter={<PercentageOutlined />}
          style={{ width: '100%' }}
          min={0}
          max={100}
          formatter={value =>
            `${value} %`.replace(/\B(?=(\d{2})+(?!\d))/g, ' ')
          }
        />
      )
    },
    {
      title: 'period',
      name: ['period'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {((enums && enums.periods) || []).map(period => (
            <Option key={period} value={period}>
              {t(`loans.types.${period}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'no_folder',
      name: ['no_folder']
    },
    {
      name: ['amortization_table'],
      input: (
        <>
          <Dragger
            {...props}
            style={{ borderRadius: '10px' }}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          {documents.map(doc => (
            <CustomLineFileFields
              id={id}
              resource="interventions"
              documents={documents}
              setDocuments={setDocuments}
              file={doc}
              purpose="purpose"
            />
          ))}
        </>
      )
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/loans/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCompanies();
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
