import { useTranslation } from 'react-i18next';

const useColumns = ({ year }) => {
  const { t } = useTranslation();
  const extraYear = String(year)
    .split('')
    .slice(2, 4)
    .join('');

  return [
    {
      title: t('managings.columns.managing_taxes_tf.name'),
      fixed: 'left',
      editable: false,
      dataIndex: ['company', 'name']
    },
    {
      title: `${t(
        'managings.columns.abreviated_trimester.first_trimester'
      )}${extraYear}`,
      children: [
        {
          title: t('managings.columns.months.JAN'),
          dataIndex: ['managing', 'balances', 0, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.FEV'),
          dataIndex: ['managing', 'balances', 1, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.MAR'),
          dataIndex: ['managing', 'balances', 2, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        }
      ]
    },
    {
      title: `${t(
        'managings.columns.abreviated_trimester.second_trimester'
      )}${extraYear}`,
      children: [
        {
          title: t('managings.columns.months.AVR'),
          dataIndex: ['managing', 'balances', 3, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.MAI'),
          dataIndex: ['managing', 'balances', 4, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.JUN'),
          dataIndex: ['managing', 'balances', 5, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        }
      ]
    },
    {
      title: `${t(
        'managings.columns.abreviated_trimester.third_trimester'
      )}${extraYear}`,
      children: [
        {
          title: t('managings.columns.months.JUL'),
          dataIndex: ['managing', 'balances', 6, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.AOU'),
          dataIndex: ['managing', 'balances', 7, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.SEP'),
          dataIndex: ['managing', 'balances', 8, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        }
      ]
    },
    {
      title: `${t(
        'managings.columns.abreviated_trimester.fourth_trimester'
      )}${extraYear}`,
      children: [
        {
          title: t('managings.columns.months.OCT'),
          dataIndex: ['managing', 'balances', 9, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.NOV'),
          dataIndex: ['managing', 'balances', 10, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.DEC'),
          dataIndex: ['managing', 'balances', 11, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        }
      ]
    }
  ];
};

export default useColumns;
