import React from 'react';
import useColumns from '../../Commercial/Contacts/columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ListSuppliers = () => {
  const columns = useColumns();

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'type',
      key: 'type'
    },
    {
      label: 'my_company',
      key: 'my_company.name'
    },
    {
      label: 'email',
      key: 'email'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="suppliers"
      withSubRoutes
      headers={headers}
      resourceModelName="Supplier"
      scroll={{ x: 1000 }}
    />
  );
};

export default ListSuppliers;
