import { useTranslation } from 'react-i18next';

const useColumns = ({ customers, companies, lots, typeFilters }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('rentals.columns.company'),
      dataIndex: 'building',
      key: 'building',
      render: building => building && building.company.name,
      filters: companies,
      filterSearch: true,
      onFilter: (value, record) =>
        record &&
        record.building &&
        record.building.company._id.startsWith(value)
    },
    {
      title: t('rentals.columns.lot_reference'),
      key: 'lot_reference',
      dataIndex: 'lot',
      render: lot => lot && lot.reference
    },
    {
      title: t('rentals.columns.type'),
      key: 'type',
      dataIndex: 'type',
      render: type => t(`rentals.type.${type}`),
      filters: typeFilters,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.type && record.type.startsWith(value)
    },
    {
      title: t('rentals.columns.locataire_name'),
      key: 'tenant',
      dataIndex: 'tenant',
      render: tenant =>
        tenant.first_name
          ? `${tenant.first_name} ${tenant.last_name}`
          : tenant.name,
      filters: customers,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.tenant && record.tenant._id.startsWith(value)
    },
    {
      title: t('rentals.columns.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      sorter: true,
      render: start_date => new Date(start_date).toLocaleDateString('fr-FR')
    },
    {
      title: t('rentals.columns.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: end_date => new Date(end_date).toLocaleDateString('fr-FR'),
      sorter: true
    }
  ];
};

export default useColumns;
