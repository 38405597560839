import React, { useState, useEffect } from 'react';
import { Upload, InputNumber, Input, DatePicker, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons';
import PlacesAutocomplete from 'react-places-autocomplete';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const { Dragger } = Upload;
const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState();
  const [address, setAddress] = useState();
  const [enums, setEnums] = useState({});

  const fileToBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setBase64();
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/holdings/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const fields = [
    {
      label: 'logo',
      name: ['logo'],
      input: (
        <Dragger {...draggerProps} style={{ borderRadius: '10px' }}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.file')}</p>
        </Dragger>
      )
    },
    {
      label: 'name',
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      label: 'address_hq',
      name: ['address_hq'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Rechercher ...',
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'siren',
      name: ['siren'],
      rules: [{ required: true }]
    },
    {
      label: 'siret',
      name: ['siret'],
      rules: [{ required: true }]
    },
    {
      label: 'share_capital',
      name: ['share_capital'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      label: 'number_tva',
      name: ['number_tva'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      label: 'gender',
      name: ['manager', 'gender'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums.gender || []).map(title => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['manager', 'last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['manager', 'first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'date_of_birth',
      name: ['manager', 'date_of_birth'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      label: 'place_of_birth',
      name: ['manager', 'place_of_birth'],
      rules: [{ required: true }]
    },
    {
      name: ['manager', 'email'],
      rules: [{ required: true }]
    },
    {
      name: ['manager', 'phone'],
      rules: [{ required: true }]
    }
  ];

  return {
    fields,
    base64
  };
};

export default useFields;
