const onValuesChanges = ({ values, form, setDisabledField }) => {
  if (values) {
    if (values.company) {
      setDisabledField(false);
    }
    if (!values.company) {
      form.current.setFieldsValue({
        building: undefined
      });
      setDisabledField(true);
    }
  }
};

export default onValuesChanges;
