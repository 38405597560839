import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AccountingContextProvider } from '../AccountingContext';
import CreateUpdateInvoice from './CreateUpdateInvoice';
import ShowInvoice from './ShowInvoice';
import ListInvoices from './ListInvoices';

const InvoiceRouter = () => {
  const { path } = useRouteMatch();

  return (
    <AccountingContextProvider collection="invoices">
      <Switch>
        <Route
          path={`${path}/create`}
          render={() => <CreateUpdateInvoice purpose="create" />}
        />
        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdateInvoice purpose="edit" />}
        />
        <Route
          path={`${path}/show/:id`}
          render={() => <ShowInvoice resource="invoices" />}
        />
        <Route path={path} component={ListInvoices} />
      </Switch>
    </AccountingContextProvider>
  );
};

export default InvoiceRouter;

