import React from 'react';
import { Layout, PageHeader, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExpensesSettings from './Expense/expensesSettings';
import IncomesSettings from './Income/incomesSettings';
import SuppliersSettings from './Supplier/SuppliersSettings';
import ManagersSettings from './Manager/ManagersSettings';
import RentIndexesSettings from './RentIndex/RentIndexesSettings';
import RentChargesSettings from './RentCharge/RentChargesSettings';
import PermissionSettings from './Permission/PermissionsSettings';
import QuotesSettings from './Quotes/QuotesSettings';

const { Content } = Layout;
const { Panel } = Collapse;

const Settings = () => {
  const { t } = useTranslation();
  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/parameters',
      breadcrumbName: 'Paramètres'
    }
  ];
  return (
    <>
      <PageHeader
        breadcrumb={{
          routes,
          itemRender: ({ path, breadcrumbName }) => (
            <Link to={path}>{breadcrumbName}</Link>
          )
        }}
        title={t('settings.title')}
        onBack={() => window.history.back()}
        ghost={false}
      />
      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Collapse accordion>
          <Panel header={t('settings.expenses.title')} key="1">
            <ExpensesSettings />
          </Panel>
          <Panel header={t('settings.incomes.title')} key="2">
            <IncomesSettings />
          </Panel>
          <Panel header={t('settings.suppliers.title')} key="3">
            <SuppliersSettings />
          </Panel>
          <Panel header={t('settings.manager.title')} key="4">
            <ManagersSettings />
          </Panel>
          <Panel header={t('settings.rentIndex.title')} key="5">
            <RentIndexesSettings />
          </Panel>
          <Panel header={t('settings.baseTax.title')} key="6">
            <RentChargesSettings />
          </Panel>
          <Panel header={t('settings.permissions.title')} key="7">
            <PermissionSettings />
          </Panel>
          <Panel header={t('settings.quotes.title')} key="8">
            <QuotesSettings />
          </Panel>
        </Collapse>
      </Content>
    </>
  );
};

export default Settings;
