import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import useFields from './fields';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const CreateUpdateMemo = ({
  purpose,
  tradKey,
  resource,
  data,
  handleOk,
  handleCancel,
  id,
  rental
}) => {
  const fields = useFields(resource);
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const updateResources = async values => {
    try {
      const updateValues = {
        ...values,
        _id: data._id,
        category: data.category
      };
      const memosIndex = rental.memos.findIndex(m => m._id === data._id);
      rental.memos[memosIndex] = updateValues;
      await dispatchAPI('PATCH', {
        url: `/rentals/${id}`,
        body: {
          memos: rental.memos
        }
      });
      handleOk();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const createResources = async values => {
    try {
      const ID = uuid();
      const newResource = values;
      newResource._id = ID;
      newResource.category = resource;
      await dispatchAPI('PATCH', {
        url: `/rentals/${id}`,
        body: {
          memos: [...rental.memos, newResource]
        }
      });
      handleOk();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const setFieldsValues = () => {
    form.setFieldsValue({
      ...data,
      date: moment(data.date)
    });
  };

  const handleSubmit = async values => {
    if (purpose === 'create') await createResources(values);
    if (purpose === 'edit') await updateResources(values);
  };

  useEffect(() => {
    if (purpose === 'edit' && data) {
      setFieldsValues();
    }
  }, [data]);

  return (
    <div style={{ backgroundColor: 'white', padding: '16px', align: 'center' }}>
      <h1 style={{ fontSize: '1.2rem', fontWeight: 500 }}>
        {t(`${resource}.form.${purpose}`)}
      </h1>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        {fields.map(field => generateFields(tradKey || resource, field))}
        <Row justify="center" gutter={[8, 0]}>
          <Col>
            <Button
              onClick={() => {
                form.resetFields();
                handleCancel();
              }}
            >
              Annuler
            </Button>
          </Col>
          <Col>
            <Button htmlType="submit" onClick={handleSubmit}>
              Sauvegarder
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

CreateUpdateMemo.propTypes = {
  purpose: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  resource: PropTypes.string.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string,
    category: PropTypes.string,
    date: PropTypes.string
  }).isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  rental: PropTypes.shape({
    memos: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

CreateUpdateMemo.defaultProps = {
  tradKey: null
};

export default CreateUpdateMemo;
