import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, DatePicker, InputNumber, Select, Upload, Input } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import CustomLineFileFields from './CustomLineFileFields';

const { Option } = Select;

const { Dragger } = Upload;
const { TextArea } = Input;

const useFields = ({ id, documents, setDocuments }) => {
  const { token, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [lots, setLots] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const [filteredLots, setFilteredLots] = useState([]);
  const [categories, setCategories] = useState([]);
  const [enums, setEnums] = useState([]);

  const props = {
    name: 'file',
    multiple: true,
    headers: { Authorization: `Bearer ${token}` },
    action: `${process.env.REACT_APP_API_URL}/expenses/files`,

    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        setDocuments([...documents, info.file.response]);
      } else if (status === 'error') {
        message(`${info.file.name} file upload failed`);
      }
    }
  };

  const onSelectCompany = value => {
    const company = companies.find(({ _id }) => _id === value);
    const companyBuildings = company.buildings;
    const newBuildings = buildings.filter(({ _id }) =>
      companyBuildings.includes(_id)
    );
    setFilteredBuildings(newBuildings);
  };

  const onSelectBuilding = value => {
    const building = buildings.find(({ _id }) => _id === value);
    const buildingLots = building.lots;
    const newLots = lots.filter(({ _id }) => buildingLots.includes(_id));
    setFilteredLots(newLots);
  };

  const fields = [
    {
      label: 'company',
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading} onSelect={onSelectCompany} allowClear>
          {(companies || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'building',
      name: ['building'],
      input: (
        <Select loading={isLoading} onSelect={onSelectBuilding} allowClear>
          {(filteredBuildings || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'lot',
      name: ['lot'],
      input: (
        <Select loading={isLoading} allowClear>
          {(filteredLots || []).map(({ _id, reference }) => (
            <Option key={_id} value={_id}>
              {reference}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'date',
      name: ['date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      label: 'wording',
      name: ['wording'],
      rules: [{ required: true }]
    },
    {
      label: 'supplier',
      name: ['supplier'],
      rules: [{ required: true }]
    },
    {
      label: 'category',
      name: ['category'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading} allowClear>
          {(categories || []).map(({ _id, label }) => (
            <Option key={_id} value={_id}>
              {label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'amount_ht',
      name: ['amount_ht'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      label: 'amount_tva',
      name: ['amount_tva'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          step="0.01"
          formatter={value => `${value}€`}
          parser={value => value.replace('€', '')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      label: 'payment_type',
      name: ['payment_type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading} allowClear>
          {(enums.paymentType || []).map(type => (
            <Option key={type} value={type}>
              {t(`expenses.paymentTypeEnums.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'proofs',
      name: ['proofs'],
      input: (
        <>
          <Dragger
            {...props}
            style={{ borderRadius: '10px' }}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <CameraOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          {documents.map(doc => (
            <CustomLineFileFields
              id={id}
              resource="holdings"
              documents={documents}
              setDocuments={setDocuments}
              file={doc}
              purpose="purpose"
            />
          ))}
        </>
      )
    },
    {
      label: 'comments',
      name: ['comments'],
      input: <TextArea />
    },
    {
      name: ['charge_tenants'],
      label: 'charge_tenants',
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];

  const getLots = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/lots'
      });
      setLots(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getBuildings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/buildings'
      });
      setBuildings(data);
      setFilteredBuildings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/expenses/expenses-categories'
      });
      setCategories(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getEnums = async () => {
    const { data } = await dispatchAPI('GET', { url: '/expenses/enums' });
    setEnums(data);
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getLots();
    await getCompanies();
    await getBuildings();
    await getCategories();
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isLoading
  };
};

export default useFields;
