import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Popconfirm, Button, Skeleton, Row, Col, Typography } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import ContentInformation from '../../../components/ContentInformation/ContentInformation';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import CustomFilesSpace from './customFileSpace';
import AmortizationTable from './Amortization/Amortization';

const { Title } = Typography;

const style = {
  background: 'rgb(38, 100, 123, 0.04)',
  padding: '16px',
  borderRadius: '6px'
};

const ShowIncome = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [loan, setLoan] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { message } = useErrorMessage();

  const getLoan = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/loans/${id}?populate=company`
      });
      setLoan(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getLoan();
    })();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom
        title={t(`loans.title`)}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.LOANS}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <Row gutter={16}>
            <Col span={18} style={{ display: 'flex' }}>
              <DescriptionList
                style={{ flex: 1 }}
                title="Informations"
                data={listContent(loan)}
                translate
              />
            </Col>
            <Col span={6} style={{ display: 'flex' }}>
              <div style={{ ...style, flex: 1 }}>
                <Title
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '1rem'
                  }}
                >
                  {t('buildings.show.documents')}
                </Title>
                <CustomFilesSpace
                  id={id}
                  files={loan && loan.files}
                  forceRefresh={refresh}
                  setForceRefresh={setRefresh}
                  resource="loans"
                />
              </div>
            </Col>
          </Row>
        </Skeleton>
      </ContentInformation>
      <ContentCustom>
        <AmortizationTable
          refresh={refresh}
          setRefresh={setRefresh}
          loan={loan}
        />
      </ContentCustom>
    </>
  );
};

export default ShowIncome;
