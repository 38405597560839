import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import EditableCell from './EditableCell';
import months from './utils';

const CashFlowTable = ({ companyId, year }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [cashFlowData, setCashFlowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const Prev = styled.div`
    color: #f5222d;
  `;

  const getCashFlowDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/cashflowtable/${companyId}?year=${year}`
      });
      setCashFlowData(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleSave = async body => {
    try {
      const { _id, category, month, previsional_amount } = body;
      const newBody = {
        category_id: _id,
        category,
        year,
        month,
        previsional_amount,
        company: companyId
      };
      await dispatchAPI('POST', {
        url: '/previsional',
        body: newBody
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getCashFlowDatas();
      setIsLoading(false);
    })();
  }, [refresh, year]);

  const data = [
    {
      sub_category: 'incomes',
      children: cashFlowData?.incomes
    },
    {
      sub_category: 'expenses',
      children: cashFlowData?.expenses
    }
  ];

  let sameKey;

  const columns = [
    {
      title: t('companies.cashflow.sub_category'),
      dataIndex: 'sub_category',
      key: 'sub_category',
      editable: true,
      fixed: 'left',
      width: 170,
      render: (value, row) => {
        if (!row.children) {
          if (sameKey === value) {
            return {
              props: {
                rowSpan: 0
              }
            };
          }
          sameKey = value;
          const dataToFilter = data.find(
            item => item.sub_category === row.category
          );
          const count = (dataToFilter?.children || []).filter(
            item => item.sub_category === value
          ).length;
          return {
            children: value,
            props: {
              rowSpan: count
            }
          };
        }
        return { children: t(`companies.cashflow.${value}`) };
      }
    },
    {
      title: t('companies.cashflow.label'),
      dataIndex: 'label',
      width: 250,
      fixed: 'left',
      key: 'label'
    }
  ];

  Object.entries(months).forEach(([month, code]) => {
    columns.push({
      title: t(`companies.cashflow.months.${month}`),
      children: [
        {
          title: t('companies.cashflow.previsional'),
          dataIndex: 'previsional_amount',
          key: code,
          width: 100,
          render: (_, record) => ({
            children: !record.children ? (
              <EditableCell
                editable
                dataIndex="previsional_amount"
                record={record}
                month={code}
                amount={record?.previsionals?.[code - 1]?.[0]?.amount || 0}
                handleSave={handleSave}
              />
            ) : null
          })
        },
        {
          title: t('companies.cashflow.real'),
          dataIndex: 'real',
          key: code,
          width: 100,
          render: (_, record) => ({
            children: !record.children ? (
              <Prev>
                {`${record?.reals?.[code - 1] || 0} €`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ' '
                )}
              </Prev>
            ) : null
          })
        }
      ]
    });
  });

  return (
    <Table
      loading={isLoading}
      columns={columns}
      rowKey="category"
      dataSource={data}
      bordered
      size="middle"
      scroll={{
        x: true
      }}
    />
  );
};

CashFlowTable.propTypes = {
  companyId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired
};

export default CashFlowTable;
