import React, { useEffect, useState } from 'react';
import { Select, Input, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = resource => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/rentals/enums' });
      setEnums(data.memos_type);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const fields = [
    {
      label: 'title',
      name: 'title',
      rules: [{ required: true }]
    },
    {
      label: 'description',
      name: 'description',
      rules: [{ required: true }],
      input: <TextArea rows={4} />
    },
    {
      label: 'date',
      name: 'date',
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      label: 'type',
      name: 'type',
      rules: [{ required: true }],
      input: (
        <Select defaultValue="">
          {(enums || []).map(e => (
            <Option value={e}>{t(`${resource}.form.enums.${e}`)}</Option>
          ))}
        </Select>
      )
    }
  ];

  return fields;
};

export default useFields;
