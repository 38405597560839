import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateUpdateRentReview from './CreateUpdateRentReview';
import ShowRentReview from './ShowRentReview';

const RentReviewRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create/:id`}
        render={() => <CreateUpdateRentReview purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateRentReview purpose="edit" />}
      />
      <Route exact path={`${path}/show/:id`} component={ShowRentReview} />
    </Switch>
  );
};

export default RentReviewRouter;
