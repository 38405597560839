const onValuesChanges = ({
  values,
  setSwitchValue,
  form,
  setDisableFields,
  companyId,
  setBuildingId,
  setCompanyId,
  disableFields
}) => {
  if (values) {
    if (values.reminder) {
      setSwitchValue(true);
    } else {
      setSwitchValue(false);
    }
    if (values.company && !values.building) {
      setCompanyId(values.company);
      form.setFieldsValue({ building: undefined, lot: undefined });
      setDisableFields({ ...disableFields, building: false, lot: true });
    }
    if (values.building && values.company) {
      setDisableFields({ ...disableFields, lot: false });
      if (values.company !== companyId) {
        form.setFieldsValue({ building: undefined, lot: undefined });
        setDisableFields({ ...disableFields, building: false, lot: true });
        setCompanyId(values.company);
      }
      setBuildingId(values.building);
    }
    if (!values.company) {
      form.setFieldsValue({
        company: undefined,
        building: undefined,
        lot: undefined
      });
      setDisableFields({ ...disableFields, building: true, lot: true });
      setBuildingId();
      setCompanyId();
    }
  }
};
export default onValuesChanges;
