import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import onValuesChanges from './utils';

const CreateUpdateLot = ({ purpose }) => {
  const { id } = useParams();
  const [isLinky, setIsLinky] = useState(true);
  const [disabledField, setDisabledField] = useState(true);
  const { fields, isFieldsLoading, base64 } = useFields(disabledField);
  const form = useRef(null);

  useEffect(() => {
    if (id) {
      form.current.setFieldsValue({
        building: id
      });
    }
  }, [id]);

  const onValuesChange = (_, values) => {
    onValuesChanges({
      values,
      form,
      setDisabledField
    });
  };

  const config = {
    onGetResource: {
      setFields: data => {
        setIsLinky(data?.is_linky);
        return {
          ...data,
          liberation_date: data.liberation_date && moment(data.liberation_date)
        };
      }
    },
    onCreateResource: {
      setBody: data => ({
        ...data,
        is_linky: isLinky,
        ...(base64 ? { avatar: base64 } : {})
      })
    },
    onUpdateResource: {
      setBody: data => ({
        ...data,
        is_linky: isLinky,
        ...(base64 ? { avatar: base64 } : {})
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      ref={form}
      onValuesChange={onValuesChange}
      baseUrl="lots"
      resource="lots"
      withSubRoutes
      config={config}
    />
  );
};

CreateUpdateLot.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateLot;
