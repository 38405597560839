import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateUser = ({ purpose }) => {
  const { fields, isFieldsLoading, base64 } = useFields();
  const { user, setUser } = useAuthContext();
  const config = {
    onGetResource: {
      setFields: data => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: data => {
        if (data._id === user._id) setUser({ ...user, ...data });
        return {
          ...data,
          ...(base64 ? { photo: base64 } : {})
        };
      }
    },
    onCreateResource: {
      setBody: data => {
        return {
          ...data,
          photo: base64
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateUser;
