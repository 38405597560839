import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const Context = createContext({});

export const ContextProvider = ({ children, collection }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);

  const postResource = async (body, callback) => {
    try {
      await dispatchAPI('POST', { url: `/${collection}`, body });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteResource = async (id, callback) => {
    try {
      await dispatchAPI('DELETE', { url: `/${collection}/${id}` });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (id, body, callback) => {
    try {
      await dispatchAPI('PATCH', { url: `/${collection}/${id}`, body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Context.Provider
      value={{
        dispatchAPI,
        message,
        collection,
        t,
        postResource,
        updateResource,
        deleteResource
      }}
    >
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  collection: PropTypes.string.isRequired
};

export default () => useContext(Context);
