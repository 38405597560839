import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Divider, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ContentInformation from '../../components/ContentInformation/ContentInformation';
import PageHeaderHome from './PageHeaderHome';
import Agenda from './Agenda/Agenda';
import Alerts from './Alerts';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ListUnpaid from './ListUnpaid';

const { Title } = Typography;

const Home = () => {
  moment.locale('fr');
  const history = useHistory();
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [events, setEvents] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [enums, setEnums] = useState();
  const [quote, setQuote] = useState();
  const [month, setMonth] = useState(moment().format('MMMM'));
  const [mnth, setMnth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const today = moment();

  const handleChangePanel = (mmmm, mm, yyyy) => {
    setMonth(mmmm);
    setMnth(mm);
    setYear(yyyy);
  };

  const onAlerts = event => {
    const results = [];
    for (let i = 0; i < event.length; i += 1) {
      const e = event[i];
      const date = new Date(e.date);
      if (e.reminder) {
        if (today.year() === date.getFullYear()) {
          if (today.month() === date.getMonth()) {
            if (
              today.date() <= date.getDate() &&
              date.getDate() - today.date() <= e.number_days_reminder
            ) {
              results.push(e);
            }
          } else if (
            date.getMonth() > today.month() &&
            date.getDate() - today.date() <= e.number_days_reminder
          ) {
            results.push(e);
          }
        }
      }
    }
    return results;
  };

  const getQuote = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotes/true`
      });
      setQuote(data);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const getEvents = async (mm, yyyy) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/agendas?month=${mm}&year=${yyyy}`
      });
      const interventions = await dispatchAPI('GET', {
        url: `/interventions/agendas?month=${mm}&year=${yyyy}`
      });
      setEvents([...data, ...interventions.data]);
      const alertS = onAlerts(data);
      setAlerts(alertS);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/agendas/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const goToCustomerDetails = _id => {
    history.push(`/customers/show/${_id}`);
  };

  useEffect(() => {
    (async () => {
      await getQuote();
      await getEvents(mnth, year);
      await getEnums();
      await getCustomers();
    })();
  }, []);

  const formatCustomers = () => {
    return (customers || []).map(customer => ({
      label: customer.name,
      value: customer._id
    }));
  };

  return (
    <>
      <PageHeaderHome quote={quote} month={month} />
      <Row>
        <Col xs={24} xxl={18}>
          <ContentInformation>
            <Agenda
              setAlerts={setAlerts}
              enums={enums}
              events={events}
              getEvents={getEvents}
              handleChangePanel={handleChangePanel}
              month={mnth}
              onAlerts={onAlerts}
              setEvents={setEvents}
              user={user}
              year={year}
            />
          </ContentInformation>
        </Col>
        <Col xs={24} xxl={6} style={{ padding: '8px' }}>
          <ContentInformation>
            <Alerts events={alerts} enums={enums} t={t} />
          </ContentInformation>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col style={{ padding: '16px' }} span={24}>
          <Title style={{ fontSize: '1.4rem' }}>Rechercher un locataire</Title>
          <Select
            showSearch
            style={{ width: '20%' }}
            placeholder="Rechercher un locataire ..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={formatCustomers()}
            onSelect={goToCustomerDetails}
            loading={isLoading}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} style={{ padding: '16px' }}>
          <Title style={{ fontSize: '1.4rem' }}>
            Impayés du trimestre en cours
          </Title>
          <ListUnpaid />
        </Col>
      </Row>
    </>
  );
};

export default Home;
