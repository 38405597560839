import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CustomDragger from '../../components/Files/CustomDragger';
import CustomLineFile from '../../components/Files/CustomLineFile';

const Content = styled.div`
  background-color: var(--primaryColor);
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  color: white;
`;

const CustomFilesSpace = ({
  id,
  files,
  forceRefresh,
  setForceRefresh,
  resource
}) => {
  const { t } = useTranslation();
  const props = {
    id,
    files,
    forceRefresh,
    setForceRefresh,
    resource
  };

  return (
    <Row justify="space-around">
      <Col span={4}>
        <Content span={6}>{t('files.rib')}</Content>
        <Content>
          <CustomDragger {...props} category="rib" />
        </Content>
        {files &&
          files
            .filter(({ category }) => category === 'rib')
            .map(file => <CustomLineFile {...props} file={file} />)}
      </Col>

      <Col span={4}>
        <Content span={6}>{t('files.kbis')}</Content>
        <Content>
          <CustomDragger {...props} category="kbis" />
        </Content>
        {files &&
          files
            .filter(({ category }) => category === 'kbis')
            .map(file => <CustomLineFile {...props} file={file} />)}
      </Col>

      <Col span={4}>
        <Content span={6}>{t('files.correspondence')}</Content>
        <Content>
          <CustomDragger {...props} category="correspondence" />
        </Content>
        {files &&
          files
            .filter(({ category }) => category === 'correspondence')
            .map(file => <CustomLineFile {...props} file={file} />)}
      </Col>

      <Col span={4}>
        <Content span={6}>{t('files.assurance')}</Content>
        <Content>
          <CustomDragger {...props} category="assurance" />
        </Content>
        {files &&
          files
            .filter(({ category }) => category === 'assurance')
            .map(file => <CustomLineFile {...props} file={file} />)}
      </Col>

      <Col span={4}>
        <Content span={6}>{t('files.other')}</Content>
        <Content>
          <CustomDragger {...props} category="other" />
        </Content>
        {files &&
          files
            .filter(({ category }) => category === 'other')
            .map(file => <CustomLineFile {...props} file={file} />)}
      </Col>
    </Row>
  );
};

CustomFilesSpace.propTypes = {
  id: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  forceRefresh: PropTypes.string,
  setForceRefresh: PropTypes.func,
  resource: PropTypes.string
};
CustomFilesSpace.defaultProps = {
  files: [],
  forceRefresh: null,
  setForceRefresh: null,
  resource: null
};
export default CustomFilesSpace;
