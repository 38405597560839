import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import Table from './SuppliersTable';

const Settings = () => {
  const { dispatchAPI } = useAuthContext();
  const [datas, setDatas] = useState([]);
  const url = '/configurations';
  const newItem = { type: 'SUPPLIER', label: 'nouvelle catégorie' };

  const fetchDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${url}?type=SUPPLIER`
      });
      setDatas(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const onAdd = async () => {
    try {
      await dispatchAPI('POST', {
        url,
        body: newItem
      });
    } catch (e) {
      message.error(e.message);
    }
    await fetchDatas();
  };

  const onDelete = async ({ _id }) => {
    try {
      await dispatchAPI('DELETE', {
        url: `${url}/${_id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await fetchDatas();
  };

  const onEdit = async (_id, row) => {
    console.log(row);
    try {
      await dispatchAPI('PATCH', {
        url: `${url}/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e);
    }
    await fetchDatas();
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <>
      {datas.length > 0 && (
        <Table datas={datas} onEdit={onEdit} onDelete={onDelete} />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={onAdd}
      >
        <PlusOutlined />
      </Button>
    </>
  );
};
export default Settings;
