import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('loans.columns.company'),
      key: 'company',
      dataIndex: 'company',
      render: company => company && company.name,
      sorter: true
    },
    {
      title: t('loans.columns.establishment'),
      key: 'establishment',
      dataIndex: 'establishment',
      sorter: true
    },
    {
      title: t('loans.columns.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      render: start_date => moment(start_date).format('L'),
      sorter: true
    },
    {
      title: t('loans.columns.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: end_date => moment(end_date).format('L'),
      sorter: true
    },
    {
      title: t('loans.columns.total_amount'),
      key: 'total_amount',
      dataIndex: 'total_amount',
      render: total_amount =>
        `${parseFloat(total_amount)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`,
      sorter: true
    },
    {
      title: t('loans.columns.period'),
      key: 'period',
      dataIndex: 'period',
      render: period => t(`loans.types.${period}`),
      sorter: true
    }
  ];
};

export default useColumns;
