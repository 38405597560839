import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import useContext from './context';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdate = ({ purpose }) => {
  const { collection, t, message, dispatchAPI } = useContext();
  const { fields, isFieldsLoading } = useFields(
    message,
    t,
    dispatchAPI,
    collection
  );

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl={collection}
      resource={collection}
      withSubRoutes
    />
  );
};

CreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdate;

