export const headers = [
  {
    label: "Invoice number",
    key: "number"
  },
  {
    label: "Invoice type",
    key: "type"
  },
  {
    label: "Emission date",
    key: "emission_date"
  },
  {
    label: "Reception date",
    key: "reception_date"
  },
  {
    label: "Payment date",
    key: "payment_date"
  },
  {
    label: "Deadline date",
    key: "deadline_date"
  },
  {
    label: "Customer",
    key: "customer.name"
  },
  {
    label: "Contact first name",
    key: "contact.first_name"
  },
  {
    label: "Contact last name",
    key: "contact.last_name"
  },
  {
    label: "Project name",
    key: "project.name"
  },
  {
    label: "Order number",
    key: "order.number"
  },
  {
    label: "Subject",
    key: "subject"
  },
  {
    label: "Comment",
    key: "comment"
  },
  {
    label: 'Invoice duty free',
    key: "subTotal"
  },
  {
    label: 'Invoice total VAT',
    key: "total_VAT"
  },
  {
    label: 'Invoice total discount',
    key: "total_discount"
  },
  {
    label: 'Invoice total paid',
    key: "total_paid"
  },
  {
    label: 'Invoice total all taxes',
    key: "total"
  }
];
