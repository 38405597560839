import React, { useState } from 'react';
import { Input, DatePicker, InputNumber } from 'antd';

const useFields = ({ lot, customer }) => {
  const [fieldsIsLoading, setIsFieldsLoading] = useState(true);

  const fields = [
    {
      label: 'lot',
      name: ['lot'],
      rules: [{ required: true }],
      initialValue: lot && lot.reference,
      input: (
        <Input
          disabled
          placeholder={lot && lot.reference}
          defaultValue={lot && lot.reference}
        />
      )
    },
    {
      label: 'customer',
      name: ['customer'],
      input: (
        <Input
          disabled
          placeholder={customer && customer.name}
          defaultValue={customer && customer.name}
        />
      )
    },
    {
      label: 'reviewDate',
      name: ['reviewDate'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      label: 'new_index',
      name: ['new_index'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} />
    }
  ];

  return { fields, fieldsIsLoading };
};

export default useFields;
