import React, { useCallback, useEffect, useState } from 'react';
import { Select, Input, Upload } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [address, setAddress] = useState('');
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState();

  const fileToBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const fields = [
    {
      name: ['avatar'],
      input: (
        <Dragger {...draggerProps} style={{ borderRadius: '10px' }}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.file')}</p>
        </Dragger>
      )
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      label: 'company',
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {companies.map(company => (
            <Option key={company._id} value={company._id}>
              {company.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'type_company',
      name: ['type_company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.company_type || []).map(title => (
            <Option key={title} value={title}>
              {t(`buildings.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'address',
      name: ['address'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Rechercher ...',
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'type_parking',
      name: ['type_parking'],
      input: (
        <Select loading={isFieldsLoading} allowClear mode="multiple">
          {(enums.type_parking || []).map(title => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['nb_parking_places'],
      input: <Input type="number" />
    },
    {
      name: ['surface'],
      input: <Input type="number" />
    },
    {
      label: 'access_type',
      name: ['access_type'],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(enums.access_type || []).map(title => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'syndic',
      name: ['syndic'],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {suppliers.map(supplier => (
            <Option key={supplier._id} value={supplier._id}>
              {supplier.name}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/buildings/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/suppliers' });
      setSuppliers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCompanies();
    await getSuppliers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64
  };
};

export default useFields;
