import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateBuilding = ({ purpose }) => {
  const { fields, base64 } = useFields();

  const getCoordinates = async google => {
    const address_detail = {};
    await geocodeByAddress(google)
      .then(results => {
        if (results[0]?.address_components[6]) {
          address_detail.postal_code =
            results[0].address_components[6].long_name;
          address_detail.street = results[0].address_components[1].long_name;
          address_detail.channel = results[0].address_components[0].long_name;
          address_detail.city = results[0].address_components[2].long_name;
          address_detail.country = results[0].address_components[5].long_name;
        }
      })
      .catch(error => {
        return error;
      });
    return address_detail;
  };

  const config = {
    onCreateResource: {
      setBody: async data => {
        const address_detail = await getCoordinates(data.address);
        return {
          ...data,
          address_detail,
          ...(base64 ? { avatar: base64 } : {})
        };
      }
    },
    onGetResource: {
      setFields: data => ({
        ...data,
        construction_date: moment(data.construction_date)
      })
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          ...(base64 ? { avatar: base64 } : {})
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="buildings"
      resource="buildings"
      config={config}
    />
  );
};

CreateUpdateBuilding.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateBuilding;

