import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Layout, message, Row, Typography, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import useHandleResize from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/logo.png';
import useAuthContext from '../../contexts/AuthContext';
import BkgImage from '../../assets/images/bkg.jpg';

const Login = () => {
  const { dispatchAPI } = useAuthContext();
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width, height } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const [googleUrl, setGoogleUrl] = useState('');
  const [code, setCode] = useState('');
  const forms = {
    login: <LoginForm switchForm={f => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={f => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={f => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={f => setCurrentForm(f)} token={token} />
    )
  };
  const { location } = history;

  if (location.search) {
    const searchString = location.search.split('?')[1];
    const searchParts = searchString.split('&');

    searchParts.forEach(part => {
      const partMap = part.split('=');

      if (partMap[0] === 'code') {
        const newCode = partMap[1];
        if (code === '') setCode(newCode);
      }
    });
  }

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <Layout style={{ minHeight: '100vh', display: 'inherit' }}>
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 8 }}
          style={{
            backgroundColor: '#26647B'
          }}
        >
          <Row justify="center" align="middle" style={{ height: '100vh' }}>
            {width < 768 && height > 550 && (
              <img alt="logo" style={{ width: '60%' }} src={Logo} />
            )}
            {width < 768 && height <= 550 && (
              <img alt="logo" style={{ width: '40%' }} src={Logo} />
            )}
            <Col xxl={14} xs={20} style={{ textAlign: 'center' }}>
              {width > 768 && (
                <img
                  style={{
                    width: 250,
                    marginBottom: 50
                  }}
                  alt="Logo"
                  src={Logo}
                />
              )}
              <Title
                style={{
                  color: 'white',
                  textAlign: 'center',
                  fontFamily: 'Pretty Queen Personal Use'
                }}
              >
                {t(`login.title.${currentForm}`)}
              </Title>
              {forms[currentForm]}
            </Col>
          </Row>
        </Col>
        {width > 768 && height > 550 && (
          <Col
            xs={{ span: 0 }}
            md={{ span: 14 }}
            lg={{ span: 16 }}
            style={{
              backgroundColor: '#F7C1AB',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '24px'
            }}
          >
            <Image
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                overflow: 'hidden',
                borderRadius: '40px 5px'
              }}
              preview={false}
              src={BkgImage}
            />
          </Col>
        )}
      </Row>
    </Layout>
  );
};

Login.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default Login;
