import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, Button, Popconfirm, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RentalsTimelineLogos from './RentalsTimelineLogos';

const RentalsTimeline = ({
  memos,
  resource,
  handleCreateUpdate,
  deleteResource
}) => {
  const { t } = useTranslation();

  return (
    <Timeline>
      {memos &&
        memos
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          )
          .filter(({ category }) => category === resource)
          .map(memo => (
            <Timeline.Item>
              <h1>{moment(memo.date).format('LLLL')}</h1>
              <div style={{ backgroundColor: 'white', padding: '16px' }}>
                <Row>
                  <Col span={22}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start space-around',
                        alignItems: 'baseline'
                      }}
                    >
                      {RentalsTimelineLogos(memo.type)}
                      <h1 style={{ marginLeft: '8px' }}>{memo.title}</h1>
                    </div>
                  </Col>
                  <Col span={2}>
                    <Row>
                      <Col span={11}>
                        <Button
                          type="link"
                          onClick={() => {
                            handleCreateUpdate('edit', memo);
                          }}
                        >
                          <EditOutlined />
                        </Button>
                      </Col>
                      <Col span={11}>
                        <Popconfirm
                          title={t('settings.messages.delete-line-confirm')}
                          onConfirm={() => deleteResource(memo)}
                        >
                          <Button type="link">
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <p>{memo.description}</p>
              </div>
            </Timeline.Item>
          ))}
    </Timeline>
  );
};

RentalsTimeline.propTypes = {
  memos: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ),
  resource: PropTypes.string.isRequired,
  handleCreateUpdate: PropTypes.func.isRequired,
  deleteResource: PropTypes.func.isRequired
};

RentalsTimeline.defaultProps = {
  memos: []
};

export default RentalsTimeline;
