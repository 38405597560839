import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useColumns = ({ companies, categories }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('incomes.columns.category'),
      key: 'category',
      dataIndex: 'category',
      render: category => category && category.label,
      sorter: true,
      filters: categories,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.category && record.category._id.startsWith(value)
    },
    {
      title: t('incomes.columns.emission_date'),
      key: 'emission_date',
      dataIndex: 'emission_date',
      render: emission_date => moment(emission_date).format('L'),
      sorter: true
    },
    {
      title: t('incomes.columns.deadline_date'),
      key: 'deadline_date',
      dataIndex: 'deadline_date',
      render: deadline_date => moment(deadline_date).format('L'),
      sorter: true
    },
    {
      title: t('incomes.columns.no_invoice'),
      key: 'no_invoice',
      dataIndex: 'no_invoice',
      sorter: true
    },
    {
      title: t('incomes.columns.company'),
      key: 'company',
      dataIndex: 'company',
      render: company => company && company.name,
      sorter: true,
      filters: companies,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.company && record.company._id.startsWith(value)
    },
    {
      title: t('incomes.columns.supplier'),
      key: 'supplier',
      dataIndex: 'supplier',
      sorter: true
    },
    {
      title: t('incomes.columns.amount_ttc'),
      key: 'amount_ttc',
      render: ({ amount_ht, amount_tva }) =>
        amount_tva &&
        amount_ht &&
        `${parseFloat(amount_tva + amount_ht).toFixed(2)} €`,
      sorter: true
    }
  ];
};

export default useColumns;
