import React, { useCallback, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Tabs, Skeleton, Typography, Row, Col, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  WarningOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import CustomFilesSpace from './customFilesSpace';
import CompaniesTable from './CompaniesTable';
import InvoicesTable from './InvoicesTable';
import CommentCard from '../../components/CommentCard/CommentCard';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import ContentInformation from '../../components/ContentInformation/ContentInformation';
import { DescriptionList } from '../../components';
import useListContent from './listContent';

const styles = {
  tabTitle: {
    textTransform: 'uppercase',
    fontSize: '0.8rem'
  }
};

const { Title } = Typography;
const { TabPane } = Tabs;

const ShowHolding = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [holding, setHolding] = useState({});
  const [visible, setVisible] = useState(false);

  const getHolding = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/holdings/${id}`
      });
      setHolding(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
    setIsLoading(false);
  }, []);

  const handleOk = () => {
    setVisible(false);
    getHolding();
  };

  const updateData = async values => {
    try {
      await dispatchAPI('PATCH', {
        url: `/holdings/${id}`,
        body: values
      });
      handleOk();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    getHolding();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom
        title={t('holdings.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.HOLDINGS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <DescriptionList
            title="Informations"
            data={useListContent(holding)}
            avatar={holding && holding.logo}
            hasAvatar
            translate
          />
        </Skeleton>
      </ContentInformation>
      <ContentCustom>
        <Tabs>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('holdings.show.companies')}
              </Title>
            }
            key="1"
          >
            <CompaniesTable />
          </TabPane>
          <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('holdings.show.invoices')}
              </Title>
            }
            key="2"
          >
            <InvoicesTable />
          </TabPane>
          {/* <TabPane
            tab={
              <Title style={styles.tabTitle}>
                {t('holdings.show.histories')}
              </Title>
            }
            key="3"
          /> */}
        </Tabs>
      </ContentCustom>
      <Row>
        <Col span={20}>
          <ContentCustom>
            <Title
              style={{
                paddingLeft: 24,
                textTransform: 'uppercase',
                fontSize: '1rem'
              }}
            >
              Documents
            </Title>
            <CustomFilesSpace
              id={id}
              files={holding.proofs}
              forceRefresh={refresh}
              setForceRefresh={setRefresh}
              resource="holdings"
            />
          </ContentCustom>
        </Col>
        <Col
          span={4}
          style={{
            padding: '24px 24px 16px 0px',
            marginTop: 2,
            background: 'var(--componentBackground)',
            display: 'flex'
          }}
        >
          <CommentCard
            visible={visible}
            setVisible={setVisible}
            updateData={updateData}
            data={holding}
          />
        </Col>
      </Row>
    </>
  );
};

export default ShowHolding;
