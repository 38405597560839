import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import headers from './headers';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const { Option } = Select;

const ListCompanies = () => {
  const columns = useColumns();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [holdings, setHoldings] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [extraQuery, setExtraQuery] = useState(null);

  const getHoldings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/holdings'
      });
      setHoldings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const onChangeHolding = value => {
    setExtraQuery(value);
    setRefresh(!refresh);
  };

  useEffect(() => {
    (async () => {
      await getHoldings();
    })();
  }, []);

  const holdingSelection = (
    <Select
      onChange={onChangeHolding}
      placeholder="Holding"
      allowClear
      style={{ marginRight: '16px', width: '150px' }}
    >
      {holdings.map(({ _id, name }) => (
        <Option key={_id} value={_id}>
          {name}
        </Option>
      ))}
    </Select>
  );

  return (
    <SimpleResourceLandingLayout
      resourceName="companies"
      columns={columns}
      headers={headers}
      extraQuery={extraQuery && `holding=${extraQuery}`}
      resourceModelName="Company"
      populate="buildings,holding,manager"
      sortDefault="name"
      withUploadButton
      extraButtons={holdingSelection}
      forceRefresh={refresh}
      scroll={{ x: 1000 }}
    />
  );
};

export default ListCompanies;
