import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

const SubExpensesTable = ({ expenses, t }) => {
  const columns = [
    {
      title: t('settlements.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      sorter: true
    },
    {
      title: t('settlements.columns.date'),
      dataIndex: 'date',
      key: 'date',
      render: date => moment(date).format('L')
    },
    {
      title: t('settlements.columns.category'),
      dataIndex: 'category',
      key: 'category'
    },
    {
      key: 'action',
      render: () => {
        return (
          <DownloadOutlined
            style={{ color: 'green', fontSize: 18, cursor: 'pointer' }}
          />
        );
      }
    }
  ];

  return <Table dataSource={expenses} columns={columns} scroll={{ x: 1000 }} />;
};

export default SubExpensesTable;
