import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('holdings.columns.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('holdings.columns.address_hq'),
      key: 'address_hq',
      dataIndex: 'address_hq',
      sorter: true
    },
    {
      title: t('holdings.columns.number_tva'),
      key: 'number_tva',
      dataIndex: 'number_tva',
      sorter: true
    },
    {
      title: t('holdings.columns.manager_name'),
      key: 'manager',
      dataIndex: 'manager',
      render: manager =>
        manager && `${manager?.first_name} ${manager?.last_name}`,
      sorter: true
    },
    {
      title: t('holdings.columns.manager_email'),
      key: 'manager_email',
      dataIndex: 'manager',
      render: manager => manager && manager?.email,
      sorter: true
    },
    {
      title: t('holdings.columns.manager_phone'),
      key: 'manager_phone',
      dataIndex: 'manager',
      render: manager => manager && manager?.phone,
      sorter: true
    }
  ];
};

export default useColumns;
