import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { geocodeByAddress } from 'react-places-autocomplete';
import useFields from './fields';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateCustomer = ({ purpose }) => {
  const form = useRef();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [customerType, setCustomerType] = useState(false);
  const [token, setToken] = useState();
  const [managerType, setManagerType] = useState('');
  const [sirenStatus, setSirenStatus] = useState(false);

  const getToken = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/insee' });
      setToken(data.access_token);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSiretInfo = async code => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/insee/${token}/${code}`
      });
      if (data) setSirenStatus(true);
    } catch (e) {
      if (e.response) message('unknown_siren');
    }
  };

  const handleSiretChange = async event => {
    const { value } = event.target;
    if (token && value.length === 9) {
      await getSiretInfo(value);
    } else {
      setSirenStatus(false);
    }
  };

  const { fields, isFieldsLoading, base64 } = useFields({
    customerType,
    setCustomerType,
    managerType,
    setManagerType,
    handleSiretChange,
    sirenStatus
  });

  const config = {
    onGetResource: {
      setFields: data => {
        setCustomerType(data.type === 'BUSINESS');
        setManagerType(data.manager.type);
        return {
          ...data,
          date_of_birth: moment(data?.date_of_birth)
        };
      }
    },
    onCreateResource: {
      setBody: async data => {
        setCustomerType(data?.type === 'BUSINESS');
        const address_details = await geocodeByAddress(data.hq_address);
        return {
          ...data,
          address_details,
          type: data?.type === 'BUSINESS',
          ...(base64 ? { avatar: base64 } : {})
        };
      }
    },
    onUpdateResource: {
      setBody: async data => {
        const address_details = await geocodeByAddress(data.hq_address);
        return {
          ...data,
          address_details,
          type: data?.type,
          ...(base64 ? { avatar: base64 } : {})
        };
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getToken();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        config={config}
        purpose={purpose}
        ref={form}
        baseUrl="customers"
        resource="customers"
        tradKey="customers"
      />
    </div>
  );
};

CreateUpdateCustomer.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCustomer;
