import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useColumns from '../Lot/columns';
import { SearchAddBar } from '../../utils/constants/customButton';
import { Datatable } from '../../components';

const BuildingsLotsTable = () => {
  const columns = useColumns();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState();

  return (
    <>
      <SearchAddBar
        setSearchValue={setSearchValue}
        addPath={`/lots/create/${id}`}
      />
      <Datatable
        resourceName="lots"
        pathname="lots"
        columns={columns}
        customActionColumn
        populate="building"
        extraQuery={`building=${id}`}
        searchValue={searchValue}
        path="/lots"
      />
    </>
  );
};

export default BuildingsLotsTable;
