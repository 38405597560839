import moment from 'moment';

const formatter = d => {
  let finalData = {};
  if (d && d.managing) {
    finalData = { ...(d.hasOwnProperty('managing') && d.managing) };
    finalData.company = d?.company?.name;
    switch (d && d.managing && d.managing.type) {
      case 'managing':
        if (finalData.hasOwnProperty('levy_date')) {
          finalData.levy_date = moment(finalData.levy_date).format('L');
        } else {
          finalData.levy_date = '';
        }
        if (
          finalData.hasOwnProperty('balances') &&
          finalData.balances.length > 0
        ) {
          finalData.balances.forEach((balance, index) => {
            finalData[`balance${index}`] = balance;
          });
        }
        break;
      case 'managing_loans':
        if (finalData.hasOwnProperty('deadline_date')) {
          finalData.deadline_date = moment(finalData.deadline_date).format('L');
        } else {
          finalData.deadline_date = '';
        }
        if (
          finalData.hasOwnProperty('balances') &&
          finalData.balances.length > 0
        ) {
          finalData.balances.forEach((balance, index) => {
            finalData[`balance${index}`] = balance;
          });
        }
        break;
      case 'managing_taxes_tva':
      case 'managing_taxes_tf':
      case 'managing_taxes_cfe':
      case 'managing_is':
      case 'managing_cp':
      case 'managing_cfe':
        if (
          finalData.hasOwnProperty('balances') &&
          finalData.balances.length > 0
        ) {
          finalData.balances.forEach((balance, index) => {
            finalData[`balance${index}`] = balance;
          });
        }
        break;
      default:
        break;
    }
  }
  delete finalData.managing;
  return finalData;
};

export default formatter;
