import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InterventionStatusTag from './InterventionStatusTag';

const useListContent = ({
  building,
  lot,
  customer,
  demandDate,
  interventionDate,
  status,
  type,
  interventionChoice,
  object
}) => {
  const { t } = useTranslation();

  const content = [
    {
      label: 'interventions.form.intervention_choice',
      content: t(`interventions.show.${interventionChoice}`)
    },
    {
      label: 'interventions.form.type',
      content: t(`interventions.form.types.${type}`)
    },
    {
      label: 'interventions.form.status',
      content: InterventionStatusTag({ status })
    },
    {
      label: 'interventions.form.building',
      content: building && building.name
    },
    {
      label: 'interventions.form.object',
      content: object && t(`interventions.form.objects.${object}`)
    },
    {
      label: 'interventions.form.lot',
      content: lot && lot.reference
    },
    {
      label: 'interventions.form.customer',
      content: customer && customer.name
    },
    {
      label: 'interventions.form.phone',
      content: customer ? customer.email : '-'
    },
    {
      label: 'interventions.form.email',
      content: customer ? customer.email : '-'
    },
    {
      label: 'interventions.form.demandDate',
      content: moment(demandDate).format('DD/MM/YYYY')
    },
    {
      label: 'interventions.form.interventionDate',
      content: moment(interventionDate).format('DD/MM/YYYY')
    }
  ];

  return content;
};

export default useListContent;
