import { useTranslation } from 'react-i18next';
import moment from 'moment';

const new_receipt_value = {};

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('rentReviews.columns.lot_reference'),
      dataIndex: 'lot',
      render: lot => lot && lot.reference
    },
    {
      title: t('rentReviews.columns.customer'),
      dataIndex: 'customer',
      render: customer => customer && customer.name
    },
    {
      title: t('rentReviews.columns.reviewDate'),
      dataIndex: 'review_date',
      render: review_date => moment(review_date).format('DD-MM-YYYY')
    },
    {
      title: t('rentReviews.columns.new_index'),
      dataIndex: 'new_index',
      render: newIndex => newIndex
    },
    {
      title: t('rentReviews.columns.new_receipts'),
      dataIndex: 'rent',
      render: rent => rent
    }
  ];
};

export default useColumns;
