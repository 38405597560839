import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ShowRental from './ShowRental';
import CreateUpdateRental from './CreateUpdateRentals';
import ListRentals from './ListRentals';
import { routes } from '../../utils/constants/routes';

const RentalsRouter = () => {
  return (
    <Switch>
      <Route
        path={`${routes.RENTALS}/create`}
        render={() => <CreateUpdateRental purpose="create" />}
      />
      <Route
        path={`${routes.RENTALS}/edit/:id`}
        render={() => <CreateUpdateRental purpose="edit" />}
      />
      <Route exact path={`${routes.RENTALS}/show/:id`} component={ShowRental} />
      <Route path={routes.RENTALS} component={ListRentals} />
    </Switch>
  );
};

export default RentalsRouter;
