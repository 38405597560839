import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  savedItem,
  index,
  children,
  selectType,
  ...restProps
}) => {
  console.log('🚀 ~ dataIndex', dataIndex);
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {dataIndex === 'amount' ? <Input type="number" /> : <Input />}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CustomTable = ({ datas, onEdit, onDelete }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = ({ _id }) => _id === editingKey;

  const edit = savedItem => {
    form.setFieldsValue({
      label: '',
      ...savedItem
    });
    setEditingKey(savedItem._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async savedItem => {
    try {
      const row = await form.validateFields();
      onEdit(savedItem._id, row);
      setEditingKey('');
    } catch (e) {
      console.log('Validate Failed:', e);
    }
  };

  const columns = [
    {
      title: t('settings.suppliers.label'),
      dataIndex: 'label',
      width: '35%',
      editable: true
    },
    {
      title: t('settings.suppliers.amount'),
      dataIndex: 'amount',
      width: '35%',
      editable: true
    },
    {
      title: t('settings.expenses.table.operation'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, savedItem) => {
        const editable = isEditing(savedItem);
        return editable ? (
          <span>
            <Button onClick={() => save(savedItem)}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(savedItem)}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => onDelete(savedItem)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: savedItem => ({
        savedItem,
        selectType: col.dataIndex === 'show' ? 'show' : '',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(savedItem)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={datas}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default CustomTable;
