import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Typography, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import useFields from './fields';
import interventionUseFields from '../Interventions/fields';
import onValuesChanges from './utils';

const style = {
  modalStyle: {
    height: '600px',
    overflow: 'auto',
    padding: '48px',
    borderRadius: '4px'
  }
};

const { Title } = Typography;
const { TabPane } = Tabs;

const CreateUpdateModal = ({
  purpose,
  tradKey,
  resource,
  visible,
  handleOk,
  handleCancel,
  data,
  selectedValue,
  setResource
}) => {
  const { user, dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [radioValue, setRadioValue] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [disableFields, setDisableFields] = useState({
    building: true,
    lot: true
  });
  const [allFieldsDisabled, setAllFieldsDisabled] = useState(false);
  const [lots, setLots] = useState([]);
  const [building, setBuilding] = useState();
  const [buildings, setBuildings] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [choice, setChoice] = useState('building');
  const [buildingId, setBuildingId] = useState();
  const fields = useFields({
    disableFields,
    radioValue,
    setRadioValue,
    switchValue,
    companies,
    building,
    lots,
    allFieldsDisabled
  });
  const [lot, setLot] = useState();

  const handleChoiceChange = e => {
    setChoice(e.target.value);
  };

  const interventionFields = interventionUseFields({
    building: null,
    filteredLots: lots,
    setFilteredLots: setLots,
    lot,
    setLot,
    choice,
    handleChoiceChange,
    purpose,
    buildings,
    documents,
    setDocuments,
    id: data?._id
  });

  const getBuildingAndLots = async companyID => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/buildings?company=${companyID}`
      });
      setBuilding(response.data);
      setLots(response.data[0].lots);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const response = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(response.data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getBuildings = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: '/buildings?populate=lots'
      });
      setBuildings(response.data);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const onValuesChange = (_, values) => {
    onValuesChanges({
      values,
      setSwitchValue,
      form,
      setDisableFields,
      companyId,
      setBuildingId,
      setCompanyId,
      disableFields
    });
  };

  const updateAgenda = async values => {
    try {
      await dispatchAPI('PATCH', {
        url: `/agendas/${data._id}`,
        body: {
          ...values,
          company: values?.company,
          building: values?.building,
          lot: values?.lot
        }
      });
      handleOk();
      setAllFieldsDisabled(false);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const createAgenda = async values => {
    try {
      if (!radioValue) {
        values.common_events = false;
      }
      await dispatchAPI('POST', {
        url: '/agendas',
        body: { ...values, user: user._id }
      });
      handleOk();
      setAllFieldsDisabled(false);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const updateIntervention = async values => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interventions/${data._id}`,
        body: values
      });
      handleOk();
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const createIntervention = async values => {
    try {
      await dispatchAPI('POST', {
        url: '/interventions',
        body: { ...values }
      });
      handleOk();
    } catch (err) {
      if (err.response) message(err.response.response.status);
    }
  };

  const deleteAgenda = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/agendas/${data._id}` });
      handleOk();
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const setFieldsValues = async () => {
    if (data && data?.resource === 'agenda') {
      setCompanyId(data.company[0]?._id);
      setSwitchValue(data.reminder);
      form.setFieldsValue({
        ...data,
        date: moment(data.date),
        schedule: (data.schedule || []).map(item => moment(item)),
        company: data.company && data.company[0]?._id,
        building: data.building && data.building[0]?._id,
        lot: data.lot && data.lot[0]?._id,
        reminder: data.reminder
      });
      if (data && data.company && !data.building) {
        setDisableFields({ ...disableFields, building: true, lot: true });
      }
      if (data && data.building) {
        setDisableFields({ ...disableFields, building: false, lot: false });
      }
      if (data && user && data.user && data.user[0]._id !== user._id) {
        setAllFieldsDisabled(true);
      }
    } else if (data && data?.resource === 'intervention') {
      setDocuments(data.files);
      if (data.interventionChoice === 'building') {
        form2.setFieldsValue({
          ...data,
          building: data.building[0]._id,
          customer: data.customer[0]._id,
          interventionChoice: data.interventionChoice,
          demandDate: moment(data.demandDate),
          interventionDate: moment(data.interventionDate)
        });
      } else if (data.interventionChoice === 'lot') {
        form2.setFieldsValue({
          ...data,
          interventionChoice: data.interventionChoice,
          building: data.building[0]._id,
          customer: data.customer[0]._id,
          lot: data.lot[0]._id,
          demandDate: moment(data.demandDate),
          interventionDate: moment(data.interventionDate)
        });
      }
    }
  };

  useEffect(() => {
    if (purpose === 'update' && data) {
      setFieldsValues();
    }
    if (!data) {
      form.resetFields();
      form2.resetFields();
      setAllFieldsDisabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (purpose === 'create') {
      form.setFieldsValue({
        date: selectedValue
      });
      setAllFieldsDisabled(false);
    }
  }, [selectedValue]);

  useEffect(() => {
    (async () => {
      await getCompanies();
      await getBuildings();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (companyId) {
        await getBuildingAndLots(companyId);
      }
    })();
  }, [companyId]);

  const handleSubmit = async values => {
    if (purpose === 'update') {
      if (resource === 'agendas') {
        await updateAgenda(values);
      } else if (resource === 'interventions') {
        await updateIntervention(values);
      }
    }
    if (purpose === 'create') {
      if (resource === 'agendas') {
        await createAgenda(values);
      } else if (resource === 'interventions') {
        await createIntervention(values);
      }
    }
  };

  const handleOnOk = async () => {
    try {
      if (resource === 'agendas') {
        const values = await form.validateFields();
        form.resetFields();
        handleSubmit(values);
      } else if (resource === 'interventions') {
        const values = await form2.validateFields();
        form2.resetFields();
        handleSubmit(values);
      }
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const title = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Title
        level={4}
        style={{
          margin: 'auto',
          fontFamily: 'Pretty Queen'
        }}
      >
        {t(`agendas.form.title.${purpose}`)}
      </Title>
    </div>
  );

  const handleOnCancel = () => {
    if (resource === 'agendas') {
      form.resetFields();
      setDisableFields({
        ...disableFields,
        building: true,
        lot: true
      });
    } else if (resource === 'interventions') {
      form2.resetFields();
    }
    handleCancel();
  };

  const handleOnTabPaneChange = key => {
    if (key === '1') {
      setResource('agendas');
    } else if (key === '2') {
      setResource('interventions');
    }
  };

  return (
    <>
      <Modal
        title={title}
        destroyOnClose
        onCancel={handleCancel}
        visible={visible}
        bodyStyle={style.modalStyle}
        width="40%"
        centered
        okText="Sauvegarder"
        footer={
          <div
            style={
              purpose === 'update'
                ? {
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0 24px'
                  }
                : {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '0 24px'
                  }
            }
          >
            {purpose === 'update' && (
              <span>
                <Button
                  danger
                  style={{
                    margin: 0,
                    border: '6px none',
                    color: 'white'
                  }}
                  onClick={() => deleteAgenda()}
                  disabled={
                    data &&
                    user &&
                    data.user &&
                    data.user[0] &&
                    data.user[0]._id !== user._id
                  }
                >
                  Supprimer
                </Button>
              </span>
            )}
            <span>
              <Button
                style={{
                  borderRadius: '6px',
                  margin: '0 4px'
                }}
                onClick={() => {
                  handleOnCancel();
                }}
              >
                Annuler
              </Button>
              <Button
                style={{
                  borderRadius: '6px',
                  borderBlock: 'none',
                  background: 'var(--primaryColor)',
                  color: 'white'
                }}
                disabled={
                  data && user && data.user && data.user[0]._id !== user._id
                }
                htmlType="submit"
                onClick={handleOnOk}
              >
                Sauvegarder
              </Button>
            </span>
          </div>
        }
      >
        <Tabs
          type="card"
          centered
          onChange={handleOnTabPaneChange}
          activeKey={
            data
              ? data?.resource === 'agenda'
                ? '1'
                : '2'
              : resource === 'agendas'
              ? '1'
              : '2'
          }
        >
          <TabPane
            tab="Evènement"
            key="1"
            disabled={data && data?.resource === 'intervention'}
          >
            <Form
              form={form}
              onFinish={handleSubmit}
              onValuesChange={onValuesChange}
              layout="vertical"
            >
              {fields.map(field => generateFields(tradKey || resource, field))}
            </Form>
          </TabPane>
          <TabPane
            tab="Intervention"
            key="2"
            disabled={data && data?.resource === 'agenda'}
          >
            <Form form={form2} onFinish={handleSubmit} layout="vertical">
              {interventionFields.fields.map(field =>
                generateFields(tradKey || resource, field)
              )}
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

CreateUpdateModal.propTypes = {
  purpose: PropTypes.string,
  tradKey: PropTypes.string,
  resource: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string,
    schedule: PropTypes.array,
    building: PropTypes.object,
    lot: PropTypes.object,
    reminder: PropTypes.bool,
    user: PropTypes.object,
    files: PropTypes.array,
    interventionChoice: PropTypes.string,
    customer: PropTypes.array,
    resource: PropTypes.string,
    demandDate: PropTypes.object,
    interventionDate: PropTypes.object,
    company: PropTypes.array
  }),
  selectedValue: PropTypes.shape({}),
  setResource: PropTypes.func.isRequired
};

CreateUpdateModal.defaultProps = {
  selectedValue: moment(),
  tradKey: null,
  data: null,
  visible: false,
  purpose: null
};

export default CreateUpdateModal;
