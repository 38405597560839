import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components';
import useColumns from '../Receipts/columns';

const RentalReceiptsTable = () => {
  const { t } = useTranslation();
  const columns = useColumns(t, 'receipts');
  const { id } = useParams();

  return (
    <Datatable
      resourceName="receipts"
      columns={columns}
      populate="rental,building,lot,customer"
      extraQuery={`rental=${id}`}
      customActionColumn
      path="/receipts"
    />
  );
};

export default RentalReceiptsTable;
