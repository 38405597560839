import React, { createContext, useContext, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useStateWithLocalStorage } from '../utils';

const AuthContext = createContext({});
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [, setRememberMe] = useStateWithLocalStorage('remember_me');
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN',
    permission: {
      grant: [],
      permission_label: ''
    }
  });
  const [token, setToken] = useStateWithLocalStorage('token');
  const [isValid, setIsValid] = useState(false);
  const [isGranted, setIsGranted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setSession = accessToken => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  const loginAPI = async (email, password, remember) => {
    try {
      const result = await axiosInstance.post('/login', {
        email,
        password
      });
      setUser(result.data.user);
      setRememberMe(remember);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const googleLoginAPI = async code => {
    try {
      const result = await axiosInstance.post('/auth2/google', { code });
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const facebookLoginAPI = async options => {
    try {
      const result = await axiosInstance.post('/auth2/facebook', { options });
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const twitterLoginAPI = async options => {
    try {
      const result = await axiosInstance.post('/auth2/twitter', options);
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const linkedinLoginAPI = async options => {
    try {
      const result = await axiosInstance.post('/auth2/linkedin', options);
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const registerAPI = async values => {
    try {
      return await axiosInstance.post('/register', values);
    } catch (e) {
      return throw e;
    }
  };

  const logout = () => {
    setSession(null);
    setUser(null);
  };

  const isTokenValid = () => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        message.warn(t('login.expiredSession'));
        setSession(null);
        return false;
      }
    } catch (e) {
      message.warn(t('login.tokenError'));
      setSession(null);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  const checkIsGranted = requestPath => {
    const matchRuleShort = (str, rule) => {
      const escapeRegex = str =>
        str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
      return new RegExp(
        `^${rule
          .split('.*')
          .map(escapeRegex)
          .join('.*')}$`
      ).test(str);
    };

    const { length } = requestPath.split('.');

    if (!user.permission) {
      // handle users wihtout permission field
      return true;
    }
    const grantedArray = [
      ...user?.permission.grant,
      'settings',
      'login',
      'tink',
      '',
      '.'
    ].map(v => v.toLowerCase());

    for (let index = 0; index < length; index = +1) {
      const array = requestPath.split('.');
      if (length === 1) {
        // allow top
        if (grantedArray.some(item => item.split('.')[0] === requestPath))
          return true;
      }
      if (index === 0) {
        if (
          grantedArray.some(item => {
            return matchRuleShort(array.join('.'), item);
          })
        )
          return true;
      } else {
        if (grantedArray.some(item => item === array.join('.'))) return true;
        if (
          grantedArray.some(item => {
            return matchRuleShort(array.join('.'), item);
          })
        )
          return true;
      }

      return false;
    }
  };

  const checkShouldDisplayMenuItem = requestedItem => {
    // console.log(requestedItem);
    if (!user.permission) {
      // handle user without permission field
      return true;
    }
    //  handle navmenu agenda
    if (
      [
        ...user?.permission.grant,
        'settings',
        'login',
        'home',
        'quotes',
        '',
        '.'
      ]
        .map(perm => perm.split('.')[0].toLowerCase())
        .includes(requestedItem)
    ) {
      return true;
    }
    if (
      [
        ...user?.permission.grant,
        'settings',
        'login',
        'home',
        'quotes',
        '',
        '.'
      ]
        .map(perm => perm.split('.')[1]?.toLowerCase())
        .includes(requestedItem)
    ) {
      return true;
    }
  };

  isTokenValid();

  const fetchAPI = async (
    url,
    method = 'GET',
    body = null,
    responseType = 'json',
    cancelToken
  ) => {
    try {
      isTokenValid();
      setIsLoading(true);
      const result = await axiosInstance({
        url,
        method,
        responseType,
        cancelToken,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsLoading(false);
      return throw e;
    }
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'GOOGLE_LOGIN':
        return googleLoginAPI(options.code);
      case 'FACEBOOK_LOGIN':
        return facebookLoginAPI(options);
      case 'TWITTER_LOGIN':
        return twitterLoginAPI(options);
      case 'LINKEDIN_LOGIN':
        return linkedinLoginAPI(options);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        return throw new Error('Unknown dispatchAPI type!');
    }
  };

  const onReconciled = async (id, collection, is_reconciled, callback) => {
    const body = { is_reconciled };
    try {
      await dispatchAPI('PATCH', {
        url: `/${collection}/${id}`,
        body
      });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onPartialReconciled = async (
    id,
    collection,
    is_reconciled,
    callback,
    subId
  ) => {
    const body = { is_reconciled, id: subId, type: 'sub' };
    try {
      await dispatchAPI('PATCH', {
        url: `/${collection}/${id}`,
        body
      });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        isValid,
        isGranted,
        dispatchAPI,
        checkIsGranted,
        checkShouldDisplayMenuItem,
        isLoading,
        onReconciled,
        onPartialReconciled
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default () => useContext(AuthContext);
