import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Select, Typography, Dropdown, Menu } from 'antd';
import {
  FlagOutlined,
  CheckOutlined,
  DashOutlined,
  MoreOutlined,
  SwapOutlined,
  FileOutlined,
  TagOutlined,
  TagsOutlined,
  CopyOutlined,
  PlusCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';

const { Text } = Typography;
const { Option } = Select;

const useColumns = ({
  datasToReconcile,
  dispatchAPI,
  id,
  setVisible,
  setReconciliationLabel,
  dataToModal,
  setDataToModal,
  setDataCanBeReconciled,
  getTransactions,
  account,
  message
}) => {
  const { t } = useTranslation();
  moment.locale('fr');
  const handleSuggestReconciliation = async (body, itemId) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/expenses/set/reconciliation/${itemId}`,
        body
      });
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const handleCancelReconciliation = async item => {
    try {
      await dispatchAPI('PATCH', {
        url: `/expenses/cancel-reconciliation`,
        body: { transactionIds: item.dataId }
      });
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const filterDataToReconcile = (code, extraCode) => {
    let dataSFiltered;
    switch (code) {
      case 'PP':
        if (extraCode === 'D') {
          dataSFiltered = datasToReconcile.filter(
            d => d.amount >= dataToModal.transactionAmount.amount
          );
        } else if (extraCode === 'F') {
          dataSFiltered = datasToReconcile.filter(
            d =>
              d.amount >= 0 && d.amount >= dataToModal.transactionAmount.amount
          );
        }
        break;
      case 'RP':
        if (extraCode === 'D') {
          dataSFiltered = datasToReconcile.filter(
            d =>
              d.amount < 0 && d.amount <= dataToModal.transactionAmount.amount
          );
        } else if (extraCode === 'F') {
          dataSFiltered = datasToReconcile.filter(
            d =>
              d.amount >= 0 && d.amount <= dataToModal.transactionAmount.amount
          );
        }
        break;
      default:
        break;
    }
    setDataCanBeReconciled(dataSFiltered);
  };

  const reconciliationOffActionsMenu = () => {
    let labels = [
      { name: 'Créer une dépense', code: 'CR' },
      {
        name: 'Dépense partiellement payée',
        code: 'DP',
        extraCode: 'D'
      },
      {
        name: 'Rapprochement de plusieurs dépenses',
        code: 'RP',
        extraCode: 'D'
      },
      {
        name: 'Rapprochement complexe',
        code: 'RC'
      }
    ];
    let titles = [
      'Créer une dépense',
      'Dépense partiellement payée',
      'Rapprochement de plusieurs dépenses',
      'Rapprochement complexe'
    ];
    let icons = [
      <PlusCircleOutlined />,
      <TagOutlined />,
      <TagsOutlined />,
      <SettingOutlined />
    ];
    if (
      dataToModal &&
      dataToModal.transactionAmount &&
      dataToModal.transactionAmount.amount >= 0
    ) {
      labels = [
        { name: 'Créer une facture', code: 'CF' },
        {
          name: 'Facture partiellement payée',
          code: 'PP',
          extraCode: 'F'
        },
        {
          name: 'Rapprochement de plusieurs factures',
          code: 'RP',
          extraCode: 'F'
        },
        {
          name: 'Rapprochement complexe',
          code: 'RC'
        }
      ];
      titles = [
        'Créer une facture',
        'Facture partiellement payée',
        'Rapprochement de plusieurs factures',
        'Rapprochement complexe'
      ];
      icons = [
        <PlusCircleOutlined />,
        <FileOutlined />,
        <CopyOutlined />,
        <SettingOutlined />
      ];
    }
    return (
      <Menu>
        <Menu.Item key="1">
          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setReconciliationLabel(labels[0]);
            }}
          >
            <span style={{ marginRight: '8px' }}>{icons[0]}</span>
            <span>{titles[0]}</span>
          </Button>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setReconciliationLabel(labels[1]);
              filterDataToReconcile(labels[1].code, labels[1].extraCode);
            }}
          >
            <span style={{ marginRight: '8px' }}>{icons[1]}</span>
            <span>{titles[1]}</span>
          </Button>
        </Menu.Item>
        <Menu.Item key="3">
          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setReconciliationLabel(labels[2]);
              filterDataToReconcile(labels[2].code, labels[2].extraCode);
            }}
          >
            <span style={{ marginRight: '8px' }}>{icons[2]}</span>
            <span>{titles[2]}</span>
          </Button>
        </Menu.Item>
        <Menu.Item key="4">
          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setReconciliationLabel(labels[3]);
            }}
          >
            <span style={{ marginRight: '8px' }}>{icons[3]}</span>
            <span>{titles[3]}</span>
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const reconciliationOnActionsMenu = item => {
    return (
      <Menu>
        <Menu.Item>
          <Button
            type="link"
            onClick={() => {
              handleCancelReconciliation(item);
              getTransactions();
            }}
          >
            Annuler
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  return [
    {
      title: 'Statut',
      render: data => (
        <Button type="link">
          {!data?.reconciliation?.total_reconciled &&
          !data?.reconciliation?.partiallly_reconciled ? (
            <FlagOutlined
              style={{ fontWeight: '600', fontSize: '16px', color: '#CB4335' }}
            />
          ) : (
            <CheckOutlined
              style={{ fontWeight: '600', fontSize: '16px', color: '#229954' }}
            />
          )}
        </Button>
      )
    },
    {
      title: 'Compte',
      render: () => account && account.institution_id.split('_')[0]
    },
    {
      title: "Date d'opération",
      key: 'valueDate',
      dataIndex: 'valueDate',
      render: valueDate => valueDate
    },
    {
      title: 'Moyen',
      key: 'method',
      render: data =>
        data.remittanceInformationUnstructuredArray[0].split('\n')[0]
    },
    {
      title: 'Libellé',
      key: 'label',
      render: data =>
        data.remittanceInformationUnstructuredArray[0].split('\n')[2]
    },
    {
      title: 'Montant',
      key: 'amount',
      render: data =>
        Number(data.transactionAmount.amount) > 0 ? (
          <Text style={{ color: 'green' }}>
            {data.transactionAmount.amount}
          </Text>
        ) : (
          <Text style={{ color: 'red' }}>{data.transactionAmount.amount}</Text>
        )
    },
    {
      key: 'action',
      render: data => {
        if (data?.reconciliation?.total_reconciled) {
          return <Text>Totalement reconcilié</Text>;
        }
        if (data?.reconciliation?.partially_reconciled) {
          return (
            <Text>{`A reconcilié partiellement ${data && data.label}`}</Text>
          );
        }
        const result = datasToReconcile.filter(
          d => Number(d?.amount) === Number(data?.transactionAmount?.amount)
        );
        if (result.length === 1) {
          return (
            <div>
              <Button
                style={{
                  backgroundColor: '#E14068',
                  marginRight: '8px',
                  color: 'white'
                }}
                type="link"
                width={16}
                height={16}
                onClick={() => {
                  handleSuggestReconciliation(
                    {
                      reconciliation: {
                        partiallly_reconciled: false,
                        total_reconciled: true,
                        payments: [
                          {
                            transaction_id: data.transactionId,
                            date: moment(),
                            amount_paid: data.amount
                          }
                        ]
                      }
                    },
                    result[0]._id
                  );
                  getTransactions();
                }}
              >
                <SwapOutlined />
              </Button>
              <span>{`Rapproché avec ${result[0]?.wording}`}</span>
            </div>
          );
        }
        if (result.length > 1) {
          return (
            <Select style={{ width: '100%' }}>
              {result.map(r => (
                <Option value={r._id}>{r.wording}</Option>
              ))}
            </Select>
          );
        }
        if (data.transactionAmount.amount >= 0) {
          return (
            <Button
              type="dashed"
              style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '24px',
                borderColor: 'blue'
              }}
            >
              Créer une facture
            </Button>
          );
        }
        return (
          <Button
            type="dashed"
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '24px',
              borderColor: 'orange'
            }}
          >
            Créer une dépense
          </Button>
        );
      }
    },
    {
      key: 'action',
      align: 'right',
      render: record => (
        <Dropdown
          destroyPopupOnHide
          overlay={
            record?.reconciliation?.total_reconciled
              ? reconciliationOnActionsMenu(record)
              : reconciliationOffActionsMenu(record)
          }
          placement="bottomLeft"
          trigger={['click']}
        >
          <Button
            type="link"
            onClick={() => {
              setDataToModal(record);
            }}
          >
            <MoreOutlined style={{ fontWeight: '600', fontSize: '16px' }} />
          </Button>
        </Dropdown>
      )
    }
  ];
};

export default useColumns;
