import React, { useCallback, useEffect, useState } from 'react';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import {
  Col,
  Row,
  Skeleton,
  Typography,
  Table,
  Button,
  Divider,
  Progress,
  Popconfirm,
  Tag,
  Layout,
  PageHeader
} from 'antd';
import numeral from 'numeral';
import { EditOutlined, FileAddOutlined } from '@ant-design/icons/lib';
import NextPrevButtons from '../Invoices/NextPrevButtons';
import {
  DeleteOutlined, DownloadOutlined,
  EyeOutlined, LoadingOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { invoiceTypes } from '../../../utils/constants/tagColors';
import DownloadGeneratedFile from '../Invoices/DownloadGeneratedFile';

const { Title } = Typography;
const { Column } = Table;
const { Content } = Layout;

const iconSize = 18;

const ShowOrder = () => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [settings, setSettings] = useState([]);
  const [remaining, setRemainingToInvoice] = useState(0);
  const [selectedRowKeys, setSelected] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});

  const createInvoice = async body => {
    try {
      await dispatchAPI('POST', { url: '/invoices', body });
      await fetchOrder();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteInvoice = async id => {
    try {
      await dispatchAPI('DELETE', { url: `/invoices/${id}` });
      await fetchOrder();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchOrder = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/orders/${id}?populate=customer,contact,quotation,invoices`
      });
      setOrder(data);
      setRemainingToInvoice(getRemainingToInvoice(data));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchSettings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?type=ACCOUNTING'
      });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const generateInvoice = async (articles = []) => {
    const deadline_date = moment().add(
      settings.find(setting => setting.label === 'default_invoice_deadline')
        .value,
      'days'
    );
    let invoice = {
      ...order.quotation,
      order: order._id,
      type: 'PAY',
      deadline_date
    };

    if (articles.length > 0) {
      let discount = 0;
      let total = 0;
      let total_quantity = 0;
      let subTotal = 0;
      let total_VAT = 0;

      articles.forEach(article => {
        if (article.discount) {
          discount +=
            article.discount && article.discount.type === 'PERCENT'
              ? article.quantity *
                article.unit_price *
                (100 - article.discount.value)
              : article.discount.value;
        }
        total_quantity += article.quantity;
        subTotal += article.quantity * article.unit_price;
        total_VAT +=
          article.quantity * ((article.unit_price * article.VAT) / 100);
        total += article.lineTotal;
      });

      invoice = {
        ...invoice,
        articles,
        total_quantity,
        subTotal,
        total_VAT,
        total_discount: discount,
        total
      };
    }

    const { _id, ...formattedInvoice } = invoice;
    setSelected([]);

    if (invoice.total.toFixed(2) > remaining) {
      return message('invoice_too_big');
    }

    await createInvoice(formattedInvoice);
  };

  const downloadFile = async (id, name, template) => {
    try {
      const response = await dispatchAPI('GET', { url: `/files/generate/invoice/${id}/${template}`, responseType: 'blob' });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const getOrder = useCallback(async () => {
    setLoading(true);
    await fetchOrder();
    await fetchSettings();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getOrder();
  }, [getOrder, id]);

  const getRemainingToInvoice = order => {
    const orderTotal = order.quotation.total;
    let invoiced = 0;

    if (order.invoices && order.invoices.length > 0) {
      order.invoices.forEach(invoice => {
        invoiced += invoice.total;
      });
    }

    return orderTotal - invoiced;
  };

  const expandedRowRender = () => {
    const columns = [
      { title: t('orders.show.table.article'), dataIndex: 'name', key: 'name' },
      {
        title: t('orders.show.table.quantity'),
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: t('orders.show.table.unit_price'),
        dataIndex: 'unit_price',
        key: 'unit_price',
        render: price => `${numeral(price).format('0,0.00')}€`
      },
      {
        title: t('orders.show.table.VAT'),
        dataIndex: 'VAT',
        key: 'VAT',
        render: VAT => `${VAT}%`
      },
      {
        title: t('orders.show.table.discount'),
        dataIndex: 'discount',
        key: 'discount',
        render: discount => {
          if (discount) {
            return discount.type === 'PERCENT'
              ? `${discount.value}%`
              : `${numeral(discount.value).format('0,0.00')}€ HT`;
          }
          return 'N/A';
        }
      },
      {
        title: t('orders.show.table.subtotal'),
        dataIndex: 'subtotal',
        key: 'subtotal',
        render: price => `${numeral(price).format('0,0.00')}€`
      },
      {
        title: t('orders.show.table.lineTotal'),
        dataIndex: 'lineTotal',
        key: 'lineTotal',
        render: price => `${numeral(price).format('0,0.00')}€`
      }
    ];

    const onSelectChange = keys => {
      setSelected(keys);
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange
    };

    const data = [];
    order.quotation.articles.map(element => {
      const lineTotal =
        element.subtotal +
        ((element.unit_price * element.VAT) / 100) * element.quantity;
      element.lineTotal = lineTotal;
      data.push({
        key: element,
        ...element,
        lineTotal,
        invoice: { ...element, lineTotal }
      });
    });
    return (
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  return (
    <>
      <PageHeaderCustom
        title={t('orders.show.number', {
          number: order.number
        })}
        extra={!isLoading && (
          <>
            <DownloadGeneratedFile collection="orders" element={order} />
            <Link
              to={{ pathname: `/orders/edit/${id}` }}
              style={{ float: 'right' }}
            >
              <Button type="primary" icon={<EditOutlined />}>
                {t('buttons.edit')}
              </Button>
            </Link>
          </>
        )}
      />

      <ContentCustom>
        <NextPrevButtons
          resource={'orders'}
          emissionDate={order.emission_date}
        />

        <Row style={{ marginTop: '50px', marginBottom: '30px' }}>
          <Col span={6} style={{ paddingRight: '10px' }}>
            <div
              style={{
                backgroundColor: 'var(--contrastBackground)',
                padding: '10px',
                minHeight: '150px'
              }}
            >
              <Skeleton
                loading={isLoading}
                paragraph={{ rows: 1, width: '50%' }}
              >
                {order.customer && (
                  <>
                    <Title
                      level={5}
                      style={{ marginBottom: '0', color: 'lightgrey' }}
                    >
                      {t('orders.show.customer')}
                    </Title>
                    <Title level={3} style={{ marginTop: '0' }}>
                      <Link to={`/companies/show/${order.customer._id}`}>
                        {order.customer.name}
                      </Link>
                    </Title>
                    {order.customer.channel_number}{' '}
                    {order.customer.address_details}
                    <br />
                    {order.customer.postal_code} {order.customer.city}
                  </>
                )}
              </Skeleton>
            </div>
          </Col>
          <Col span={6} style={{ paddingRight: '10px' }}>
            <div
              style={{
                backgroundColor: 'var(--contrastBackground)',
                padding: '10px',
                minHeight: '150px'
              }}
            >
              <Skeleton
                loading={isLoading}
                paragraph={{ rows: 1, width: '50%' }}
              >
                {order.contact && (
                  <>
                    <Title
                      level={5}
                      style={{ marginBottom: '0', color: 'lightgrey' }}
                    >
                      {t('orders.show.contact')}
                    </Title>
                    <Title level={3} style={{ marginTop: '0' }}>
                      <Link to={`/contacts/show/${order.contact._id}`}>
                        {`${order.contact.first_name} ${order.contact.last_name}`}
                      </Link>
                    </Title>
                    {order.contact.email}
                    <br />
                    {order.contact.phone_number1}
                  </>
                )}
              </Skeleton>
            </div>
          </Col>
          <Col span={6} style={{ paddingRight: '10px' }}>
            <div
              style={{
                border: '1px solid lightgrey',
                padding: '10px',
                minHeight: '150px'
              }}
            >
              <Skeleton
                loading={isLoading}
                paragraph={{ rows: 1, width: '50%' }}
              >
                {order.quotation && (
                  <>
                    <Title
                      level={5}
                      style={{ marginBottom: '0', color: 'lightgrey' }}
                    >
                      {t('orders.show.invoicing')}
                    </Title>
                    <Title level={4} style={{ marginTop: '0' }}>
                      {t('orders.show.remaining', {
                        value: `${numeral(remaining < 0 ? 0 : remaining).format('0,0.00')}€`
                      })}
                    </Title>
                    <Progress
                      type="circle"
                      percent={parseInt(
                        ((order.quotation.total - remaining) * 100) /
                          order.quotation.total
                      )}
                      width={65}
                    />
                  </>
                )}
              </Skeleton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Title level={3}>
              {t('orders.show.title')}&nbsp;{order.subject}
            </Title>
            {order.comment && (
              <i>
                {t('orders.show.comment')}&nbsp;{order.comment}
              </i>
            )}
            <br />
            <p>
              {t('orders.show.emission', {
                date: moment(order.emission_date).format('LL')
              })}
              <br />
              {order.reception_date &&
                t('orders.show.reception', {
                  date: moment(order.reception_date).format('LL')
                })}
            </p>
          </Col>
        </Row>
      </ContentCustom>
      <Divider />
      <PageHeader
        title={t('orders.show.quotations')}
        ghost={false}
        extra={[
          <>
            <Popconfirm
              title={
                selectedRowKeys.length === 0
                  ? t('orders.show.table.action.invoiceConfirm')
                  : t('orders.show.table.action.lineConfirm')
              }
              okText={t('orders.show.table.action.ok')}
              okButtonProps={{ type: 'primary' }}
              cancelText={t('orders.show.table.action.cancel')}
              onConfirm={async () => {
                await generateInvoice(selectedRowKeys);
              }}
              placement="left"
              icon={<WarningOutlined />}
              disabled={remaining === 0}
            >
              <Button
                type="primary"
                icon={<FileAddOutlined />}
                style={{ float: 'right' }}
                disabled={remaining === 0}
              >
                {t('orders.show.table.action.invoice')}
              </Button>
            </Popconfirm>
            {selectedRowKeys.length > 0 && (
              <div style={{ float: 'right', marginRight: 10, marginTop: 5 }}>
                {t('orders.show.table.action.linesCount', {
                  count: selectedRowKeys.length
                })}
              </div>
            )}
          </>
        ]}
      />
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Skeleton
          active
          title={false}
          loading={isLoading}
          paragraph={{ rows: 2 }}
        >
          <Table
            dataSource={[order.quotation]}
            pagination={false}
            expandable={{ expandedRowRender }}
          >
            <Column title={t('orders.show.table.number')} dataIndex="number" />
            <Column
              title={t('orders.show.table.subject')}
              dataIndex="subject"
            />
            <Column
              title={t('orders.show.table.global_discount')}
              dataIndex="discount"
              render={discount => {
                if (discount) {
                  return discount.type === 'PERCENT'
                    ? `${discount.value}%`
                    : `${numeral(discount.value).format('0,0.00')}€ HT`;
                }
                return 'N/A';
              }}
            />
            <Column
              title={t('orders.show.table.total')}
              dataIndex="total"
              render={total => `${numeral(total).format('0,0.00')}€`}
            />
            <Column
              render={(element) => element && (
                <>
                  <Link
                    to={{
                      pathname: `/quotations/show/${element._id}`
                    }}
                  >
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Link>
                  {settings.find(s => s.label === 'template_quotations').value && (
                    <>
                      <Divider type="vertical" />
                      <Button style={{ padding: 0 }} type="link" onClick={async () => {
                        if (!isDownloading[element._id]) {
                          setIsDownloading({ ...isDownloading, [element._id]: true });
                          await downloadFile(element._id, `${element.number}.pdf`, settings.find(s => s.label === 'template_quotations').value);
                        }
                      }}>
                        {isDownloading[element._id] ? <LoadingOutlined /> : <DownloadOutlined />}
                      </Button>
                    </>
                  )}
                </>
              )}
            />
          </Table>
        </Skeleton>
      </Content>
      <Divider />
      <PageHeader
        title={t('orders.show.invoices')}
        ghost={false}
        extra={[
          <Button
            type="add"
            icon={<FileAddOutlined />}
            style={{ marginLeft: 10, float: 'right' }}
            onClick={() => {
              history.push({
                pathname: '/invoices/create',
                search: `?customer=${order.customer._id}&contact=${order.contact._id}&order=${order._id}`
              });
            }}
          >
            {t('orders.show.table.action.create')}
          </Button>
        ]}
      />
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Skeleton
          active
          title={false}
          loading={isLoading}
          paragraph={{ rows: 2 }}
        >
          <Table dataSource={order.invoices} pagination={false}>
            <Column title={t('orders.show.table.number')} dataIndex="number" />
            <Column
              title={t('orders.show.table.type')}
              dataIndex="type"
              render={type => (
                <Tag color={invoiceTypes[type]}>
                  {t(`invoices.tags.${type}`)}
                </Tag>
              )}
            />
            <Column
              title={t('orders.show.table.subject')}
              dataIndex="subject"
            />
            <Column
              title={t('orders.show.table.total')}
              dataIndex="total"
              render={total => `${numeral(total).format('0,0.00')}€`}
            />
            <Column
              title={t('orders.show.table.payment_date')}
              dataIndex="payment_date"
              render={date => date && moment(date).format('DD/MM/YYYY')}
            />
            <Column
              key="action"
              render={({ _id, payment_date, number }) => {
                return (
                  <>
                    <Link
                      to={{
                        pathname: `/invoices/show/${_id}`
                      }}
                    >
                      <EyeOutlined style={{ fontSize: iconSize }} />
                    </Link>
                    {settings.find(s => s.label === 'template_invoices').value && (
                      <>
                        <Divider type="vertical" />
                        <Button style={{ padding: 0 }} type="link" onClick={async () => {
                          if (!isDownloading[_id]) {
                            setIsDownloading({ ...isDownloading, [_id]: true });
                            await downloadFile(_id, `${number}.pdf`, settings.find(s => s.label === 'template_invoices').value);
                          }
                        }}>
                          {isDownloading[_id] ? <LoadingOutlined /> : <DownloadOutlined />}
                        </Button>
                      </>
                    )}
                    {!payment_date && (
                      <>
                        <Divider type="vertical" />
                        <Link
                          to={{
                            pathname: `/invoices/edit/${_id}`
                          }}
                        >
                          <EditOutlined style={{ fontSize: iconSize }} />
                        </Link>
                        <Divider type="vertical" />
                        <Popconfirm
                          title={t('orders.show.table.action.delete')}
                          okText={t('orders.show.table.action.ok')}
                          okButtonProps={{ type: 'danger' }}
                          cancelText={t('orders.show.table.action.cancel')}
                          onConfirm={async () => {
                            await deleteInvoice(_id);
                          }}
                          icon={<WarningOutlined />}
                        >
                          <DeleteOutlined
                            style={{ color: 'red', fontSize: iconSize }}
                            type="delete"
                          />
                        </Popconfirm>
                      </>
                    )}
                  </>
                );
              }}
            />
          </Table>
        </Skeleton>
      </Content>
    </>
  );
};

export default ShowOrder;
