import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Popconfirm, Button } from 'antd';
import moment from 'moment';
import useAccountingContext from '../AccountingContext';
import columns from './columns';
import OptionsRow from './OptionsRow';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import { headers } from './headers';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const iconSize = 18;

const ListInvoices = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    template,
    range,
    deleteDocument,
    downloadFile
  } = useAccountingContext();
  const location = useLocation();
  const { pathname } = location;
  const [extraQuery, setExtraQuery] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [companies, setCompanies] = useState();
  const [customers, setCustomers] = useState();

  useEffect(() => {
    if (range && range.length) {
      setExtraQuery(
        `emission_date>=${moment(range[0])
          .startOf('day')
          .toISOString()}&emission_date<=${moment(range[1])
          .endOf('day')
          .toISOString()}`
      );
    } else setExtraQuery(null);
  }, [range]);

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanies();
      await getCustomers();
    })();
  }, []);

  const actionColumn = [
    {
      key: 'action',
      render: ({ key, payment_date, number }) => {
        return (
          <>
            <Link to={{ pathname: `${pathname}/show/${key}` }}>
              <EyeOutlined
                style={{ fontSize: iconSize, color: 'var(--textColor)' }}
              />
            </Link>
            {template && (
              <>
                <Divider type="vertical" />
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={async () => {
                    if (!isDownloading[key]) {
                      setIsDownloading({ ...isDownloading, [key]: true });
                      await downloadFile(key, `${number}.pdf`, () =>
                        setIsDownloading({ ...isDownloading, [key]: false })
                      );
                    }
                  }}
                >
                  {isDownloading[key] ? (
                    <LoadingOutlined />
                  ) : (
                    <DownloadOutlined />
                  )}
                </Button>
              </>
            )}
            {!payment_date && (
              <>
                <Divider type="vertical" />
                <Link to={{ pathname: `${pathname}/edit/${key}` }}>
                  <EditOutlined
                    style={{ fontSize: iconSize, color: 'var(--textColor)' }}
                  />
                </Link>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={async () => {
                    setForceRefresh(false);
                    await deleteDocument(key, () => setForceRefresh(true));
                  }}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined
                    style={{ color: 'red', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
          </>
        );
      }
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="invoices"
      columns={[...columns({ t, companies, customers }), ...actionColumn]}
      extraQuery={extraQuery}
      populate="customer,emitter"
      customActionColumn
      forceRefresh={forceRefresh}
      withUploadButton
      headers={headers}
      extraButtons={<OptionsRow />}
      scroll={{ x: 1000 }}
    />
  );
};

export default ListInvoices;
