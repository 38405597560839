import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Popconfirm, Button, Skeleton } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import ContentInformation from '../../../components/ContentInformation/ContentInformation';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';

const ShowIncome = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const [income, setIncome] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { message } = useErrorMessage();

  const getIncome = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incomes/${id}?populate=company,building,lot,category`
      });
      setIncome(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
    setIsLoading(false);
  };

  const deleteResource = (key, callback) => {
    dispatchAPI('DELETE', { url: `/incomes/${key}` });
    callback();
  };

  useEffect(() => {
    (async () => {
      await getIncome();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t(`incomes.title`)}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INCOMES}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteResource(id, history.goBack)}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation>
        <Skeleton loading={isLoading}>
          <DescriptionList
            title="Informations"
            data={listContent(income)}
            translate
          />
        </Skeleton>
      </ContentInformation>
    </>
  );
};

export default ShowIncome;
