import React from 'react';
import { DatePicker } from 'antd';
import useAccountingContext from '../AccountingContext';

const { RangePicker } = DatePicker;

const OptionsRow = () => {
  const { range, setRange } = useAccountingContext();

  return <RangePicker value={range} onChange={dates => setRange(dates)} />;
};

export default OptionsRow;
