import moment from 'moment';
import { Tag } from 'antd';
import React from 'react';

const columns = t => [
  {
    title: t('receipts.columns.emitter'),
    dataIndex: 'building',
    key: 'building',
    render: building => building.name,
    sorter: true
  },
  {
    title: t('receipts.columns.status'),
    key: 'status',
    sorter: true,
    render: record => {
      if (record.hasOwnProperty('reconciliation')) {
        if (
          record &&
          record.reconciliation.hasOwnProperty('total_reconciled') &&
          record.reconciliation.total_reconciled
        ) {
          return <Tag color="green">Payé</Tag>;
        }
        return <Tag color="orange">En attente</Tag>;
      }
      return <Tag color="red">Impayé</Tag>;
    }
  },
  {
    title: t('receipts.columns.number'),
    dataIndex: 'reference',
    key: 'reference',
    sorter: true
  },
  {
    title: t('receipts.columns.date'),
    dataIndex: 'date',
    key: 'date',
    render: date => moment(date).format('L'),
    sorter: true
  },
  {
    title: t('receipts.columns.customer'),
    dataIndex: 'tenant',
    key: 'tenant',
    render: tenant => tenant && tenant.name,
    sorter: true
  },
  {
    title: t('receipts.columns.bill_period'),
    dataIndex: 'dates',
    key: 'dates',
    render: dates => t(`receipts.columns.period.${dates.period}`),
    sorter: true
  },
  {
    title: t('receipts.columns.total'),
    dataIndex: 'total',
    key: 'total',
    render: total =>
      `${parseFloat(total.rent + total.charge + total.tax)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`,
    sorter: true
  }
];

export default columns;
