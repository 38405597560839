import React, { useCallback, useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';

const Columns = () => {
  const { dispatchAPI } = useAuthContext();
  const [buildings, setBuildings] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  const renderCategories = value => {
    let display = '-';
    categories.map(e => {
      if (e._id === value) {
        display = e.label;
      }
    });
    return display;
  };

  const fetchCategories = async () => {
    const { data } = await dispatchAPI('GET', { url: '/expenses-categories' });
    setCategories(data);
  };

  const fetchData = useCallback(async () => {
    const { data } = await dispatchAPI('GET', { url: `/buildings` });
    setBuildings(data);
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchData();
  }, [fetchData]);

  return [
    {
      title: t('expenses.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      render: date => (date && moment(date).format('L')) || '-'
    },
    {
      title: t('expenses.form.company'),
      key: 'company',
      dataIndex: 'company',
      sorter: true,
      render: company => company && company.name
    },
    {
      title: t('expenses.form.category'),
      key: 'category',
      dataIndex: 'category',
      sorter: true,
      render: id => {
        return renderCategories(id);
      }
    },
    {
      title: t('expenses.form.supplier'),
      key: 'supplier',
      dataIndex: 'supplier',
      sorter: true
    },
    {
      title: t('expenses.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('expenses.form.payment_type'),
      key: 'payment_type',
      dataIndex: 'payment_type',
      sorter: true
    },
    {
      title: t('expenses.form.amount_ttc'),
      key: 'amount_ttc',
      sorter: true,
      render: (value, record) => record?.amount_ht + record?.amount_tva
    }
  ];
};

export default Columns;
