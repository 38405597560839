import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BankReconciliation from './BankReconciliation';
import { BankReconciliationContextProvider } from './BankReconciliationContext';

const BankReconciliationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <BankReconciliationContextProvider>
      <Switch>
        <Route path={path} component={BankReconciliation} />
      </Switch>
    </BankReconciliationContextProvider>
  )
};

export default BankReconciliationRouter;
