import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ExpensesTable from './expensesTable';

const ExpensesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [expenses, setExpenses] = useState([]);
  const [newExpenses] = useState({
    sub_category: 'Sous-catégorie',
    code: 'Code comptable',
    label: 'Libellé',
    show: true
  });

  const fetchExpenses = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/expenses-categories' });
      setExpenses(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const add = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/expenses-categories',
        body: newExpenses
      });
    } catch (e) {
      message.error(e);
    }
    await fetchExpenses();
  };

  const del = async item => {
    try {
      await dispatchAPI('DELETE', {
        url: `/expenses-categories/${item._id}`
      });
    } catch (e) {
      message.error('Ticket exist with this status');
    }
    await fetchExpenses();
  };

  const edit = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/expenses-categories/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e);
    }
    await fetchExpenses();
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  return (
    <>
      {expenses.length > 0 && (
        <ExpensesTable expenses={expenses} editExpense={edit} del={del} />
      )}
      <Button type="dashed" style={{ width: '100%', height: 50 }} onClick={add}>
        {`${t('settings.expenses.add')} `}
      </Button>
    </>
  );
};
export default ExpensesSettings;
