const headers = [
  {
    label: 'Due_date',
    key: 'date'
  },
  {
    label: 'wording',
    key: 'wording'
  },
  {
    label: 'category',
    key: 'category.label'
  },
  {
    label: 'Amount_HT',
    key: 'amount_ht'
  },
  {
    label: 'TVA',
    key: 'tva'
  },
  {
    label: 'Customers first name',
    key: 'id_supplier.first_name' || ''
  },
  {
    label: 'Customers last name',
    key: 'id_supplier.last_name' || ''
  },
  {
    label: 'Invoice is reconciled',
    key: 'is_reconciled'
  },
  {
    label: 'Invoice amount HT',
    key: 'amount_ht'
  },
  {
    label: 'Invoice total reconciled',
    key: 'total_reconciled'
  }
];

export default headers;
