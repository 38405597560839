import moment from 'moment';

const useListContent = ({
  // reference,
  supplier,
  category,
  emission_date,
  dealine_date,
  company,
  building,
  lot,
  amount_ht,
  amount_tva,
  wording
}) => {
  const content = [
    {
      label: 'incomes.form.category',
      content: category && category.label
    },
    {
      label: 'incomes.form.supplier',
      content: supplier
    },
    {
      label: 'incomes.form.emission_date',
      content: moment(emission_date).format('L')
    },
    {
      label: 'incomes.form.deadline_date',
      content: moment(dealine_date).format('L')
    },
    {
      label: 'incomes.form.company',
      content: company && company.name
    },
    {
      label: 'incomes.form.building',
      content: building && building.name
    },
    {
      label: 'incomes.form.lot',
      content: lot && lot.reference
    },
    {
      label: 'incomes.form.amount_ht',
      content: amount_ht
    },
    {
      label: 'incomes.form.amount_tva',
      content: amount_tva
    },
    {
      label: 'incomes.form.wording',
      content: wording
    }
  ];

  return content;
};

export default useListContent;
