import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import IncomesTable from './incomesTable';

const IncomesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [incomes, setIncomes] = useState([]);
  const [newIncome] = useState({
    sub_category: 'Sous-catégorie',
    code: 'Code comptable',
    label: 'Libellé',
    show: true
  });

  const fetchIncomes = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/incomes-categories' });
      setIncomes(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const add = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/incomes-categories',
        body: newIncome
      });
    } catch (e) {
      message.error(e);
    }
    await fetchIncomes();
  };

  const del = async item => {
    try {
      await dispatchAPI('DELETE', {
        url: `/incomes-categories/${item._id}`
      });
    } catch (e) {
      message.error('Income exist with this status');
    }
    await fetchIncomes();
  };

  const edit = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/incomes-categories/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e);
    }
    await fetchIncomes();
  };

  useEffect(() => {
    fetchIncomes();
  }, []);

  return (
    <>
      {incomes.length > 0 && (
        <IncomesTable incomes={incomes} editIncome={edit} del={del} />
      )}
      <Button type="dashed" style={{ width: '100%', height: 50 }} onClick={add}>
        {`${t('settings.incomes.add')} `}
      </Button>
    </>
  );
};
export default IncomesSettings;
