import React, { useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DownloadGeneratedFile = ({ element, template, downloadFile }) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <>
      {template && (
        <Button type="primary" style={{margin: 0}} icon={isDownloading ? <LoadingOutlined /> : <DownloadOutlined />} onClick={async () => {
          if (!isDownloading) {
            setIsDownloading(true);
            await downloadFile(element._id, `${element.number}.pdf`, () => setIsDownloading(false));
          }
        }}>{t('buttons.download')}</Button>
      )}
    </>
  );
};

DownloadGeneratedFile.propTypes = {
  element: PropTypes.shape({
    _id: PropTypes.string,
    number: PropTypes.string
  }).isRequired,
  template: PropTypes.string.isRequired,
  downloadFile: PropTypes.func.isRequired
}

export default DownloadGeneratedFile;
