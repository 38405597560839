import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Skeleton,
  Image,
  Table,
  Typography,
  Divider
} from 'antd';
import {
  DeleteOutlined,
  PrinterOutlined,
  WarningOutlined
} from '@ant-design/icons/lib';
import moment from 'moment';
import numeral from 'numeral';
import ContentInformation from '../../components/ContentInformation/ContentInformation';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import useContext from './context';
import { useGenerateDocument } from '../../utils/generateDocument';
import RamosLogo from '../../assets/images/logo-ramos-groupe-bleu.png';

const { Text, Title } = Typography;

const Show = () => {
  const { id } = useParams();
  const { collection, dispatchAPI, message, t } = useContext();
  const [resource, setResource] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [articles, setArticles] = useState([]);
  const [company, setCompany] = useState();
  const { generateDocument } = useGenerateDocument();
  const [docIsCreating, setDocIsCreating] = useState(false);

  const onRefresh = () => setRefresh(!refresh);

  const genrateReceiptDoc = async () => {
    setDocIsCreating(true);
    await generateDocument(id, 'receipt');
    setDocIsCreating(false);
  };

  const getCompanyInformation = useCallback(async companyId => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${companyId}`
      });
      setCompany(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${collection}/${id}`
      });
      const receiptArticles = [];
      Object.entries(data.total).map(([key, value]) => {
        let label = '';
        if (key === 'rent') {
          label = `Appel Loyer ${moment(data.dates.date).format('MMMM YYYY')}`;
        } else if (key === 'tax') {
          label = `Appel Provision de Charges Locatives ${moment(
            data.dates.date
          ).format('MMMM YYYY')}`;
        } else {
          label = `Appel Provision Taxe Foncière ${moment(
            data.dates.date
          ).format('MMMM YYYY')}`;
        }
        receiptArticles.push({
          amount_ht: value,
          date: moment(data.dates.date),
          label
        });
        return true;
      });
      getCompanyInformation(data.building.company);
      setArticles(receiptArticles);
      setResource(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, [refresh]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    })();
  }, [fetchData]);

  const columns = [
    {
      title: t('receipts.columns.articles.date'),
      dataIndex: 'date',
      key: 'date',
      render: date => moment(date).format('L')
    },
    {
      title: t('receipts.columns.articles.label'),
      dataIndex: 'label',
      key: 'label',
      render: label => label
    },
    {
      title: t('receipts.columns.articles.amount_ht'),
      dataIndex: 'amount_ht',
      key: 'amount_ht',
      render: amount => `${numeral(amount).format('0,0.00')} €`
    }
  ];

  const renderFooter = () => {
    let footer = null;
    if (resource) {
      footer = (
        <div align="right" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          <Text>
            {`TOTAL HT : ${numeral(
              resource &&
                resource.total &&
                resource.total.rent + resource.total.charge + resource.total.tax
            ).format('0,0.00')} €`}
          </Text>
          <br />
          <div>
            <span style={{ marginRight: '24px' }}>
              <Text>TVA 20% :</Text>
            </span>
            <span>
              <Text>
                {`${numeral(
                  ((resource &&
                    resource.total &&
                    resource.total.rent +
                      resource.total.charge +
                      resource.total.tax) *
                    20) /
                    100
                ).format('0,0.00')} €`}
              </Text>
            </span>
          </div>
          <Divider />
          <Text>
            {`TOTAL TTC : ${numeral(
              (resource &&
                resource.total &&
                resource.total.rent +
                  resource.total.charge +
                  resource.total.tax) +
                ((resource &&
                  resource.total &&
                  resource.total.rent +
                    resource.total.charge +
                    resource.total.tax) *
                  20) /
                  100
            ).format('0,0.00')} €`}
          </Text>
        </div>
      );
    }
    return footer;
  };

  const tableProps = {
    bordered: false,
    footer: renderFooter
  };

  return (
    <>
      <PageHeaderCustom
        title={t('receipts.title')}
        withSubRoutes
        extra={
          <>
            <Button
              type="primary"
              loading={docIsCreating}
              onClick={() => genrateReceiptDoc()}
            >
              {`${t('buttons.print')} `}
              <PrinterOutlined />
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton loading={isLoading}>
          {resource && (
            <div style={{ margin: '80px 200px' }}>
              <Row>
                <Col span={12}>
                  <Image src={RamosLogo} height={100} width="auto" />
                </Col>
                <Col span={12}>
                  <Title level={5}>{resource?.tenant?.name}</Title>
                  <Title level={5}>
                    {resource?.tenant && resource.tenant.bill_address}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Title level={5}>N° Facture</Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Title level={5}>{resource.wording}</Title>
                  <Text>{moment(resource.period).format('LL')}</Text>
                  <br />
                  <Text>{company?.name}</Text>
                  <br />
                  <Text>{company?.address}</Text>
                  <br />
                  <Text>{company?.phone}</Text>
                  <br />
                  <Text>{`${company?.legal_form} au capital de ${company?.share_capital} €`}</Text>
                  <br />
                  <Text>{`RSC de Toulouse N° ${company?.rcs_number}`}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    {...tableProps}
                    pagination={false}
                    dataSource={articles}
                    columns={columns}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Skeleton>
      </ContentInformation>
    </>
  );
};

export default Show;
