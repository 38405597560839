import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('managings.cashflow.columns.company'),
      key: 'company',
      dataIndex: 'company',
      sorter: true
    },
    {
      title: t('managings.cashflow.columns.portion'),
      key: 'portion',
      dataIndex: 'portion',
      render: value => `${value} %`,
      width: 180
    },
    {
      title: t('managings.cashflow.columns.lender'),
      key: 'loans',
      dataIndex: 'loans',
      sorter: true,
      render: value => value[0]?.lender
    },
    {
      title: t('managings.cashflow.columns.ending_date_loan'),
      key: 'ending_date_loan',
      dataIndex: 'loans',
      render: value => value[0] && moment(value[0].ending_date_loan).format('L')
    },
    {
      title: t('managings.cashflow.columns.outstanding_capital'),
      key: 'loans',
      dataIndex: 'loans',
      render: value =>
        value[0] &&
        `${Number.parseFloat(value[0].outstanding_capital).toFixed(
          2
        )} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    {
      title: t('managings.cashflow.columns.expenses'),
      key: 'expenses',
      dataIndex: 'expenses',
      sorter: true,
      render: value => `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    {
      title: t('managings.cashflow.columns.rental_incomes'),
      key: 'rental_incomes',
      dataIndex: 'rental_incomes',
      sorter: true,
      render: value => `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    {
      title: t('managings.cashflow.columns.cashflow'),
      key: 'cashflow',
      dataIndex: 'cashflow',
      sorter: true,
      render: value => `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    {
      title: t('managings.cashflow.columns.second_lender'),
      key: 'loans',
      dataIndex: 'loans',
      render: value => value[1]?.lender
    },
    {
      title: t('managings.cashflow.columns.third_lender'),
      key: 'loans',
      dataIndex: 'loans',
      render: value => value[2]?.lender
    },
    {
      title: t('managings.cashflow.columns.fourth_lender'),
      key: 'loans',
      dataIndex: 'loans',
      render: value => value[3]?.lender
    }
  ];
};

export default useColumns;
