import { useTranslation } from 'react-i18next';

const useListContent = ({
  name,
  address,
  type_company,
  company,
  type_parking,
  nb_parking_places,
  access_type,
  surface,
  syndic
}) => {
  const { t } = useTranslation();
  return [
    {
      label: 'buildings.form.name',
      content: name
    },
    {
      label: 'buildings.form.type_company',
      content: t(`buildings.form.${type_company}`)
    },
    {
      label: 'buildings.form.access_type',
      content: access_type || '-'
    },
    {
      label: 'buildings.form.surface',
      content: surface
    },
    {
      label: 'buildings.form.company',
      content: company?.name
    },
    {
      label: 'buildings.form.type_parking',
      content: type_parking?.join(' & ') || '-'
    },
    {
      label: 'buildings.form.address',
      content: address,
      span: 2
    },
    {
      label: 'buildings.form.nb_parking_places',
      content: nb_parking_places || '-'
    },

    {
      label: 'buildings.form.syndic',
      content: syndic?.name
    }
  ];
};

export default useListContent;
