import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';

import Incomes from './Incomes/ListIncomes';
import ShowIncome from './Incomes/ShowIncome';
import CreateUpdateIncome from './Incomes/CreateUpdateIncome';

import InvoiceRouter from './Invoices/InvoiceRouter';
import OrderRouter from './Orders/OrderRouter';
import BankReconciliationRouter from './BankReconciliation/BankReconciliationRouter';
import ExpensesRouter from './Expenses/ExpensesRouter';
import SuppliersRouter from './Suppliers/SuppliersRouter';
import ReceiptRouter from '../Receipts/router';
import LoansRouter from './Loans/LoansRouter';

const AccountRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.ACCOUNTING.INCOMES}/create`}
        render={() => <CreateUpdateIncome purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.INCOMES}/edit/:id`}
        render={() => <CreateUpdateIncome purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.INCOMES}/show/:id`}
        component={ShowIncome}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.INCOMES}`}
        component={Incomes}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.EXPENSES}`}
        component={ExpensesRouter}
      />

      <Route
        path={`${path}${subRoutes.ACCOUNTING.SUPPLIERS}`}
        component={SuppliersRouter}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.BANK_RECONCILIATION}`}
        component={BankReconciliationRouter}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.INVOICES}`}
        component={InvoiceRouter}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.ORDERS}`}
        component={OrderRouter}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.RECEIPTS}`}
        component={ReceiptRouter}
      />
      <Route
        path={`${path}${subRoutes.ACCOUNTING.LOANS}`}
        component={LoansRouter}
      />
    </Switch>
  );
};

export default AccountRouter;
