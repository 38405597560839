import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContentCustom from '../ContentCustom/ContentCustom';
import PageHeaderCustom from '../PageHeader/PageHeader';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

const modes = {
  FORM: 'FORM',
  SCAN: 'SCAN'
};

const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      withSubRoutes,
      submitLabel,
      customSubmit,
      per,
      onValuesChange
    },
    ref
  ) => {
    const history = useHistory();
    const { id } = useParams();
    const { t } = useTranslation();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [mode] = useState(modes.FORM);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    form.setFieldsValue({
      bailleur_part: per
    });
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async body => {
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? await onUpdateResource.setBody(body)
              : body
        });
        history.goBack();
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    };

    const createResource = async body => {
      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? await onCreateResource.setBody(body)
              : body
        });
        history.goBack();
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', { url: `${baseUrl}/${id}` });
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? await onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields]);

    useEffect(() => {
      if (purpose === 'edit' && id) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource]);

    const handleSubmit = async values => {
      if (customSubmit) return customSubmit(values);
      if (purpose === 'edit') await updateResource(values);
      if (purpose === 'create') await createResource(values);
      return false;
    };

    return (
      <>
        <PageHeaderCustom
          title={t(`${resource}.form.${purpose}`)}
          withSubRoutes={withSubRoutes}
        />
        <ContentCustom>
          <Form
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...formItemLayout}
            onFinish={handleSubmit}
            form={form}
            ref={ref}
            onValuesChange={
              onValuesChange
                ? (changedValues, values) =>
                    onValuesChange(changedValues, values, form)
                : null
            }
          >
            {fields.map(field => generateFields(tradKey || resource, field))}
            {formExtra}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => history.goBack()}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit">
                  {`${t(submitLabel || 'buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  withSubRoutes: PropTypes.bool,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  onValuesChange: PropTypes.func
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  withSubRoutes: false,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  onValuesChange: null
};

export default CreateUpdateContainer;
