import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../../utils/constants/routes';
import ListCustomers from './ListCustomers';
import DetailCustomer from './DetailCustomer';
import CreateUpdateCustomer from './CreateUpdateCustomer';

const CustomersRouter = () => {

  return (
    <Switch>
      <Route
        path={`${routes.CUSTOMERS}/create`}
        render={() => <CreateUpdateCustomer purpose="create" />}
      />
      <Route
        path={`${routes.CUSTOMERS}/edit/:id`}
        render={() => <CreateUpdateCustomer purpose="edit" />}
      />
      <Route path={`${routes.CUSTOMERS}/show/:id`} component={DetailCustomer} />
      <Route path={`${routes.CUSTOMERS}`} render={() => <ListCustomers />} />
    </Switch>
  );
};

export default CustomersRouter;
