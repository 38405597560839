const headers = [
  {
    label: 'Nom',
    key: 'name'
  },
  {
    label: 'Forme juridique',
    key: 'type'
  },
  {
    label: 'Forme juridique',
    key: 'legal_form'
  },
  {
    label: 'Groupe',
    key: 'group' || ''
  },
  {
    label: 'Numero de voie',
    key: 'channel_number'
  },
  {
    label: "Complement d'addresse",
    key: 'address_details'
  },
  {
    label: 'Code postal',
    key: 'postal_code'
  },
  {
    label: 'Ville',
    key: 'city'
  },
  {
    label: 'SIREN',
    key: 'siren_number'
  },
  {
    label: 'SIRET',
    key: 'siret_number'
  },
  {
    label: 'APE',
    key: 'ape_number' || ''
  },
  {
    label: 'Capital social',
    key: 'share_capital' || ''
  }
];

export default headers;
