import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Skeleton, Tabs, Row, Col, Typography, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import ContentInformation from '../../components/ContentInformation/ContentInformation';
import dataFilter from '../../utils/filtersFunction';
import TimelineRentalsTabs from './RentalsTabs';
import CustomeFilesSpace from './CustomFilesSpace';
import RentalReceiptsTable from './RentalReceiptsTable';
import { useGenerateDocument } from '../../utils/generateDocument';

const { Title } = Typography;

const styles = {
  tabTitle: {
    textTransform: 'uppercase',
    fontSize: '0.8rem'
  }
};

const { TabPane } = Tabs;

const ShowLot = () => {
  Moment.locale('fr');
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [rental, setRental] = useState({});
  const [income, setIncome] = useState({});
  const [categories, setCategories] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [memos, setMemos] = useState();
  const { generateDocument } = useGenerateDocument();
  const [docIsCreating, setDocIsCreating] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rentals/${id}?populate=lot,tenant,building`
      });
      setRental(data);
      setMemos(data.memos);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id, refresh]);

  const fetchIncome = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incomes/rental/${id}`
      });
      setIncome(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incomes-categories`
      });
      setCategories(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData();
      await fetchIncome();
      await fetchCategories();
      setIsLoading(false);
    })();
  }, [fetchData]);

  const deleteRentals = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/rentals/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const genrateRentalDoc = async () => {
    setDocIsCreating(true);
    await generateDocument(id, 'rental');
    setDocIsCreating(false);
  };

  return (
    <>
      <PageHeaderCustom
        title={t('rental.show.title')}
        extra={
          <>
            <Button
              type="primary"
              loading={docIsCreating}
              onClick={() => genrateRentalDoc()}
            >
              {`${t('buttons.print')} `}
              <PrinterOutlined />
            </Button>
            <Link to={{ pathname: `${routes.RENTALS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteRentals}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <h1
            style={{
              fontSize: '1.3rem',
              fontWeight: 600,
              textTransform: 'uppercase',
              background: 'rgb(38, 100, 123, 0.04)',
              borderRadius: '6px',
              display: 'inline-block',
              padding: '0 16px'
            }}
          >
            Contrat de bail
          </h1>
          <Row gutter={16}>
            <Col span={9} style={{ display: 'flex' }}>
              <DescriptionList
                style={{ flex: 1 }}
                title="Informations générales"
                data={dataFilter(listContent(rental), [
                  'lot',
                  'tenant',
                  'type',
                  'insee_type',
                  'base_index',
                  'review_index',
                  'start_date',
                  'end_date',
                  'rentperiod',
                  'rent_free'
                ])}
                resourceName="rental"
                translate
              />
            </Col>
            <Col span={6} style={{ display: 'flex' }}>
              <DescriptionList
                style={{ flex: 1 }}
                title="Loyer"
                data={dataFilter(listContent(rental), [
                  'rent_ht_month',
                  'rent_ht_m2_month',
                  'rent_ht_year',
                  'rent_ht_m2_year'
                ])}
                resourceName="rental"
                translate
              />
            </Col>
            <Col span={9} style={{ display: 'flex' }}>
              <DescriptionList
                style={{ flex: 1 }}
                title="Contribution"
                data={dataFilter(listContent(rental), [
                  'rental_taxes_contribution_month',
                  'rental_charges_contribution_month',
                  'rental_taxes_contribution_m2_month',
                  'rental_charges_contribution_m2_month',
                  'rental_taxes_contribution_year',
                  'rental_charges_contribution_year',
                  'rental_taxes_contribution_m2_year',
                  'rental_charges_contribution_m2_year'
                ])}
                resourceName="rental"
                translate
              />
            </Col>
          </Row>
        </Skeleton>
      </ContentCustom>
      <ContentCustom>
        <Tabs>
          <TabPane tab={<Title style={styles.tabTitle}>Comptes</Title>} key="1">
            Compte
          </TabPane>
          <TabPane
            tab={<Title style={styles.tabTitle}>Quittances</Title>}
            key="2"
          >
            <RentalReceiptsTable />
          </TabPane>
          <TabPane
            tab={<Title style={styles.tabTitle}>Révisions de loyers</Title>}
            key="3"
          >
            Révision de loyers
          </TabPane>
        </Tabs>
      </ContentCustom>
      <ContentInformation>
        <Row gutter={[16, 16]}>
          <Col span={8} style={{ display: 'flex' }}>
            <CustomeFilesSpace
              id={id}
              files={rental.files}
              setForceRefresh={setRefresh}
              refresh={refresh}
              resource="rentals"
            />
          </Col>
          <Col
            span={16}
            style={{
              display: 'flex'
            }}
          >
            <TimelineRentalsTabs
              title="Mémo"
              style={{ flex: 1 }}
              setShowForm={setShowForm}
              showForm={showForm}
              id={id}
              rental={rental}
              memos={memos}
              fetchData={fetchData}
            />
          </Col>
        </Row>
      </ContentInformation>
    </>
  );
};

export default ShowLot;
