import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/routes';

const useListContent = ({
  name,
  legal_form,
  group,
  category,
  channel_number,
  street,
  address_details,
  postal_code,
  city,
  siren_number,
  siret_number,
  ape_number,
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'suppliers.form.name',
      content: name,
    },
    {
      label: 'suppliers.form.legal_form',
      content: legal_form,
    },
    {
      label: 'suppliers.form.group',
      content: group,
    },
    {
      label: 'suppliers.form.category',
      content: category,
    },
    {
      label: 'suppliers.form.address',
      content: `${channel_number} ${street} ${address_details}`,
    },
    {
      label: 'suppliers.form.postcode',
      content: postal_code,
    },
    {
      label: 'suppliers.form.city',
      content: city,
    },
    {
      label: 'suppliers.form.siren_number',
      content: siren_number,
    },
    {
      label: 'suppliers.form.siret_number',
      content: siret_number,
    },
    {
      label: 'suppliers.form.ape_number',
      content: ape_number,
    },
  ];
};

export default useListContent;
