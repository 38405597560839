import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;

const useFields = (message, t, dispatchAPI, collection) => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `${collection}/enums` });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['position'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number_1'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number_2']
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;

