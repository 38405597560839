export const incomesSubCategories = [
  'rented_incomes',
  'company_managment',
  'works',
  'syndicate',
  'cash_flow'
];

export const expensesSubCategories = [
  'company_managment',
  'park_managment',
  'service_agreement',
  'bank',
  'juridical'
];
