import React from 'react';
import useColumns from './columns';
import headers from './headers';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const ListLots = () => {
  const columns = useColumns();

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="lots"
      withSubRoutes
      populate="building"
      resourceModelName="lots"
      sortDefault="reference"
      headers={headers}
      withUploadButton
      scroll={{ x: 1200 }}
    />
  );
};

export default ListLots;
