import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components';
import useColumns from '../../Rentals/columns';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { RangePicker } = DatePicker;

const CustomersLocationsTable = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const [companies, setCompanies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [lots, setLots] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dateRange, setDateRange] = useState();
  const columns = useColumns({ customers, companies, lots, typeFilters });

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers'
      });
      const listCustomersName = data.map(({ name, _id }) => {
        return {
          text: name,
          value: _id
        };
      });
      setCustomers(listCustomersName);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getLots = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/lots'
      });
      const listLotsReference = data.map(({ _id, reference }) => {
        return {
          text: reference,
          value: _id
        };
      });
      setLots(listLotsReference);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      const listCompaniesName = data.map(({ name, _id }) => {
        return {
          text: name,
          value: _id
        };
      });
      setCompanies(listCompaniesName);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/rentals/enums'
      });
      const types = data.type.map(type => {
        return {
          text: t(`rentals.type.${type}`),
          value: type
        };
      });
      setTypeFilters(types);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanies();
      await getCustomers();
      await getLots();
      await getEnums();
    })();
  }, []);

  const onChangeRange = (_, dateString) => {
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      setDateRange();
    } else {
      setDateRange(dateString);
    }
    setRefresh(!refresh);
  };

  return (
    <>
      <RangePicker onChange={onChangeRange} />
      <Datatable
        resourceName="rentals"
        columns={columns}
        populate="lot,tenants"
        extraQuery={
          dateRange
            ? `tenant=${id}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
            : `tenant=${id}`
        }
        customActionColumn
        path="/rentals"
        forceRefresh={refresh}
      />
    </>
  );
};

export default CustomersLocationsTable;
