import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Skeleton,
  Image,
  Table,
  Typography,
  Divider
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  PrinterOutlined
} from '@ant-design/icons/lib';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import { routes, subRoutes } from '../../../utils/constants/routes';
import { useGenerateDocument } from '../../../utils/generateDocument';
import ContentInformation from '../../../components/ContentInformation/ContentInformation';
import RamosLogo from '../../../assets/images/logo-ramos-groupe-bleu.png';

const { Text, Title } = Typography;

const ShowInvoice = ({ resource }) => {
  const { generateDocument } = useGenerateDocument();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [docIsCreating, setDocIsCreating] = useState(false);

  const genrateReceiptDoc = async () => {
    setDocIsCreating(true);
    await generateDocument(id, 'invoice');
    setDocIsCreating(false);
  };

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/${id}?populate=customer,company,other_customer,emitter`
      });
      setInvoice(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    await getResource();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchResource();
  }, [fetchResource, id]);

  const columns = [
    {
      title: t('invoices.columns.articles.label'),
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: t('invoices.columns.articles.unit_value'),
      dataIndex: 'unity',
      key: 'unity',
      render: unity => unity && t('invoices.form.articles.unity')
    },
    {
      title: t('invoices.columns.articles.quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: t('invoices.columns.articles.unit_price'),
      dataIndex: 'unit_price',
      key: 'unit_price',
      render: unit_price => `${numeral(unit_price).format('0,0.00')} €`
    },
    {
      title: t('invoices.columns.articles.total'),
      key: 'total',
      render: data =>
        data.quantity &&
        data.unit_price &&
        `${numeral(data.quantity * data.unit_price).format('0,0.00')} €`
    }
  ];

  const renderFooter = () => {
    let footer = null;
    if (invoice) {
      footer = (
        <div align="right" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          <Text>
            {`TOTAL HT : ${numeral(invoice.subTotal).format('0,0.00')} €`}
          </Text>
          <br />
          <div>
            <span>
              <Text>TVA 20% :</Text>
            </span>
            <span>
              <Text>{` ${numeral(invoice.total_VAT).format('0,0.00')} €`}</Text>
            </span>
          </div>
          <Divider />
          <Text>
            {`TOTAL TTC : ${numeral(invoice.total).format('0,0.00')} €`}
          </Text>
        </div>
      );
    }
    return footer;
  };

  const tableProps = {
    bordered: false,
    footer: renderFooter
  };

  const customerInformations = () => {
    if (invoice.customer) {
      return (
        <>
          <Title level={5}>
            {`${invoice.customer.last_name} ${invoice.customer.first_name}`}
          </Title>
          <Title level={5}>
            {`${invoice.customer.address_details[0].address_components[0].short_name}, ${invoice.customer.address_details[0].address_components[1].short_name}`}
          </Title>
          <Title level={5}>
            {`${invoice.customer.address_details[0].address_components[6].short_name} ${invoice.customer.address_details[0].address_components[2].short_name}, ${invoice.customer.address_details[0].address_components[5].long_name}`}
          </Title>
        </>
      );
    }
    if (invoice.company) {
      return (
        <>
          <Title levelt={5}>{invoice.company.name}</Title>
          <Title level={5}>{invoice.company.address}</Title>
        </>
      );
    }
    if (invoice.other_customer) {
      return invoice.other_customer.customer_type === 'INDIVIDUAL' ? (
        <>
          <Title level={5}>
            {`
        ${invoice.other_customer.last_name}
        ${invoice.other_customer.first_name}`}
          </Title>
          <Title level={5}>{invoice.other_customer.bill_address}</Title>
        </>
      ) : (
        <>
          <Title level={5}>{invoice.other_customer.company_name}</Title>
          <Title level={5}>{invoice.other_customer.bill_address}</Title>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeaderCustom
        title={t('invoices.title')}
        withSubRoutes
        extra={
          <>
            <Button
              type="primary"
              loading={docIsCreating}
              onClick={() => genrateReceiptDoc()}
            >
              {`${t('buttons.print')} `}
              <PrinterOutlined />
            </Button>
            <Link
              to={{
                pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INVOICES}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentInformation style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton loading={isLoading}>
          {invoice && (
            <div style={{ margin: '80px 200px' }}>
              <Row>
                <Col span={12}>
                  <Image src={RamosLogo} height={100} width="auto" />
                </Col>
                <Col span={12}>{customerInformations()}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Title level={5}>
                    {t('invoices.show.number', {
                      number: invoice.number
                    })}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Title level={5}>
                    {`${t('invoices.show.object')} : ${invoice.wording}`}
                  </Title>
                  <Text>
                    {invoice.emitter &&
                      `Site : ${invoice.emitter.name} / ${invoice.emitter.address}`}
                  </Text>
                  <br />
                  <Text>{`Date : ${moment(invoice.period).format('L')}`}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Text>
                      {`${t('invoices.showInvoice.payments')} : ${t(
                        `invoices.showInvoice.paymentMethod.${invoice.payment}`
                      )}`}
                    </Text>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    {...tableProps}
                    pagination={false}
                    dataSource={invoice.articles}
                    columns={columns}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '8px' }}>
                  <Text>{t('invoices.showInvoice.bank_account_details')}</Text>
                  <br />
                  <Text>{`IBAN : ${invoice?.emitter?.accounts?.iban}`}</Text>
                  <br />
                  <Text>{`BIC : ${invoice?.emitter?.accounts?.bic}`}</Text>
                  <br />
                  <Text>{invoice?.emitter?.accounts?.bank_name}</Text>
                </Col>
              </Row>
            </div>
          )}
        </Skeleton>
        <Row justify="center">
          <Col
            style={{
              maxWidth: '620px',
              textAlign: 'center'
            }}
          >
            <Text>{t('invoices.showInvoice.legal_text')}</Text>
            <Divider />
            <Text>
              {`${invoice?.emitter?.name} - ${invoice?.emitter?.address}`}
            </Text>
            <br />
            <Text>{`${invoice?.emitter?.legal_form} au capital de ${invoice?.emitter?.share_capital} € - RCS N° ${invoice?.emitter?.rcs_number} Toulouse`}</Text>
            <br />
            <Text>Tél: 05 62 16 16 16 - facturation@ramos.fr</Text>
          </Col>
        </Row>
      </ContentInformation>
    </>
  );
};

ShowInvoice.propTypes = {
  resource: PropTypes.string.isRequired
};

export default ShowInvoice;
