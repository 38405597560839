import React, { useState, useEffect } from 'react';
import { Select, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;

const ShowCashflow = () => {
  const columns = useColumns();
  const currentYear = moment().year();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [holdings, setHoldings] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [extraQuery, setExtraQuery] = useState(null);
  const [year, setYear] = useState(Number(currentYear));

  const getHoldings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/holdings'
      });
      setHoldings(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getHoldings();
    })();
  }, []);

  const onChangeHolding = value => {
    setExtraQuery(value);
    setRefresh(!refresh);
  };

  const onChangeYear = (_, dateString) => {
    if (dateString.length === 0) {
      setYear(Number(currentYear));
    } else {
      setYear(dateString);
    }
    setRefresh(!refresh);
  };

  const extraButtons = (
    <Row>
      <Col style={{ marginRight: '16px' }}>
        <Select onChange={onChangeHolding} placeholder="Holding" allowClear>
          {holdings.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col style={{ marginRight: '16px' }}>
        <DatePicker
          picker="year"
          onChange={onChangeYear}
          defaultValue={moment()}
        />
      </Col>
    </Row>
  );

  const extraQueryToSend = () => {
    let finalQuery;
    if (year && extraQuery) {
      finalQuery = `holding=${extraQuery}&year=${year}`;
    } else if (year) {
      finalQuery = `year=${year}`;
    }
    return finalQuery;
  };

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="cashflow"
      resourceModelName="cashflow"
      customActionColumn="false"
      withCreateButton={false}
      extraQuery={extraQueryToSend()}
      extraButtons={extraButtons}
      scroll={{ x: 1500 }}
    />
  );
};

export default ShowCashflow;
