const colors = (type, value) => {
  switch (type) {
    case 'incomes': {
      switch (value) {
        case 'Recettes locatives':
          return '#8D48F7';
        case 'Gestion société':
          return '#BF8FF6';
        case 'Travaux':
          return '#D3B5F4';
        case 'Syndic':
          return '#F0DBF1';
        default:
          return '#F6EEF8';
      }
    }
    case 'expenses': {
      switch (value) {
        case 'Gestion société':
          return '#F5222D';
        case 'Gestion park':
          return '#FD7F66';
        case 'Contrat prestation':
          return '#FCA687';
        case 'Banque':
          return '#F6CCBE';
        default:
          return '#F2E9D9';
      }
    }
    default:
      return null;
  }
};

export default colors;
