import React, { useState } from 'react';
import { DatePicker, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Datatable } from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/routes';

const { RangePicker } = DatePicker;

const InvoicesTable = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState();
  const [refresh, setRefresh] = useState(false);

  const onChangeRange = (_, dateString) => {
    if (dateString[0].length === 0 && dateString[1].length === 0) {
      setDateRange();
    } else {
      setDateRange(dateString);
    }
    setRefresh(!refresh);
  };

  const columns = [
    {
      title: t('invoices.columns.status'),
      render: record => {
        if (record?.reconciliation) {
          if (record?.reconciliation?.total_reconciled) {
            return <Tag color="green">{t('invoices.status.PAID')}</Tag>;
          }
          return <Tag color="orange">{t('invoices.status.PENDING')}</Tag>;
        }
        return <Tag color="red">{t('invoices.status.UNPAID')}</Tag>;
      },
      sorter: true
    },
    {
      title: t('invoices.columns.no_invoice'),
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('invoices.columns.period'),
      dataIndex: 'period',
      render: period => moment(period).format('L'),
      sorter: true
    },
    {
      title: t('invoices.columns.wording'),
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('invoices.columns.amount_ht'),
      render: record => record && record.subTotal,
      sorter: true
    },
    {
      title: t('invoices.columns.amount_ttc'),
      render: record => record && record.total,
      sorter: true
    }
  ];

  return (
    <>
      <RangePicker onChange={onChangeRange} />
      <Datatable
        resourceName="invoices"
        showAction={{
          pathname: record =>
            `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INVOICES}/show/${record?.key}`
        }}
        editAction={{
          pathname: record =>
            `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INVOICES}/edit/${record?.key}`
        }}
        columns={columns}
        extraQuery={
          dateRange
            ? `customer=${id}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
            : `customer=${id}`
        }
        path="/invoices"
        forceRefresh={refresh}
      />
    </>
  );
};

export default InvoicesTable;
