import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [setFilters] = useState({});
  const [enums, setEnums] = useState({});
  const [groups, setGroups] = useState();
  const [customersTypeFilters, setCustomersTypeFilters] = useState([]);
  const [legalDenominations, setLegalDenominations] = useState([]);

  const getFilters = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/filters' });
      setFilters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setEnums(data);
      const denominationsType = data.legalForms.map(legalFrom => {
        return {
          text: legalFrom,
          value: legalFrom
        };
      });
      const customersType = data.types.map(type => {
        return {
          text: t(`customers.type.${type}`),
          value: type
        };
      });
      setCustomersTypeFilters(customersType);
      setLegalDenominations(denominationsType);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/groups' });
      setGroups(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getFilters();
      await getEnums();
      await getGroups();
    })();
  }, []);

  return [
    {
      title: t('customers.form.name'),
      key: 'name',
      dataIndex: 'name',
      render: name => name,
      sorter: true
    },
    {
      title: t('customers.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: type => (type ? t(`customers.type.${type}`) : '-'),
      sorter: true,
      filters: customersTypeFilters,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.type && record.type.startsWith(value)
    },
    {
      title: t('customers.form.legal_form'),
      key: 'legal_form',
      dataIndex: 'legal_form',
      render: legal_form => {
        if (legal_form) {
          return legal_form;
        }
        return '-';
      },
      sorter: true,
      filters: legalDenominations,
      filterSearch: true,
      onFilter: (value, record) =>
        record && record.legal_form && record.legal_form.startsWith(value)
    },
    {
      title: t('customers.form.siren'),
      key: 'siren',
      dataIndex: 'siren',
      render: siren => {
        if (siren) {
          return siren;
        }
        return '-';
      },
      sorter: true
    },
    {
      title: t('customers.form.ape_number'),
      key: 'ape_number',
      dataIndex: 'ape_number',
      render: ape_number => {
        if (ape_number) {
          return ape_number;
        }
        return '-';
      }
    },
    {
      title: t('customers.form.manager_name'),
      key: 'manager',
      dataIndex: 'manager',
      sorter: true,
      render: manager => (manager ? manager.last_name : '')
    }
  ];
};

export default useColumns;
