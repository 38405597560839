const headers = [
  {
    label: 'name',
    key: 'name'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'legal_form',
    key: 'legal_form'
  },
  {
    label: 'group',
    key: 'group'
  },
  {
    label: 'channel_number',
    key: 'channel_number'
  },
  {
    label: 'hq_address',
    key: 'hq_address'
  },
  {
    label: 'postal_code',
    key: 'postal_code'
  },
  {
    label: 'city',
    key: 'city'
  },
  {
    label: 'SIREN',
    key: 'siren_number'
  },
  {
    label: 'SIRET',
    key: 'siret_number'
  },
  {
    label: 'APE',
    key: 'ape_number' || ''
  },
  {
    label: 'share_capital',
    key: 'share_capital' || ''
  }
];

export default headers;
