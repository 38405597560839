import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './columns';

const ListLoans = () => {
  const columns = useColumns();

  return (
    <SimpleResourceLandingLayout
      resourceName="loans"
      withUploadButton
      columns={columns}
      populate="company"
      resourceModelName="Loan"
      scroll={{ x: 1200 }}
    />
  );
};

export default ListLoans;
