import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ShowManaging from './ManagingTables/ShowManaging';
import ShowCashflow from './Cashflow/ShowCashflow';
import ListArchives from './Archive/ListArchives';
import { subRoutes } from '../../utils/constants/routes';

const ManagingRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.MANAGINGS.PREVISIONAL}`}
        component={ShowManaging}
      />
      <Route
        path={`${path}${subRoutes.MANAGINGS.CASHFLOW}`}
        component={ShowCashflow}
      />
      <Route
        path={`${path}${subRoutes.MANAGINGS.ARCHIVE}`}
        component={ListArchives}
      />
    </Switch>
  );
};

export default ManagingRouter;
