import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './columns';
import headers from './headers';

const Expenses = () => {
  const columns = useColumns();

  return (
    <>
      <SimpleResourceLandingLayout
        resourceName="expenses"
        withUploadButton
        columns={columns}
        populate="company,category"
        headers={headers}
        resourceModelName="Expense"
      />
    </>
  );
};

export default Expenses;
