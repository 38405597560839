import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomDragger from '../../components/Files/CustomDragger';
import CustomLineFile from '../../components/Files/CustomLineFile';

const Content = styled.div`
  background-color: var(--primaryColor);
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  color: white;
`;

const CustomFilesSpace = ({
  id,
  files,
  forceRefresh,
  setForceRefresh,
  resource
}) => {
  const { t } = useTranslation();
  const props = {
    id,
    files,
    forceRefresh,
    setForceRefresh,
    resource
  };

  return (
    <div
      style={{
        background: 'rgb(38, 100, 123, 0.04)',
        padding: '16px',
        borderRadius: '6px',
        width: '100%'
      }}
    >
      <h1
        style={{
          fontSize: '1.3rem',
          fontWeight: 600,
          textTransform: 'uppercase'
        }}
      >
        Documents
      </h1>
      <div
        style={{
          padding: '0 16px 0 16px',
          overflow: 'auto',
          maxHeight: '650px'
        }}
      >
        <Row>
          <Col span={24}>
            <Content>Contrat</Content>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Content>
              <CustomDragger {...props} category="contract" />
            </Content>
            {files &&
              files
                .filter(({ category }) => category === 'contract')
                .map(file => <CustomLineFile {...props} file={file} />)}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Content>Compte</Content>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Content>
              <CustomDragger {...props} category="account" />
            </Content>
            {files &&
              files
                .filter(({ category }) => category === 'account')
                .map(file => <CustomLineFile {...props} file={file} />)}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Content>Facturation</Content>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Content>
              <CustomDragger {...props} category="invoice" />
            </Content>
            {files &&
              files
                .filter(({ category }) => category === 'invoice')
                .map(file => <CustomLineFile {...props} file={file} />)}
          </Col>
        </Row>
      </div>
    </div>
  );
};

CustomFilesSpace.propTypes = {
  id: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  forceRefresh: PropTypes.string,
  setForceRefresh: PropTypes.func,
  resource: PropTypes.string
};
CustomFilesSpace.defaultProps = {
  files: [],
  forceRefresh: null,
  setForceRefresh: null,
  resource: null
};

export default CustomFilesSpace;
