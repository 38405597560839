const headers = [
  {
    label: 'company',
    key: 'company'
  },
  {
    label: 'jan',
    key: ('balance0' && 'balance0.amount') || ''
  },
  {
    label: 'fev',
    key: ('balance1' && 'balance1.amount') || ''
  },
  {
    label: 'mar',
    key: ('balance2' && 'balance2.amount') || ''
  },
  {
    label: 'avr',
    key: ('balance3' && 'balance3.amount') || ''
  },
  {
    label: 'may',
    key: ('balance4' && 'balance4.amount') || ''
  },
  {
    label: 'jun',
    key: ('balance5' && 'balance5.amount') || ''
  },
  {
    label: 'jul',
    key: ('balance6' && 'balance6.amount') || ''
  },
  {
    label: 'aug',
    key: ('balance7' && 'balance7.amount') || ''
  },
  {
    label: 'sep',
    key: ('balance8' && 'balance8.amount') || ''
  },
  {
    label: 'oct',
    key: ('balance9' && 'balance9.amount') || ''
  },
  {
    label: 'nov',
    key: ('balance10' && 'balance10.amount') || ''
  },
  {
    label: 'dec',
    key: ('balance11' && 'balance11.amount') || ''
  }
];

export default headers;
