import React from 'react';
import {
  WarningOutlined,
  CloudOutlined,
  ToolOutlined,
  BulbOutlined
} from '@ant-design/icons';

const InterventionObjectIcon = ({ object }) => {
  let icon;
  switch (object) {
    case 'GREENSPACE':
      icon = <CloudOutlined />;
      break;
    case 'WORKS':
      icon = <ToolOutlined />;
      break;
    case 'OTHER':
      icon = <BulbOutlined />;
      break;
    default:
      icon = <WarningOutlined />;
      break;
  }
  return icon;
};

export default InterventionObjectIcon;
