const useListContent = ({
  lot,
  customer,
  review_date,
  new_index,
  new_receipts
}) => {
  const content = [
    {
      label: 'lot',
      content: lot && lot.reference
    },
    {
      label: 'customer',
      content: customer && customer.name
    },
    {
      label: 'review_date',
      content: review_date
    },
    {
      label: 'new_index',
      content: new_index
    },
    {
      label: 'new_receipts',
      content: new_receipts
    }
  ];

  return content;
};

export default useListContent;
