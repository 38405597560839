import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('managings.columns.managing_cp.name'),
      fixed: 'left',
      editable: false,
      dataIndex: ['company', 'name']
    },
    {
      title: t('managings.columns.managing_cp.provisional_rental'),
      fixed: 'left',
      editable: true,
      dataIndex: ['managing', 'provisional_rental'],
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing_cp.rate_honorary'),
      fixed: 'left',
      editable: true,
      dataIndex: ['managing', 'rate_honorary'],
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing_cp.balances'),
      children: [
        {
          title: t('managings.columns.months.JAN'),
          dataIndex: ['managing', 'balances', 0, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.FEV'),
          dataIndex: ['managing', 'balances', 1, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.MAR'),
          dataIndex: ['managing', 'balances', 2, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.AVR'),
          dataIndex: ['managing', 'balances', 3, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.MAI'),
          dataIndex: ['managing', 'balances', 4, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.JUN'),
          dataIndex: ['managing', 'balances', 5, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.JUL'),
          dataIndex: ['managing', 'balances', 6, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.AOU'),
          dataIndex: ['managing', 'balances', 7, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.SEP'),
          dataIndex: ['managing', 'balances', 8, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.OCT'),
          dataIndex: ['managing', 'balances', 9, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.NOV'),
          dataIndex: ['managing', 'balances', 10, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        },
        {
          title: t('managings.columns.months.DEC'),
          dataIndex: ['managing', 'balances', 11, 'amount'],
          editable: true,
          picker: 'month',
          render: data => {
            if (data) return data;
            return '-';
          }
        }
      ]
    },
    {
      title: t('managings.columns.managing_cp.total_provisional'),
      dataIndex: ['managing', 'total_provisional'],
      editable: true,
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing_cp.real_rental_revenu'),
      dataIndex: ['managing', 'real_rental_revenu'],
      editable: true,
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing_cp.real_rate_honorary'),
      dataIndex: ['managing', 'real_rate_honorary'],
      editable: true,
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing_cp.rebilling'),
      dataIndex: ['managing', 'rebilling'],
      editable: true,
      render: data => {
        if (data) return data;
        return '-';
      }
    },
    {
      title: t('managings.columns.managing_cp.regul'),
      dataIndex: ['managing', 'regul'],
      editable: true,
      render: data => {
        if (data) return data;
        return '-';
      }
    }
  ];
};

export default useColumns;
