import React, { useEffect, useState } from 'react';
import { Select, Input, DatePicker, Radio, Upload, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import CustomLineFileFields from './CustomLineFileField';

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const useFields = ({
  building,
  filteredLots,
  setFilteredLots,
  lot,
  setLot,
  choice,
  handleChoiceChange,
  purpose,
  buildings,
  documents,
  setDocuments,
  id
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { token, dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [customers, setCustomers] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/interventions/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onSelectBuilding = value => {
    const b = buildings.find(({ _id }) => _id === value);
    setFilteredLots(b.lots);
  };

  const props = {
    name: 'file',
    multiple: true,
    headers: { Authorization: `Bearer ${token}` },
    action: `${process.env.REACT_APP_API_URL}/interventions/files`,

    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        setDocuments([...documents, info.file.response]);
      } else if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getEnums();
      await getCustomers();
      setIsFieldsLoading(false);
    })();
  }, []);

  const fields = [
    {
      label: 'intervention_choice',
      name: ['interventionChoice'],
      input: (
        <Radio.Group
          defaultValue={choice}
          value={choice}
          style={{ display: 'flex', justifyContent: 'center' }}
          onChange={handleChoiceChange}
          disabled={purpose === 'edit' || purpose === 'update'}
        >
          <Radio value="building">Batiment</Radio>
          <Radio value="lot">Lot</Radio>
          <Radio value="other">Autre</Radio>
        </Radio.Group>
      )
    },
    {
      label: 'building',
      name: ['building'],
      input: building ? (
        <Input disabled={building?.name} />
      ) : (
        <Select
          loading={isFieldsLoading}
          allowClear
          onSelect={onSelectBuilding}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {(buildings || []).map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      ),
      hidden: choice === 'other'
    },
    {
      label: 'lot',
      name: ['lot'],
      input:
        building && lot ? (
          <Input disabled={lot?.reference} />
        ) : (
          <Select
            onSelect={value => {
              setLot(value);
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {(filteredLots || []).map(({ _id, reference }) => (
              <Option key={_id} value={_id}>
                {reference}
              </Option>
            ))}
          </Select>
        ),
      hidden: choice === 'other' || choice === 'building'
    },
    {
      label: 'customer',
      name: ['customer'],
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {(customers || []).map(elem => (
            <Option key={elem.name} value={elem._id}>
              {elem.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'type',
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(enums?.type || []).map(el => (
            <Option key={el} value={el}>
              {t(`interventions.form.types.${el}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'object',
      name: ['object'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(enums?.object || []).map(el => (
            <Option key={el} value={el}>
              {t(`interventions.form.objects.${el}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'demandDate',
      name: ['demandDate'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
    },
    {
      label: 'interventionDate',
      name: ['interventionDate'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
    },
    {
      label: 'status',
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(enums?.status || []).map(el => (
            <Option key={el} value={el}>
              {t(`interventions.form.status_enums.${el}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['intervener', 'society']
    },
    {
      name: ['intervener', 'name']
    },
    {
      name: ['intervener', 'phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['intervener', 'email']
    },
    {
      name: ['intervener', 'comments'],
      input: <TextArea rows={3} />
    },
    {
      name: ['files'],
      input: (
        <>
          <Dragger
            {...props}
            style={{ borderRadius: '10px' }}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          {documents.map(doc => (
            <CustomLineFileFields
              id={id}
              resource="interventions"
              documents={documents}
              setDocuments={setDocuments}
              file={doc}
              purpose="purpose"
            />
          ))}
        </>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;

