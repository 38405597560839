import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListCompanies from './ListCompanies';
import CreateUpdateCompany from './CreateUpdateCompany';
import ShowCompany from './ShowCompany';

const CompaniesRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/create`}
        render={() => <CreateUpdateCompany purpose="create" />}
      />
      <Route
        path={`${match.path}/edit/:id`}
        render={() => <CreateUpdateCompany purpose="edit" />}
      />
      <Route path={`${match.path}/show/:id`} render={() => <ShowCompany />} />
      <Route path={match.path} component={ListCompanies} />
    </Switch>
  );
};

export default CompaniesRouter;

